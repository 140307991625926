import { Button, DialogContent, TextField, Typography } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import React from 'react';
import { useAppDialog } from '../app-dialog';
import { BasicDialog, BasicDialogActions, BasicDialogTitle } from './dialog';
import { LoadingButton } from './loading-button';

type Props = {
	onCancel: () => void;
	onConfirm: () => void;
	title?: string;
	message: string;
	confirm_text?: string;
	deleting?: boolean;
}

export const DeleteConfirmDialog = ({
	onCancel,
	onConfirm,
	title,
	message,
	confirm_text,
	deleting,
}: Props) => {
	const app_dialog = useAppDialog();
	const [text, setText] = React.useState('');

	const _onConfirm = () => {
		if (!confirm_text || text.toLowerCase() === 'confirm') {
			onConfirm();
			return;
		}
		app_dialog.showSnackbar(`Please type "Confirm" in the input to delete`, {
			variant: 'error',
		})
	}

	return <BasicDialog
		id='delete-confimation'
		open={true}
		onClose={onCancel}
	>
		<BasicDialogTitle
			title={title || 'Confirm Delete'}
			onClose={onCancel} />
		<DialogContent>
			<Typography variant='body1' gutterBottom>
				{message}
			</Typography>
			{confirm_text ? <>
				<Typography gutterBottom variant='body1'>
					This deletion is non-reversible. To confirm that you want to delete this,
					type: <strong style={{ textDecoration: 'underline' }}>Confirm</strong> in the input below.
				</Typography>
				<TextField
					value={text}
					fullWidth
					margin='normal'
					onChange={e => setText(e.target.value)}
					placeholder='Type in "Confirm"'
					helperText={`To prevent accidental deletion, 
				please type: Confirm into the input above`}
				/>
			</> : null}
		</DialogContent>
		<BasicDialogActions>
			<Button onClick={onCancel}>
				Cancel
			</Button>
			<LoadingButton
				loading={deleting}
				variant='contained'
				color='secondary'
				onClick={_onConfirm}
				startIcon={<RemoveIcon />}>
				Delete
			</LoadingButton>
		</BasicDialogActions>
	</BasicDialog>
}
