import { gql, useMutation } from "@apollo/client";
import { Button, createStyles, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useList } from "../../../apollo/networking";
import { useAppDialog } from "../../../app-dialog";
import { Currency } from "../../../models/currency";
import { InvoiceRefundFragment, InvoiceRefundModel } from "../../../models/invoice-refund.model";
import { InvoiceFragment } from "../../../models/invoice.model";
import { getFormattedCurrency } from "../../../utils/get-formatted-currency";
import { RefundAmountDialog } from "./refund-amount.dialog";

type Props = {
    invoice_id: number;
    max_refund: number;
    currency: Currency;
}

const MutationDoRefund = gql`
    mutation do_refund($object: do_refund_input!) {
        do_refund(object: $object) {
            refund_id
            invoice {
                ${InvoiceFragment}
            }
        }
    }
`;

export const InvoiceRefunds = ({
    invoice_id,
    max_refund,
    currency,
}: Props) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const [show_refund, setShowRefund] = React.useState(false);
    const {
        items,
        loading,
        refetch,
    } = useList<InvoiceRefundModel>({
        entity_name: 'invoice_refund',
        fragment: InvoiceRefundFragment,
        query_inner: `where: {
            invoice_id: {_eq: $invoice_id}
        }, order_by: {id: desc}`,
        clause_outer: `$invoice_id: bigint!`,
        variables: {
            invoice_id,
        }
    });

    const [doRefund, refund_status] = useMutation(MutationDoRefund);

    const refund = async (amount: number) => {
        try {
            const { data } = await doRefund({
                variables: {
                    object: {
                        invoice_id,
                        amount,
                    }
                }
            })
            if (data?.do_refund?.refund_id) {
                app_dialog.showSnackbar('Refund processed');
                refetch();
                return true;
            }
            return false;
        } catch (e: any) {
            app_dialog.showError(e);
            return false;
        }
    }

    return <>
        {show_refund ? <RefundAmountDialog
            max_refund={max_refund}
            currency={currency}
            onClose={() => setShowRefund(false)}
            onRefund={refund}
            loading={refund_status.loading}
        /> : null}
        <div className={classes.header}>
            <Typography variant='overline'>Issued Refunds</Typography>
            {max_refund > 0 ? <Button
                variant='contained'
                color='secondary'
                onClick={() => setShowRefund(true)}>Issue a Refund</Button> : null}
        </div>
        {items.map(item => <div
            key={item.id} className={classes.item}>
            <div style={{ flex: 1 }}>
                Issued {moment(item.created_at).format('LLL')}
            </div>
            <Typography variant='body1'
                color='secondary'>-{getFormattedCurrency(
                    item.amount,
                    currency,
                )}</Typography>
        </div>)}
    </>
}

const useStyles = makeStyles(theme => createStyles({
    item: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 0,
        }
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
}))