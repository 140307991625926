import { gql, useApolloClient, useMutation } from "@apollo/client";
import { useAppDialog } from "../../app-dialog";
import { CertificateFragment, CertificateModel } from "../../models/certificate.model";
import { EventFragment, EventModel } from "../../models/event.model";
import { TicketFragment, TicketModel } from "../../models/ticket.model";

const MUTATION = gql`
mutation insert_event($object: event_insert_input!) {
    insert_event_one(object: $object) {
        ${EventFragment}
    }
}
`;

const FetchEvent = gql`
    query fetch_event($event_id: bigint!) {
        event_by_pk(id: $event_id) {
            id
            created_at
            updated_at
            deleted_at
            title
            feature_images
            content
            starts_at
            ends_at
            timezone
            is_live
            ticket_order
            tickets {
                ${TicketFragment}
            }
            certificates {
                ${CertificateFragment}
            }
        }
    }
`;

interface FetchEventModel extends EventModel {
    tickets: TicketModel[];
    certificates: CertificateModel[];
}

export const useEventDuplicate = () => {
    const apollo = useApolloClient();
    const [insert, status] = useMutation(MUTATION)
    const app_dialog = useAppDialog();

    const duplicate = async (event_id: number) => {
        
        try {
            const { data: event_data } = await apollo.query({
                query: FetchEvent,
                variables: {
                    event_id,
                },
            })
            const existing_event = event_data?.event_by_pk as FetchEventModel;
            if (!existing_event) {
                app_dialog.showSnackbar('Failed to fetch event');
                return null;
            }
            const new_event_data = {
                title: `${existing_event.title} Copy`,
                feature_images: existing_event.feature_images,
                content: existing_event.content,
                starts_at: existing_event.starts_at,
                ends_at: existing_event.ends_at,
                timezone: existing_event.timezone,
                is_live: false,
                ticket_order: existing_event.ticket_order,
                tickets: {
                    data: (existing_event.tickets || []).map(t => ({
                        name: t.name,
                        description: t.description,
                        price: t.price,
                        is_inventory: t.is_inventory,
                        total_inventory: t.total_inventory,
                        sale_ends_at: t.sale_ends_at,
                        ask_agd_id: t.ask_agd_id,
                        is_agd_id_required: t.is_agd_id_required,
                        offer_agd_discount: t.offer_agd_discount,
                        agd_discount_price: t.agd_discount_price,
                        offer_referral_discount: t.offer_referral_discount,
                        referral_discount_price: t.referral_discount_price,
                        discounts: t.discounts,
                        is_visible: t.is_visible,
                        schema: t.schema,
                        ui_schema: t.ui_schema,
                    }))
                },
                certificates: {
                    data: (existing_event.certificates || []).map(c => ({
                        title: c.title,
                        speaker_name: c.speaker_name,
                        units: c.units,
                        subject_code: c.subject_code,
                        location: c.location,
                        course_date: c.course_date,
                        educational_method: c.educational_method,
                    }))
                }
            }
            const { data } = await insert({
                variables: {
                    object: new_event_data
                }
            });
            const event = data?.insert_event_one;
            if (!!event) {
                app_dialog.showSnackbar('Event added');
            }
            return event;
        } catch (e: any) {
            app_dialog.showError(e);
            return null;
        }
    }

    return {
        duplicate,
        creating: status.loading,
    }
}