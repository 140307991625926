import { gql, useMutation } from "@apollo/client";
import { DialogContent } from "@material-ui/core";
import { useNavigate } from "@reach/router";
import { Form, Formik } from "formik";
import React from "react";
import { useAppDialog } from "../../../app-dialog";
import { EventFragment, EventModel } from "../../../models/event.model";
import { BasicDialogActions } from "../../../ui/dialog";
import { LoadingButton } from "../../../ui/loading-button";
import { EventForm } from "./event.form";
import { EventInitialValues, EventValidationSchema, validateEventValues } from "./event.validation";

const MUTATION = gql`
    mutation insert_event($object: event_insert_input!) {
        insert_event_one(object: $object) {
            ${EventFragment}
        }
    }
`;

type Props = {
    onFinish: (event: EventModel) => void;
}

export const EventCreate = ({
    onFinish,
}: Props) => {
    const [insert, status] = useMutation(MUTATION)
    const app_dialog = useAppDialog();
    const navigate = useNavigate();

    const onSubmit = async (values: any) => {
        try {
            const { data } = await insert({
                variables: {
                    object: validateEventValues(values),
                }
            });
            const event = data?.insert_event_one;
            if (!event) {
                return;
            }
            navigate(`/events/${event.id}/edit`)
            onFinish(event);
        } catch (e: any) {
            app_dialog.showError(e);
        }
    }
    return <Formik initialValues={EventInitialValues()}
        validationSchema={EventValidationSchema}
        onSubmit={onSubmit}>{form_props => <Form noValidate>
            <DialogContent>
                <EventForm {...form_props} />
            </DialogContent>
            <BasicDialogActions>
                <LoadingButton loading={status.loading}
                    color='primary'
                    type='submit'
                    variant='contained'>
                    Create Event
                </LoadingButton>
            </BasicDialogActions>

        </Form>}

    </Formik>
}
