import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';

interface Props {
  icon?: React.ReactNode;
  title: JSX.Element | string;
  render?: () => React.ReactElement;
  children?: any;
  subtitle?: JSX.Element | string;
  id?: string;
  style?: any;
}

export const Section = ({ icon, title, subtitle, children, render, id, style = {} }: Props) => {
  const classes = useStyles({});
  return <section id={id} className={classes.section} style={style}>
    {icon && <div className={classes.sectionIcon}>
      {icon}
    </div>}
    <div className={classes.sectionContent}>
      <div className={classes.sectionHeader}>
        <Typography variant='h4'><strong>{title}</strong></Typography>
        {subtitle && <Typography variant='subtitle1' color='textSecondary'>
          {subtitle}
        </Typography>}
      </div>
      {render ? render() : children}
    </div>
  </section>
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  section: {
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 0,
    },
  },
  sectionIcon: {
    '& svg': {
      width: 50,
      height: 50,
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      // color: theme.palette.text.secondary,
    }
  },
  sectionContent: {
    flex: 1,
  },
  sectionHeader: {
    paddingBottom: theme.spacing(2),
  },
}));
