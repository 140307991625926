import { useStorage } from "../../utils/use-storage";

const columns_key = 'cert_attendee_cols';

export type DiplomaAttendeeCol = ('email' | 'ticket' | 'agd_id');
export type DiplomaAttendeeCols = DiplomaAttendeeCol[];

const columns: {
    id: DiplomaAttendeeCol;
    label: string;
}[] = [{
    id: 'email',
    label: 'Email',
},{
    id: 'ticket',
    label: 'Ticket',
},{
    id: 'agd_id',
    label: 'AGD ID',
},]

export const useDiplomaAttendeeColumns = () => {
    const {
        getKey,
        setKey,
    } = useStorage();
    const cols = getKey(columns_key, []) as DiplomaAttendeeCols;
    const setCols = (v: DiplomaAttendeeCols) => setKey(columns_key, v);

    const showColumn = (id: DiplomaAttendeeCol) => {
        return cols.indexOf(id) > -1;
    }

    const toggleColumn = (id: DiplomaAttendeeCol) => {
        if (!showColumn(id)) {
            setCols([
                ...cols, id,
            ]);
        } else {
            setCols(cols.filter(c => c !== id));
        }
    }

    return {
        cols,
        columns,
        setCols,
        showColumn,
        toggleColumn,
    };
}