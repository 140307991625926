import { Button, createStyles, makeStyles, Typography } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';

type Props = {
	title: string;
	back_url?: string;
	no_back?: boolean;
	subtitle?: string|JSX.Element;
}

export const PageHeader = ({
	title,
	back_url,
	subtitle,
	no_back,
}: Props) => {
	const classes = useStyles({});
	return <div className={classes.pageHeader}>
		{back_url ? <Button size='small' href={back_url} startIcon={<BackIcon />}>
			Back
        </Button> : null}
		{!!back_url || no_back || window.history.length === 1 ? null : <Button size='small' onClick={() => window.history.back()} startIcon={<BackIcon />}>
			Back
        </Button>}
		<Typography variant='h3'>
			<strong>{title}</strong>
		</Typography>
		{typeof subtitle === 'string' ? <Typography variant='body1' color='textSecondary'>
			{subtitle}
		</Typography> : subtitle}
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	pageHeader: {
		paddingBottom: theme.spacing(4),
		'& h3': {
			fontWeight: theme.typography.fontWeightBold,
		},
	}
}));