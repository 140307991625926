import { createStyles, IconButton, makeStyles, TableCell, TableRow, Tooltip } from "@material-ui/core";
import MoreIcon from '@material-ui/icons/MoreHoriz';
import moment from "moment";
import React from "react";
import { AttendeeModel } from "../../models/attendee.model";
import { AttendeeCol } from "./use-attendee-cols";

type Props = {
    item: AttendeeModel;
    showColumn: (id: AttendeeCol) => boolean;
    onMore: (e: React.MouseEvent<HTMLButtonElement>, item: AttendeeModel) => void;
}

export const AttendeeItem = ({
    item,
    showColumn,
    onMore,
}: Props) => {
    const classes = useStyles({});

    return <TableRow
        hover
    >
        <TableCell>
            <div className={classes.buttons}>
                <Tooltip title='More Options'>
                    <IconButton size='small' onClick={(e) => onMore(e, item)}>
                        <MoreIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
            </div>
        </TableCell>
        <TableCell style={{ width: 50 }}>
            {item.id}
        </TableCell>
        <TableCell style={{
            minWidth: 150,
        }}>{item.name}</TableCell>
        {showColumn('email') ? <TableCell style={{
            minWidth: 200,
        }}>{item.email}</TableCell> : null}
        {showColumn('agd_id') ? <TableCell>{item.agd_id}</TableCell> : null}
        <TableCell style={{
            minWidth: 180,
        }}>{moment(item.created_at).format('YYYY/MM/DD HH:mm')}</TableCell>
        {showColumn('ticket') ? <TableCell>{item.ticket.name}</TableCell> : null}
        {showColumn('is_email_enabled') ? <TableCell>{item.is_email_enabled ? '✅' : '❌'}</TableCell> : null}
    </TableRow>
}

const useStyles = makeStyles(theme => createStyles({
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}))