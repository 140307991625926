import React from "react";
import { CertificateModel } from "../../../models/certificate.model";
import { BasicDialog, BasicDialogTitle } from "../../../ui/dialog";
import { CertificateCreate } from "./certificate-create";
import { CertificateEdit } from "./certificate-edit";


type Props = {
    event_id: number;
    action: 'edit' | 'create';
    item?: CertificateModel;
    onClose: () => void;
    onFinish: (event: CertificateModel, is_new?: boolean) => void;
}

export const CertificateEditorDialog = ({
    event_id,
    action,
    onClose,
    onFinish,
    item,
}: Props) => {
    return <BasicDialog
        open={true}
        id='update-event'
        onClose={onClose}>
        <BasicDialogTitle onClose={onClose} title={action === 'create' ? 'Create certificate' : 'Edit certificate'} />
        {item
            ? <CertificateEdit
                onFinish={onFinish}
                item={item} />
            : <CertificateCreate
                event_id={event_id}
                onFinish={onFinish}
            />}
    </BasicDialog>
}
