import { createStyles, Link, makeStyles, Typography } from '@material-ui/core'
import QrCode from 'qrcode.react'
import React from 'react'
import { environment } from '../../environment'

export type HostingWidgetsProps = {
    event_id: number;
    attendance_id: number;
}

function HostingWidgets({
    event_id,
    attendance_id,
}: HostingWidgetsProps) {
    const classes = useStyles();
    return (
        <div className={classes.qr}>
            <Typography
                align='center'
                gutterBottom variant='body1'>This QR Code will let guests self-checkin to this event. To copy this QR Code, right click the image below and click <strong>Save Image As</strong>.</Typography>
            <div className='code'>
                <QrCode
                    value={`${environment.checkin_url}/?eid=${event_id}&aid=${attendance_id}`}
                    size={200}
                />
            </div>
            <Typography variant='body2'>{environment.checkin_url}?eid={event_id}&aid={attendance_id}</Typography>
        </div>
    )
}

const useStyles = makeStyles(theme => createStyles({
    qr: {
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.background.paper,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 400,
        boxShadow: theme.shadows[4],
        marginBottom: theme.spacing(4),
        '& p:first-child': {
            marginBottom: theme.spacing(2),
        },
        '& .code': {
            marginBottom: theme.spacing(1),
        }
    }
}))

export default HostingWidgets