import { Button, createStyles, DialogContent, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { CSVLink } from "react-csv";
import { BasicDialog, BasicDialogTitle } from "../../ui/dialog";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import moment from "moment";
import { gql, useApolloClient } from "@apollo/client";
import { AttendeeFragment, AttendeeModel } from "../../models/attendee.model";
import { LoadingDots } from "../../ui/loading-dots";
import { AttendeeCertificateFragment, AttendeeCertificateModel } from "../../models/attendee-certificate.model";
import { environment } from "../../environment";


type Props = {
    certificate_id: number;
    event_id: number;
    event_title: string;
    onClose: () => void;
}


const QueryAttendee = gql`
    query attendees(
        $event_id: bigint!,
        $certificate_id: bigint!,
        $cursor: bigint!,
        $limit: Int!
    ) {
        attendee(where: {
            _and: [
                {ticket: {
                    event_id: {_eq: $event_id}
                }},
                {id: {_gt: $cursor}}
            ]
        }, limit: $limit, order_by: {id: asc}) {
            ${AttendeeCertificateFragment}
        }
    }
`;

export const ExportCertAttendees = ({
    certificate_id,
    event_id,
    event_title,
    onClose,
}: Props) => {
    const classes = useStyles({});
    const [is_ready, setIsReady] = React.useState(false);
    const [csv_data, setCsvData] = React.useState<any[]>([]);
    const [columns, setColumns] = React.useState<string[]>([]);
    const [total_rows, setTotalRows] = React.useState(0);
    const client = useApolloClient();

    const fetchAttendees = async (attendees: AttendeeCertificateModel[], limit: number, cursor = 0): Promise<AttendeeCertificateModel[]> => {
        try {
            const { data, error } = await client.query({
                query: QueryAttendee,
                variables: {
                    limit,
                    cursor,
                    event_id,
                    certificate_id,
                }
            });
            const new_attendees = (data?.attendee || []) as AttendeeCertificateModel[];
            if (new_attendees.length < limit) {
                return [
                    ...attendees,
                    ...new_attendees,
                ];
            }
            return fetchAttendees([
                ...attendees,
                ...new_attendees,
            ], limit, new_attendees[new_attendees.length - 1].id);
        } catch (e: any) {
            console.error(e);
            return [];
        }
    }

    React.useEffect(() => {
        fetchAttendees([], 50).then(data => {
            const columns: string[] = [
                'id',
                'name',
                'email',
                'agd_id',
                'certificate_link',
                'certificate_assigned_at',
                'created_at',
                'ticket_id',
                'ticket_name',
                'invoice_item_id',
            ];
            const csv_data = data
                .filter(item => item
                    .attendee_certificates && item.attendee_certificates.length > 0
                    && !item.attendee_certificates[0].deleted_at)
                .map(d => {
                    const cert = d.attendee_certificates[0];
                    return {
                        ...d,
                        created_at: moment(d.created_at).format('LLL'),
                        ticket_name: d.ticket.name,
                        certificate_link: `${environment.frontend_url}/certificates/${cert.secret}`,
                        certificate_assigned_at: moment(cert.updated_at).format('LLL'),
                        ...(d.data ? Object.keys(d.ticket.schema?.properties || {}).reduce(
                            (obj, pkey, idx) => {
                                const property = d.ticket.schema.properties[pkey] as any;
                                const label = `${property.title}_${idx}`;
                                columns.push(label)
                                return {
                                    ...obj,
                                    [label]: d.data[pkey] || '',
                                };
                            }, {}) : {})
                    }
                });
            setTotalRows(csv_data.length);
            setColumns(columns);
            setCsvData(csv_data);
            setIsReady(true);
        })
    }, []);

    return <BasicDialog
        id='export-attendees'
        onClose={onClose}
        open={true}
    >
        <BasicDialogTitle title='Export Attendees' onClose={onClose} />
        <DialogContent>
            <div className={classes.loading}>
                {is_ready ? <>
                    <Typography variant='h6' gutterBottom>
                        Your download is ready. {total_rows} attendee certificates found.
                    </Typography>
                    <CSVLink
                        data={csv_data}
                        filename={`${event_title}_attendees_${moment().format('LL')}.csv`}
                        headers={columns}
                        className={classes.button}
                        target="_blank">
                        <Button
                            variant='contained'
                            color='secondary'
                            size='large'
                            startIcon={<DownloadIcon />}
                        >Download Report</Button>
                    </CSVLink>
                </> : <>
                        <LoadingDots />
                        <Typography variant='h6' align='center'>
                            Preparing your export...one moment.</Typography>
                    </>}
            </div>
        </DialogContent>
    </BasicDialog>
}

const useStyles = makeStyles(theme => createStyles({
    button: {
        textDecoration: 'none !important',
    },
    loading: {
        textAlign: 'center',
        padding: theme.spacing(0, 0, 4, 0),
    }
}))