import { gql, useMutation } from "@apollo/client";
import { Button, createStyles, IconButton, lighten, makeStyles, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import MoreIcon from '@material-ui/icons/MoreHoriz';
import { Link, useLocation } from "@reach/router";
import { Link as MaterialLink } from '@material-ui/core'
import * as qs from 'query-string';
import React from "react";
import { useList } from "../../apollo/networking";
import { useUpdateByPk } from "../../apollo/networking/use-update-by-pk";
import { useAppDialog } from "../../app-dialog";
import { AttendanceFragment, AttendanceModel, AttendanceStateOptions } from "../../models/attendance.model";
import { EventModel } from "../../models/event.model";
import { DeleteConfirmDialog } from "../../ui/delete-confirm.dialog";
import { LoadingDots } from "../../ui/loading-dots";
import { AttendanceEditorDialog } from "./attendance-editor/attendance-editor.dialog";
import { environment } from "../../environment";

type Props = {
    event: EventModel;
}

const MutationUpdateAttendance = gql`
    mutation update_attendance($attendance_id: bigint!, $set: attendance_set_input!) {
        update_attendance_by_pk(
            pk_columns: {id: $attendance_id},
            _set: $set,
        ) {
            ${AttendanceFragment}
        }
    }
`;

export const AttendanceList = ({
    event,
}: Props) => {
    const classes = useStyles({});
    const location = useLocation();
    const params = qs.parse(location.search || '');
    const add = params?.add;
    const app_dialog = useAppDialog();
    const [confirm_delete, setConfirmDelete] = React.useState<AttendanceModel | false>(false);
    const [anchor_el, setAnchorEl] = React.useState<{
        anchor: HTMLButtonElement;
        item: AttendanceModel;
    } | undefined>();
    const [updateAttendance, update_att_status] = useMutation(MutationUpdateAttendance);
    const [editor, setEditor] = React.useState<{
        action: 'edit' | 'create';
        item?: AttendanceModel;
    } | undefined>();
    const {
        items,
        loading,
        refetch,
        error,
    } = useList<AttendanceModel>({
        entity_name: 'attendance',
        fragment: AttendanceFragment,
        clause_outer: `$event_id: bigint!`,
        variables: {
            event_id: event.id,
        },
        query_inner: `
            where: {event_id: {_eq: $event_id}},
            order_by: {id: asc}
        `
    });

    const _items = React.useMemo(() => items.filter(i => !i.deleted_at), [items]);

    const onFinish = (_: AttendanceModel, is_new?: boolean) => {
        if (is_new) {
            refetch();
        }
        setEditor(undefined);
    }

    const onEdit = (item?: AttendanceModel) => () => {
        if (!item) {
            return;
        }
        setAnchorEl(undefined);
        setEditor({
            action: 'edit',
            item,
        })
    }

    const onDelete = () => {
        if (!anchor_el) {
            return;
        }
        setConfirmDelete(anchor_el.item);
        setAnchorEl(undefined);
    }

    const onConfirmDelete = async () => {
        if (!confirm_delete) {
            return;
        }
        try {
            await updateAttendance({
                variables: {
                    attendance_id: confirm_delete.id,
                    set: {
                        deleted_at: 'now()',
                    }
                },
            });
            setConfirmDelete(false);
            app_dialog.showSnackbar('Attendance deleted');
            refetch();
        } catch (e: any) {
            app_dialog.showError(e);
        }
    }

    return <>
        {confirm_delete ? <DeleteConfirmDialog
            onCancel={() => setConfirmDelete(false)}
            onConfirm={onConfirmDelete}
            message={`Are you sure you want to delete the attendance: ${confirm_delete.name}. This is not reversible.`}
            deleting={update_att_status.loading}
            confirm_text='confirm'
        /> : null}
        <Menu
            open={Boolean(anchor_el)}
            anchorEl={anchor_el?.anchor}
            onClose={() => setAnchorEl(undefined)}
        >
            <MenuItem onClick={onEdit(anchor_el?.item)}>Edit</MenuItem>
            <MenuItem onClick={onDelete}><Typography color='secondary'>Delete</Typography></MenuItem>
        </Menu>
        {editor ? <AttendanceEditorDialog
            action={editor.action}
            item={editor.item}
            event_id={event.id}
            onFinish={onFinish}
            onClose={() => setEditor(undefined)} /> : null}
        <Button
            variant='outlined'
            color='primary'
            className={classes.addButton}
            onClick={() => setEditor({
                action: 'create',
            })}>+ Add Attendance</Button>
        <div className={classes.checkin}>
            <Typography variant='body1'>For Manual Check-ins and to view real time attendance please use this link: </Typography>
            <Typography variant='body2' color='secondary'>DO NOT SHARE THIS LINK!</Typography>
            <MaterialLink target='_blank' href={`${environment.host_url}/${event.id}?s=${event.secret}`}>
                <Typography gutterBottom variant='h6'>
                    Open Attendance Admin
                </Typography>
            </MaterialLink>
        </div>
        <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow className='header'>
                        <TableCell />
                        <TableCell style={{ width: 50 }}>
                            ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>Check Ins</TableCell>
                        <TableCell>Check Outs</TableCell>
                        <TableCell>Youtube Url</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {_items.map((item, index) => (
                        <TableRow
                            key={item.id}
                            hover
                        >
                            <TableCell>
                                <div className={classes.buttons}>
                                    <Tooltip title='More Options'>
                                        <IconButton size='small' onClick={(e) => setAnchorEl({
                                            anchor: e.currentTarget,
                                            item,
                                        })}>
                                            <MoreIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </TableCell>
                            <TableCell style={{ width: 50 }}>
                                {item.id}
                            </TableCell>
                            <TableCell><Link to={`/events/${event.id}/attendance/${item.id}`}>{item.name}</Link> </TableCell>
                            <TableCell>{AttendanceStateOptions.find(o => o.id === item.state)?.action}</TableCell>
                            <TableCell>{item.checkin_tally?.total_checkins || 0}</TableCell>
                            <TableCell>{item.checkout_tally?.total_checkouts || 0}</TableCell>
                            <TableCell><Link to={`/events/${event.id}/webinar/${item.id}`}>{item.youtube_url}</Link></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        {loading ? <LoadingDots /> : null}
    </>
}

const useStyles = makeStyles(theme => createStyles({
    table: {
        width: '100%',
        background: 'white',
        border: `1px solid ${theme.palette.divider}`,
        '& .header': {
            background: theme.palette.background.paper,
        }
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 10,
    },
    addButton: {
        marginBottom: theme.spacing(2),
    },
    checkin: {
        backgroundColor: lighten(theme.palette.info.light, .6),
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(2),
    }
}))