import { gql, useMutation } from '@apollo/client';
import { Button, createStyles, IconButton, makeStyles, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import MoreIcon from '@material-ui/icons/MoreHoriz';
import { Link, useLocation } from "@reach/router";
import moment from "moment";
import * as qs from 'query-string';
import React from "react";
import { useList } from "../../apollo/networking";
import { useAppDialog } from "../../app-dialog";
import { CertificateFragment, CertificateModel } from "../../models/certificate.model";
import { DiplomaFragment, DiplomaModel } from '../../models/diploma.model';
import { DeleteConfirmDialog } from "../../ui/delete-confirm.dialog";
import { LoadingDots } from "../../ui/loading-dots";
import { CertificateEditorDialog } from "./diploma-editor";

type Props = {
    event_id: number;
}


const MutationUpdateCertificate = gql`
    mutation update_ticket($cert_id: bigint!, $set: certificate_set_input!) {
        update_certificate_by_pk(
            pk_columns: {id: $cert_id},
            _set: $set,
        ) {
            ${CertificateFragment}
        }
    }
`;


export const DiplomaList = ({
    event_id,
}: Props) => {
    const location = useLocation();
    const params = qs.parse(location.search || '');
    const add = params?.add;
    const app_dialog = useAppDialog();
    const classes = useStyles({});
    const [anchor_el, setAnchorEl] = React.useState<{
        anchor: HTMLButtonElement;
        item: DiplomaModel;
    } | undefined>();
    const [confirm_delete, setConfirmDelete] = React.useState<DiplomaModel | false>(false);
    const [updateDiploma, update_diploma_status] = useMutation(MutationUpdateCertificate);
    const [editor, setEditor] = React.useState<{
        action: 'edit' | 'create';
        item?: DiplomaModel;
    } | undefined>();
    const {
        items,
        loading,
        refetch,
        error,
    } = useList<DiplomaModel>({
        entity_name: 'diploma',
        fragment: DiplomaFragment,
        clause_outer: `$event_id: bigint!`,
        variables: {
            event_id,
        },
        query_inner: `
            where: {event_id: {_eq: $event_id}}
        `
    });

    React.useEffect(() => {
        if (!!add) {
            setEditor({ action: 'create' });
        }
    }, [add])

    const onFinish = (_: DiplomaModel, is_new?: boolean) => {
        if (is_new) {
            refetch();
        }
        setEditor(undefined);
    }

    const onEdit = (item?: DiplomaModel) => () => {
        if (!item) {
            return;
        }
        setAnchorEl(undefined);
        setEditor({
            action: 'edit',
            item,
        })
    }

    const onDelete = () => {
        if (!anchor_el) {
            return;
        }
        setConfirmDelete(anchor_el.item);
        setAnchorEl(undefined);
    }

    const onConfirmDelete = async () => {
        if (!confirm_delete) {
            return;
        }
        try {
            await updateDiploma({
                variables: {
                    cert_id: confirm_delete.id,
                    set: {
                        deleted_at: 'now()',
                    }
                },
            });
            setConfirmDelete(false);
            app_dialog.showSnackbar('Diploma deleted');
            refetch();
        } catch (e: any) {
            app_dialog.showError(e);
        }
    }

    return <>
        {confirm_delete ? <DeleteConfirmDialog
            onCancel={() => setConfirmDelete(false)}
            onConfirm={onConfirmDelete}
            message={`Are you sure you want to delete the diploma: ${confirm_delete.title}. This is not reversible and if there are attendees assigned to this diploma, they won't be able to access it anymore.`}
            deleting={update_diploma_status.loading}
            confirm_text='confirm'
        /> : null}
        <Menu
            open={Boolean(anchor_el)}
            anchorEl={anchor_el?.anchor}
            onClose={() => setAnchorEl(undefined)}
        >
            <MenuItem onClick={onEdit(anchor_el?.item)}>Edit</MenuItem>
            <MenuItem onClick={onDelete}><Typography color='secondary'>Delete</Typography></MenuItem>
        </Menu>
        {editor ? <CertificateEditorDialog
            action={editor.action}
            item={editor.item}
            event_id={event_id}
            onFinish={onFinish}
            onClose={() => setEditor(undefined)} /> : null}
        <Button
            variant='outlined'
            color='primary'
            className={classes.addButton}
            onClick={() => setEditor({
                action: 'create',
            })}>+ Add Diploma</Button>
        <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow className='header'>
                        <TableCell />
                        <TableCell style={{ width: 50 }}>
                            ID</TableCell>
                        <TableCell >Title</TableCell>
                        <TableCell >Created At</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                        <TableRow
                            key={item.id}
                            hover
                        >
                            <TableCell>
                                <div className={classes.buttons}>
                                    <Tooltip title='More Options'>
                                        <IconButton size='small' onClick={(e) => setAnchorEl({
                                            anchor: e.currentTarget,
                                            item,
                                        })}>
                                            <MoreIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </TableCell>
                            <TableCell style={{ width: 30 }}>
                                {item.id}
                            </TableCell>
                            <TableCell><Link to={`/events/${event_id}/diplomas/${item.id}`}>{item.title}</Link></TableCell>
                            <TableCell >{moment(item.created_at).format('LL')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        {loading ? <LoadingDots /> : null}
    </>
}

const useStyles = makeStyles(theme => createStyles({
    table: {
        width: '100%',
        background: 'white',
        border: `1px solid ${theme.palette.divider}`,
        '& .header': {
            background: theme.palette.background.paper,
        }
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 10,
    },
    addButton: {
        marginBottom: theme.spacing(2),
    }
}))