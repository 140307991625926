import { DialogContent } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Form, Formik } from 'formik';
import React from 'react';
import { BasicDialog, BasicDialogActions, BasicDialogTitle } from '../../ui/dialog';
import { LoadingButton } from '../../ui/loading-button';
import { EditQuestionnaireFormComponent } from './edit-questionnaire-form';
import { QuestionnaireEditorInitialValue } from './questionnaire-editor.validation';

type Props = {
	onClose: () => void;
	questionnaire?: {
		schema: any;
		ui_schema: any;
	};
	loading?: boolean;
	onFinish: (questionnaire: {
		schema: any;
		ui_schema: any;
	}) => void;
}

export const QuestionnaireEditorDialog = ({
	questionnaire,
	loading,
	onClose,
	onFinish,
}: Props) => {
	return <BasicDialog
		open={true}
		onClose={() => onClose()}
		max_width='lg'
		full_screen='md'
		disableBackdropClick
		disableEscapeKeyDown
		id='questionnaire-editor'>
		<BasicDialogTitle
			title={'Custom Questions'}
			onClose={() => onClose()} />
		<Formik
			initialValues={QuestionnaireEditorInitialValue(questionnaire)}
			onSubmit={onFinish}>{(props) => <Form noValidate>
				<DialogContent>
					<Alert severity='info'>
						<AlertTitle>Avoid Duplicates</AlertTitle>
						Please note that <strong>Attendee's Name</strong> and their <strong>AGD ID</strong> are
						asked automatically for each ticket sold. So you don't need to ask custom questions for this.<br/>
						If you do not want to collect AGD ID, this setting can be turned off from the ticket editor.
					</Alert>
					<EditQuestionnaireFormComponent
						{...props} />
				</DialogContent>
				<BasicDialogActions>
					<LoadingButton
						loading={loading}
						variant='contained'
						color='secondary'
						type='submit'
					>Save Questionnaire</LoadingButton>
				</BasicDialogActions>
			</Form>}
		</Formik>
	</BasicDialog>
}