import { RouteComponentProps } from "@reach/router"
import React from "react"
import { AppLayout } from "../../layout/app-layout"
import { PageHeader } from "../../ui/page-header"
import { InvoiceList } from "./invoice-list"

type Props = {
}

export const SalesPage = ({
}: RouteComponentProps<Props>) => {

    return <AppLayout>
        <PageHeader title='Sales' no_back />
        <InvoiceList />
    </AppLayout>
}
