import { Currency } from "./currency";

export type OrganizationModel = {
    id: number;
    name: string;
    description?: string;
    email: string;
    phone?: string;
    logo_image_key?: string;
    pace_id?: string;
    pace_start?: string;
    pace_end?: string;
    pace_key?: string;
    stripe_id?: string;
    currency: Currency;
    username: string;
    cerp_start?: string;
    cerp_end?: string;
    is_cerp_enabled?: boolean;
    is_referral_program_enabled?: boolean;
    feature_flags: {[id: string]: boolean};
}

export const OrganizationFragment = `
    id
    name
    description
    email
    phone
    logo_image_key
    stripe_id
    pace_id
    pace_start
    pace_end
    pace_key
    currency
    username
    cerp_start
    cerp_end
    is_cerp_enabled
    is_referral_program_enabled
    feature_flags
`;