import { Button, createStyles, makeStyles, Menu, MenuItem } from "@material-ui/core";
import CheckCircle from '@material-ui/icons/CheckCircle';
import UncheckCircle from '@material-ui/icons/RadioButtonUnchecked';
import React from "react";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";


type Props<T> = {
    columns: { id: T, label: string }[];
    showColumn: (id: T) => boolean;
    toggleColumn: (id: T) => void;
}

export const ColumnSelector = <T extends any>({
    columns,
    showColumn,
    toggleColumn,
}: Props<T>) => {
    const classes = useStyles({});

    const [anchor_el, setAnchorEl] = React.useState<HTMLButtonElement | undefined>();

    return <>
        <Menu
            open={Boolean(anchor_el)}
            anchorEl={anchor_el}
            onClose={() => setAnchorEl(undefined)}
        >
            {columns.map(col => <MenuItem
                key={col.label}
                onClick={() => toggleColumn(col.id)}>
                {showColumn(col.id) ? <CheckCircle /> : <UncheckCircle />} {col.label}
            </MenuItem>)}
        </Menu>
        <Button variant='contained'
            size='small'
            endIcon={<KeyboardArrowDown />}
            onClick={e => setAnchorEl(e.currentTarget)}>
            View Columns</Button>
    </>
}

const useStyles = makeStyles(theme => createStyles({

}))