import { Button, DialogContent } from "@material-ui/core";
import React from "react";
import { useGetByPk } from "../../../apollo/networking/use-get-by-pk";
import { InvoiceFragment, InvoiceModel } from "../../../models/invoice.model";
import { BasicDialog, BasicDialogActions, BasicDialogTitle } from "../../../ui/dialog";
import { LoadingDots } from "../../../ui/loading-dots";
import { Invoice } from "./invoice";
import { InvoiceRefunds } from "./invoice-refunds";


type Props = {
    invoice_id: number;
    onClose: () => void;
}

export const InvoiceDetailsDialog = ({
    invoice_id,
    onClose,
}: Props) => {
    const {
        loading,
        entity: invoice,
    } = useGetByPk<InvoiceModel>({
        entity_name: 'invoice',
        fragment: InvoiceFragment,
        pk_columns: [{
            name: 'id',
            value: invoice_id,
            type: 'bigint',
        }]
    });


    return <BasicDialog
        open={true}
        id='update-attendee'
        onClose={onClose}>
        <BasicDialogTitle onClose={onClose} title='Invoice details' />
        <DialogContent>
            {loading ? <LoadingDots /> : null}
            {invoice ? <>
                <Invoice invoice={invoice} />
                {invoice.payment_intent_id
                    ? <InvoiceRefunds
                        max_refund={invoice.amount - (invoice.refund_tally?.total_amount || 0)}
                        currency={invoice.currency}
                        invoice_id={invoice.id} />
                    : null}
            </> : null}
        </DialogContent>
        <BasicDialogActions>
            <Button onClick={onClose}>Dismiss</Button>
        </BasicDialogActions>
    </BasicDialog>
}
