import { useQuery, gql } from "@apollo/client";
import React from "react";
import { environment } from "../../environment";
import { Image, IMAGE_FRAGMENT } from "../image.model";

const IMAGE_UPLOAD_QUERY = gql`
	query image($limit: Int!, $cursor: bigint) {
		image(
			where: {
				id: {_lt: $cursor},
			},
			order_by: { id: desc }, 
			limit: $limit
		) {
			${IMAGE_FRAGMENT}
		}
	}
`;

export const useImageListLoader = () => {
	const limit = environment.default_page_size;
	const [prepend_images, setPrependImages] = React.useState<Image[]>([]);
	const [has_more, setHasMore] = React.useState(true);
	const { data, loading, fetchMore, error } = useQuery(
		IMAGE_UPLOAD_QUERY, {
		variables: {
			limit,
			cursor: Number.MAX_SAFE_INTEGER,
		},
		onCompleted: (data) => {
			setHasMore((data?.image || []).length === limit);
		},
		fetchPolicy: 'network-only',
	});

	const items = (data?.image || []) as Image[];

	function loadMore() {
		if (!has_more || loading || items.length === 0) {
			return;
		}
		fetchMore({
			variables: {
				limit,
				cursor: items[items.length - 1].id,
			},
			updateQuery: (prev_result: any, { fetchMoreResult }) => {
				const prev_items = prev_result?.image || [];
				const new_items = (fetchMoreResult?.image || []);
				setHasMore(new_items.length === limit);
				return {
					image: [...prev_items, ...new_items],
					__typename: prev_result.__typename
				};
			}
		})
	}

	const onPrependImages = (images: Image[]) => {
		const _images = images
			.filter(i => prepend_images
				.findIndex(p => p.key === i.key) === -1);
		setPrependImages(state => [
			..._images,
			...state,
		]);
	}


	return {
		items: [...prepend_images, ...items],
		error,
		loading,
		loadMore,
		has_more,
		onPrependImages,
	}
}