import { Button, createStyles, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Field, FieldArray, FieldProps } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import RemoveIcon from '@material-ui/icons/RemoveCircle';

interface Props extends FieldProps {

}

export const TicketDiscountField = ({
    field: {
        name,
        value,
    }
}: Props) => {
    const classes = useStyles({});
    const codes = (value || []) as {
        code: string;
        price: number;
    }[];

    return <div className={classes.section}>
        <Typography variant='overline'>Discount Codes</Typography>
        <FieldArray
            name={name}
        >{props => <>
            {codes.map((_, idx) => <div className='item' key={idx}>
                <Field
                    name={`${name}.${idx}.code`}
                    component={TextField}
                    className='input'
                    label='Discount Code (e.g. Sale50)'
                    margin='dense'
                    helperText='Case insensitive'
                />
                <Field
                    name={`${name}.${idx}.price`}
                    component={TextField}
                    type='number'
                    className='input'
                    label='Discount Price'
                    margin='dense'
                    helperText='New price when this code is used'
                />
                <IconButton color='secondary'
                    onClick={() => props.remove(idx)}>
                    <RemoveIcon />
                </IconButton>
            </div>)}
            <Button type='button' size='small'
                onClick={() => props.push({
                    price: 0,
                    code: '',
                })} fullWidth>
                + Add Discount Code
            </Button>
        </>}
        </FieldArray>

    </div>
}

const useStyles = makeStyles(theme => createStyles({
    section: {
        '& .item': {
            display: 'flex',
            padding: theme.spacing(.5, 0),
            '& .input': {
                flex: 1,
                marginRight: theme.spacing(1)
            }
        }
    }
}))