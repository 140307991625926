import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useGetByPk } from "../../apollo/networking/use-get-by-pk"
import { FeatureFlag } from "../../auth/feature-flags"
import { useOrg } from "../../auth/use-org"
import { AppLayout } from "../../layout/app-layout"
import { EventFragment, EventModel } from "../../models/event.model"
import { ErrorPage } from "../../ui/errors"
import { LoadingDots } from "../../ui/loading-dots"
import { PageHeader } from "../../ui/page-header"
import { AttendanceList } from "./attendance-list"

type Props = {
    event_id: number;
}

export const EventAttendancePage = ({
    event_id,
}: RouteComponentProps<Props>) => {
    const { entity, loading } = useGetByPk<EventModel>({
        entity_name: 'event',
        fragment: EventFragment,
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: event_id,
        }]
    })
    const is_attendance_active = useOrg().getFeatureFlag(FeatureFlag.attendance);

    if (!is_attendance_active) {
        return <ErrorPage
            title='Feature is not active!'
            message='Check in/Check out feature is not active on your account. Please contact us at help@certifysimple.com to activate this feature.'
        />
    }
    return <AppLayout>
        <PageHeader title='Attendance' subtitle='Check-ins & outs' />
        {loading ? <LoadingDots /> : null}
        {entity ? <AttendanceList event={entity} /> : null}
    </AppLayout>
}
