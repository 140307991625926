export type EventResourceModel = {
    id: number;
    created_at: string;
    deleted_at?: string;
    link: string;
    name: string;
}

export const EventResourceFragment = `
    id
    created_at
    deleted_at
    link
    name
`;