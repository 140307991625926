import { Box, Button, createStyles, InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import React from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import ReactPlayer from 'react-player'
import { useSubscribeByPk } from "../../apollo/networking/use-subscribe-by-pk"
import { useAppDialog } from "../../app-dialog"
import { environment } from "../../environment"
import { AppLayout } from "../../layout/app-layout"
import { WebinarFragment, WebinarModel } from "../../models/webinar.model"
import { LoadingDots } from "../../ui/loading-dots"
import { PageHeader } from "../../ui/page-header"
import { WebinarQuestions } from "./webinar-questions"
import { WebinarState } from "./webinar-state"

type Props = {
    attendance_id: number;
    event_id: number;
}

export const WebinarPage = ({
    attendance_id,
    event_id,
}: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const {
        entity: webinar,
        loading,
    } = useSubscribeByPk<WebinarModel>({
        entity_name: 'attendance',
        fragment: WebinarFragment,
        pk_columns: [{
            value: attendance_id,
            type: 'bigint',
            name: 'id',
        }],
    })
    const webinar_url = `${environment.user_url}/e/${event_id}/w/${attendance_id}`;

    return <AppLayout>
        {loading ? <LoadingDots /> : null}
        <PageHeader
            title='Webinar'
            subtitle={webinar?.name}
        />
        {webinar ? <>
            <Box pb={2}>
                <Typography variant='body1'>Share webinar with attendees</Typography>
                <TextField
                    fullWidth
                    margin='dense'
                    value={webinar_url}
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>
                            <CopyToClipboard
                                text={webinar_url}
                                onCopy={() => {
                                    app_dialog.showSnackbar('Link copied')
                                }}>
                                <Button size='small'>Copy</Button>
                            </CopyToClipboard>
                        </InputAdornment>
                    }}
                />
                <WebinarState
                    webinar={webinar}
                />
            </Box>
            {!webinar?.youtube_url ? <Box py={4} textAlign='center'>
                <Typography>Video not available. Please wait and this will update automatically...</Typography>
            </Box> : <div className={classes.player}>
                    <ReactPlayer
                        url={webinar?.youtube_url}
                        width='100%'
                        height='100%'
                        controls={true}
                        playing
                    />
                </div>}
            <WebinarQuestions
                attendance_id={attendance_id as number}
            />
        </> : null}
    </AppLayout>
}


const useStyles = makeStyles(theme => createStyles({
    player: {
        width: '100%',
        height: 0,
        paddingTop: '56.25%',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: 'grey',
        '& iframe': {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
        }
    }
}))