import { gql, useMutation } from "@apollo/client";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import { Form, Formik } from "formik";
import React from "react";
import * as yup from 'yup';
import { useAppDialog } from "../../app-dialog";
import { useOrg } from "../../auth/use-org";
import { AppLayout } from "../../layout/app-layout";
import { OrganizationFragment } from "../../models/organization.model";
import { EdContainer } from "../../ui/ed-container";
import { Section } from "../../ui/section";
import { SupportedCardsComponent } from "../../ui/supported-cards";
import { StripeAuthorization } from "../stripe-connect/stripe-authorization";
import { OrgEditForm } from "./org-edit-form";

type Props = {

}

const MeValidationSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Invalid email address')
        .required('Email is required'),
    phone: yup.string(),
    description: yup.string(),
    pace_id: yup.number().typeError('PACE ID must be a number').min(1, 'PACE ID must be a number'),
});

const InsertUser = gql`
    mutation update_org($org_id: bigint!, $set: organization_set_input!) {
        update_organization_by_pk(
            pk_columns: {id: $org_id},
            _set: $set
        ) {
            ${OrganizationFragment}
        }
    }
`;

export const OrgEditPage = (props: RouteComponentProps<Props>) => {
    const app_dialog = useAppDialog();
    const [insert, status] = useMutation(InsertUser);
    const { org, org_id } = useOrg();
    const onSubmit = async (values: any) => {
        try {
            await insert({
                variables: {
                    org_id,
                    set: values,
                }
            })
        } catch (e: any) {
            app_dialog.showError(e);
        }
    }

    return <AppLayout no_container>
        <EdContainer max_width='md'>
            <Formik
                initialValues={{
                    name: org?.name || '',
                    description: org?.description || '',
                    email: org?.email || '',
                    phone: org?.phone || '',
                    logo_image_key: org?.logo_image_key || '',
                    pace_id: org?.pace_id || '',
                    pace_start: org?.pace_start || null,
                    pace_end: org?.pace_end || null,
                    pace_key: org?.pace_key || '',
                    cerp_start: org?.cerp_start || null,
                    cerp_end: org?.cerp_end || null,
                    is_cerp_enabled: org?.is_cerp_enabled || false,
                    is_referral_program_enabled: org?.is_referral_program_enabled || false,
                }}
                validationSchema={MeValidationSchema}
                onSubmit={onSubmit}>{props => <Form noValidate>
                    <OrgEditForm {...props}
                        loading={status.loading} />
                </Form>}
            </Formik>
            <Section title='Payments Setup'>
                {org.stripe_id ? <Box display='flex' flexDirection='column' alignItems='center'>
                    <Typography align='center' variant='h6' color='primary'>
                        Awesome, your Stripe account is now linked with <strong>edropin.</strong>
                    </Typography>
                    <Typography variant='body1'>
                        Stripe ID {org.stripe_id}
                    </Typography>
                    <SupportedCardsComponent />
                </Box> : <>
                        <StripeAuthorization />
                    </>}

            </Section>
        </EdContainer>
    </AppLayout>
}
