import { IconButton, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import BackIcon from '@material-ui/icons/ArrowBack';
import { Link, useLocation } from '@reach/router';
import React from 'react';
import { useOrg } from '../auth/use-org';
import { UnstyledLink } from '../ui/unstyled-link';
import { UserMenu } from './user-menu';


type Props = {
  onBack?: () => void;
}

const paths = [
  { link: '/events', label: 'Events' },
  { link: '/sales', label: 'Sales' },
  // { link: '/settings', label: 'Settings' },
]

export const AppHeader: React.FC<Props> = ({ onBack }) => {
  const classes = useStyles();
  const location = useLocation();
  const {
    org,
  } = useOrg();

  const path_name = location.pathname;
  const _paths = React.useMemo(() => {
    return paths.map(p => ({
      ...p,
      active: path_name === '/' ? p.link === '/events' : path_name.startsWith(p.link),
    }))
  }, [path_name])

  return (
    <div className={classes.root}>
      <AppBar color='default' position='fixed'>
        <Toolbar>
          {onBack ? <IconButton onClick={onBack}>
            <BackIcon />
          </IconButton> : null}
          <UnstyledLink to={'/'} className={classes.title}>
            <Typography variant='h6'>{org.name}</Typography>
          </UnstyledLink>
          <div style={{ flex: 1 }} />
          <div className={classes.links}>
            {_paths.map(p => <Link
              key={p.link}
              to={p.link} className={p.active ? 'active' : ''}>{p.label}</Link>)}
          </div>
          <UserMenu />
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginTop: 2,
    '& .logo': {
      height: 30,
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block'
      },
    },
    '& .icon': {
      height: 30,
      [theme.breakpoints.up('md')]: {
        display: 'none'
      },
    }
  },
  links: {
    '& a': {
      display: 'inline-block',
      padding: theme.spacing(1),
      color: 'inherit',
      textDecoration: 'none',
      '&.active': {
        fontWeight: 'bold',
      }
    }
  },
  profileImage: {
    width: 40,
    height: 40,
    borderRadius: '50%',
  },
  toolbar: {
    ...theme.mixins.toolbar,
  }
}));