import { Button, createStyles, Link, makeStyles, TableCell, TableRow, Tooltip } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/CheckCircle';
import moment from "moment";
import React from "react";
import { environment } from "../../environment";
import { AttendeeDiplomaModel } from "../../models/attendee-diploma.model";
import { DiplomaAttendeeCol } from "./use-diploma-attendee-columns";

type Props = {
    item: AttendeeDiplomaModel;
    toggleAssign: (item: AttendeeDiplomaModel, assign: boolean) => void;
    showColumn: (id: DiplomaAttendeeCol) => boolean;
}

export const DiplomaAttendeeItem = ({
    item,
    toggleAssign,
    showColumn,
}: Props) => {
    const classes = useStyles({});
    const attendee_diploma = React.useMemo(() => item
        .attendee_diplomas && item.attendee_diplomas.length > 0
        && !item.attendee_diplomas[0].deleted_at ?
        item.attendee_diplomas[0] : undefined, [item.attendee_diplomas]);

    return <TableRow
        hover
    >
        <TableCell>
            <div className={classes.buttons}>
                <Tooltip title='Assign certificate'>
                    <Button
                        variant={!!attendee_diploma ? 'contained' : 'text'}
                        color={!!attendee_diploma ? 'secondary' : 'inherit'}
                        startIcon={<CheckIcon />}
                        size='small' onClick={(e) => toggleAssign(
                            item, !!attendee_diploma ? false : true)}>
                        {!!attendee_diploma ? 'Remove' : 'Assign'}
                    </Button>
                </Tooltip>
            </div>
        </TableCell>
        <TableCell style={{ width: 50 }}>
            {item.id}
        </TableCell>
        <TableCell style={{
            minWidth: 150,
        }}>{item.name}</TableCell>
        {showColumn('email') ? <TableCell style={{
            minWidth: 200,
        }}>{item.email}</TableCell> : null}
        <TableCell style={{
            minWidth: 200,
        }}>{attendee_diploma ? moment(attendee_diploma.updated_at).calendar() : null}</TableCell>
        <TableCell>
            {attendee_diploma ? <Link
                target='_blank'
                href={`${environment.frontend_url}/diplomas/${attendee_diploma.secret}`}>Viewer Link</Link>
                : null}
        </TableCell>
        {showColumn('ticket') ? <TableCell>{item.ticket.name}</TableCell> : null}
        {showColumn('agd_id') ? <TableCell>{item.agd_id}</TableCell> : null}
    </TableRow>
}

const useStyles = makeStyles(theme => createStyles({
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}))