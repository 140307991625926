import DateFnsUtils from '@date-io/moment';
import { Box, createStyles, InputAdornment, makeStyles, Typography } from "@material-ui/core";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Field, FormikProps } from "formik";
import { Switch, TextField } from "formik-material-ui";
import { DateTimePicker } from 'formik-material-ui-pickers';
import moment from 'moment';
import React from "react";

interface Props extends FormikProps<any> {
}

export const EventForm = ({
    setFieldValue,
    setFieldTouched,
    values,
}: Props) => {
    const classes = useStyles({});

    const onChangeStartDate = (d: MaterialUiPickersDate) => {
        const date = d?.toISOString()
        if (!date) {
            return;
        }
        setFieldValue('starts_at', date)
        setFieldTouched('starts_at', true, true)
        const end_date_str = values.ends_at as string;
        if (!end_date_str) {
            setFieldValue('ends_at', moment(date).add(8, 'hour').toISOString());
        }
    }

    return <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Field
            name='title'
            component={TextField}
            label='Title'
            margin='normal'
            fullWidth
        />
        <Box display='flex'
            alignItems='center'
            justifyContent='space-between' pt={1} pb={1}>
            <Typography>Is Event Live</Typography>
            <Field
                name='is_live'
                type='checkbox'
                component={Switch}
            />
        </Box>
        <Field
            name='starts_at'
            component={DateTimePicker}
            fullWidth
            onChange={onChangeStartDate}
            margin='normal'
            label='Starts At'
        />
        <Field
            name='ends_at'
            component={DateTimePicker}
            fullWidth
            margin='normal'
            label='Ends At'
        />
        <Field
            name='url'
            component={TextField}
            label='Event Url (Optional)'
            margin='normal'
            fullWidth
            helperText='Use this to create a custom url for social sharing'
        />
    </MuiPickersUtilsProvider>
}

const useStyles = makeStyles(theme => createStyles({

}))