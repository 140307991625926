import gql from "graphql-tag";

export const STRIPE_CONNECT_MUTATION = gql`
	mutation stripe_connect($code: String!) {
		stripe_connect(object: {
			code: $code,
		}) {
			success
		}
	}
`;