import { useSubscription, gql } from "@apollo/client";
 import React from "react";

const QUERY_GET_BY_PK = (props: {
	entity_name: string;
	variables: string;
	query: string;
	fragment: string;
}) => gql`
 subscription query_${props.entity_name}(${props.variables}) {
	${props.entity_name}_by_pk(${props.query}) {
		${props.fragment}
	}
}
`;

type Props = {
	entity_name: string;
	pk_columns: {
		name: string;
		value: any;
		type: 'uuid' | 'bigint' | 'String' | 'smallint'
	}[];
	fragment: string;
}

export const useSubscribeByPk = <T extends any>({
	...props
}: Props): {
	loading?: boolean;
	error?: any;
	data: any;
	entity: T | undefined;
} => {
	const {
		entity_name,
		pk_columns,
		fragment,
	} = props;
	const [document_node, variables] = React.useMemo(() => {
		const _query = pk_columns
			.map(col => `${col.name}: $${col.name}`)
			.join(',');
		const _variables = pk_columns
			.map(col => `$${col.name}: ${col.type}!`)
			.join(',');

		const document_node = QUERY_GET_BY_PK({
			entity_name,
			query: _query,
			variables: _variables,
			fragment,
		});
		const variables = pk_columns.reduce((obj, col) => ({
			...obj,
			[col.name]: col.value
		}), {});
		return [document_node, variables];
	}, [pk_columns, entity_name, fragment])

	const { data, loading, error } = useSubscription(document_node, {
		variables,
	});
	const entity = data ? data[`${entity_name}_by_pk`] as T : undefined;

	return {
		loading,
		error,
		data,
		entity,
	}
}