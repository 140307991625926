import { Currency } from "./currency";

export type InvoiceItemModel = {
    id: number;
    created_at: string;
    updated_at: string;
    invoice_id: number;
    ticket_id: number;
    quantity: number;
    price: number;
    discount: number;
    subtotal: number;
    amount: number;
    sold_at?: string;
    ticket: {
        id: number;
        name: string;
    };
    invoice: {
        id: number;
        name: string;
        email: string;
        currency: Currency;
        agd_id?: string;
        amount: number;
        refund_tally?: {
            id: number;
            total_amount: number;
        }
        referrer?: {
            id: number;
            email: string;
            name: string;
        };
    };
    data: {[id: string]: any};
}

export const InvoiceItemFragment = `
    id
    created_at
    updated_at
    invoice_id
    ticket_id
    quantity
    schema
    price
    discount
    amount
    subtotal
    sold_at
    ticket {
        id
        name
    }
    invoice {
        id
        name
        email
        currency
        agd_id
        amount
        refund_tally {
            id
            total_amount
        }
        referrer {
            id
            name
            email
        }
    }
    data
`;