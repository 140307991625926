import { useStorage } from "../../utils/use-storage";

const columns_key = 'attendee_cols';

export type AttendeeCol = ('email' | 'ticket' | 'agd_id' | 'is_email_enabled');
export type AttendeeCols = AttendeeCol[];

const columns: {
    id: AttendeeCol;
    label: string;
}[] = [{
    id: 'email',
    label: 'Email',
},{
    id: 'ticket',
    label: 'Ticket',
},{
    id: 'agd_id',
    label: 'AGD ID',
},{
    id: 'is_email_enabled',
    label: 'Send Email',
},]
export const useAttendeeCols = () => {
    const {
        getKey,
        setKey,
    } = useStorage();
    const cols = getKey(columns_key, []) as AttendeeCols;
    const setCols = (v: AttendeeCols) => setKey(columns_key, v);

    const showColumn = (id: AttendeeCol) => {
        return cols.indexOf(id) > -1;
    }

    const toggleColumn = (id: AttendeeCol) => {
        if (!showColumn(id)) {
            setCols([
                ...cols, id,
            ]);
        } else {
            setCols(cols.filter(c => c !== id));
        }
    }

    return {
        cols,
        columns,
        setCols,
        showColumn,
        toggleColumn,
    };
}