import React from "react";

const KEY_DATA = 'ed.user_data';
const KEY_PERSISTENT_DATA = 'ed.persistent_data';
export const useStorage = () => {
	const [is_ready, setIsReady] = React.useState(false);
	const [data, setData] = React.useState<any>({});
	const [persistent_data, setPersistentData] = React.useState<any>({});

	React.useEffect(() => {
		setData(JSON.parse(localStorage.getItem(KEY_DATA) || '{}'));
		setPersistentData(JSON.parse(localStorage.getItem(KEY_PERSISTENT_DATA) || '{}'));
		setIsReady(true);
	}, [])
	
	const getKey = (key: string, value?: any) => {
		const result = data[key];
		return result !== undefined && result !== null ? result : value;
	}

	const getPersistentKey = (key: string, value?: any) => {
		const result = persistent_data[key];
		return result !== undefined && result !== null ? result : value;
	}

	const setKey = (key: string, v: any) => {
		setData((state: any) => {
			const _state = {
				...state,
				[key]: v,
			}
			localStorage.setItem(KEY_DATA, JSON.stringify(_state));
			return _state;
		});
	}

	const setPersistentKey = (key: string, v: any) => {
		setPersistentData((state: any) => {
			const _state = {
				...state,
				[key]: v,
			}
			localStorage.setItem(KEY_PERSISTENT_DATA, JSON.stringify(_state));
			return _state;
		});
	}

	const reset = (all?: boolean) => {
		setData({});
		localStorage.setItem(KEY_DATA, JSON.stringify({}));
		if (all) {
			setPersistentData({})
			localStorage.setItem(KEY_PERSISTENT_DATA, JSON.stringify({}));
		}
	}

	return {
		getKey,
		getPersistentKey,
		setKey,
		setPersistentKey,
		reset,
		is_ready,
	}
}