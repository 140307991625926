import { Box, createStyles, makeStyles, MenuItem, Typography } from "@material-ui/core";
import { Link } from "@reach/router";
import { Field } from "formik";
import { Switch, TextField } from "formik-material-ui";
import React from "react";
import { useList } from '../../../apollo/networking';
import { TicketModel } from '../../../models/ticket.model';
import { LoadingDots } from '../../../ui/loading-dots';
import { getFormattedCurrency } from "../../../utils/get-formatted-currency";

type Props = {
    event_id: number;
}

export const AttendeeForm = ({ event_id }: Props) => {
    const {
        loading,
        items,
    } = useList<TicketModel>({
        entity_name: 'ticket',
        fragment: `id name price`,
        query_inner: `where: {event_id: {_eq: $event_id}}`,
        clause_outer: `$event_id: bigint!`,
        variables: {
            event_id,
        }
    });

    const refreshPage = (e: any) => {
        if (e.preventDefault) {
            e.preventDefault();
        }
        window.location.reload();
    }

    if (loading) {
        return <LoadingDots />
    }
    if (items.length === 0) {
        return <Typography>This event has no tickets.
           You must <Link to={`/events/${event_id}/tickets?add=1`}>create a ticket</Link> before adding an attendee. If you just added a ticket and it didn't show up, <a
                onClick={refreshPage} href="javascript:void();">click here</a> to refresh the page.
        </Typography>
    }
    return <>
        <Field
            name='name'
            component={TextField}
            label='Name'
            margin='normal'
            fullWidth
        />
        <Field
            name='email'
            type='email'
            component={TextField}
            label='Email'
            margin='normal'
            fullWidth
        />
        <Field
            name='ticket_id'
            component={TextField}
            label='Select Ticket'
            margin='normal'
            select
            fullWidth
        >{items.map(i => <MenuItem key={i.id} value={i.id}>
            {i.name} - {getFormattedCurrency(i.price)}
        </MenuItem>)}
        </Field>
        <Field
            name='agd_id'
            component={TextField}
            label='AGD ID'
            margin='normal'
            fullWidth
        />
        <Box display='flex' alignItems='center'>
            <div style={{ flex: 1 }} >
                <Typography variant='body1'>Send this ticket as an email</Typography>
            </div>
            <Field
                name='is_email_enabled'
                component={Switch}
                type='checkbox'
            />
        </Box>
    </>
}

const useStyles = makeStyles(theme => createStyles({

}))