import { Button, createStyles, IconButton, makeStyles, Typography } from "@material-ui/core"
import EmailIcon from '@material-ui/icons/Email'
import React from "react"
import { useOrg } from "../../auth/use-org"
import { environment } from "../../environment"
import { ImageSize, LazyImage } from "../../image-upload"


type Props = {
}

export const EventOrganizer = ({
}: Props) => {
    const {
        org,
    } = useOrg()
    const classes = useStyles({});
    return <div className={classes.org}>
        <LazyImage
            image_opt={{
                size: ImageSize.small,
            }}
            image_key={org.logo_image_key}
            placeholder='/images/avatar.jpg'
        />
        <div className='about'>
            <a href={`${environment.frontend_url}/${org.username}`}><Typography variant='body1'>
                <strong>{org.name}</strong></Typography></a>
            <Button size='small'
                startIcon={<EmailIcon fontSize='small' />}
                href={`mailto:${org.email}`}
            >Contact us</Button>
        </div>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    org: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: theme.spacing(2),
        '& img': {
            width: 50,
            height: 50,
            borderRadius: '50%',
        },
        '& .about': {
            paddingLeft: theme.spacing(3),
            '& a': {
                color: 'inherit',
                textDecoration: 'none',
                '&:hover': {
                    textDecoration: 'underline',
                }
            }
        }
    }
}))