import { Button, createStyles, IconButton, makeStyles, MenuItem, Switch, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import React from 'react';
import { useDebounceValue } from '../../../utils/use-debounce-value';
import { NodeType, nodeTypeFromJSON, nodeTypeHasEnum, NodeTypeOptions, nodeTypeToJSON, SchemaProperty, VisualNodeModel } from './models';
import { NodeOptionEditorComponent } from './node-option-editor.component';

type Props = {
	is_up_enabled?: boolean;
	is_down_enabled?: boolean;
	move: (direction: 'up' | 'down') => void;
	node: VisualNodeModel;
	onAdd: (e: React.MouseEvent) => void;
	onDelete: () => void;
	onUpdateNode: (json: VisualNodeModel) => void;
}

export const VisualNodeComponent = ({
	is_up_enabled,
	is_down_enabled,
	move,
	node,
	onAdd,
	onDelete,
	onUpdateNode,
}: Props) => {
	const classes = useStyles({});
	const node_type = nodeTypeFromJSON(node)
	const { property, meta: additionalProperties } = node;
	const [local_props, setLocalProps] = React.useState({
		node_type,
		title: property.title,
	});
	const [additional_props, setAdditionalProps] = React.useState(additionalProperties || {})
	const debounced = useDebounceValue(local_props, 1000);

	React.useEffect(() => {
		if (local_props.node_type === node_type
			&& local_props.title === property.title) {
			return;
		}
		onUpdateNode({
			...node,
			...nodeTypeToJSON(local_props.node_type, {
				...property,
				title: local_props.title,
			}),
			meta: additional_props,
		})
	}, [debounced])

	const onChangeRequired = (checked: boolean) => {
		onUpdateNode({
			...node,
			is_required: checked,
		})
	}

	const has_enum = nodeTypeHasEnum(node_type);
	const updateOptions = React.useCallback((property: SchemaProperty) => onUpdateNode({
		...node,
		property,
	}), [onUpdateNode])

	const updateGrading = React.useCallback((answers: any) => {
		onUpdateNode({
			...node,
			answers,
		})
	}, [onUpdateNode])

	const updateLocalProps = React
		.useCallback((field: 'title' | 'id' | 'node_type' | 'additionalProperties',
			value: string) => setLocalProps(state => ({
				...state,
				[field]: value,
			})), [setLocalProps])

	const updateAdditionalProps = React
		.useCallback((value: any) => {
			setAdditionalProps(value);
			onUpdateNode({
				...node,
				meta: value,
			});
		}, [setAdditionalProps, onUpdateNode])

	return <div className={classes.item}>
		<div className={classes.reorder}>
			<IconButton disabled={!is_up_enabled} onClick={() => move('up')}>
				<UpIcon />
			</IconButton>
			<IconButton disabled={!is_down_enabled} onClick={() => move('down')}>
				<DownIcon />
			</IconButton>
			<IconButton onClick={onAdd}>
				<AddIcon />
			</IconButton>
		</div>
		<div className={classes.left}>
			<TextField
				value={local_props.title}
				label='Prompt/Label'
				multiline
				disabled={node_type === NodeType.membership}
				margin='dense'
				fullWidth
				onChange={e => updateLocalProps('title', e.target.value as string)}
			/>
			<TextField
				value={local_props.node_type}
				label='Type'
				margin='dense'
				fullWidth
				onChange={e => updateLocalProps('node_type', e.target.value as string)}
				select
			>
				{NodeTypeOptions.map(option => <MenuItem
					key={option.id}
					value={option.id}
				>{option.label}</MenuItem>)}
			</TextField>

			{has_enum ? <NodeOptionEditorComponent
				node_type={node_type}
				property={property}
				onUpdateNode={updateOptions}
			/> : null}
			<div className='buttons'>
				<div className={classes.switch}>
					<Typography>Required</Typography>
					<Switch checked={node.is_required} onChange={(_, checked) => onChangeRequired(checked)} />
				</div>
				<div style={{ flex: 1 }} />
				<Button
					variant='outlined'
					color='secondary'
					onClick={onDelete}>Delete</Button>
			</div>
		</div>
	</div>;
}

const useStyles = makeStyles((theme) => createStyles({
	item: {
		display: 'flex',
		padding: theme.spacing(1),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		borderBottom: `1px solid ${theme.palette.divider}`,
		position: 'relative',
		'&:last-child': {
			borderBottom: 0,
		}
	},
	reorder: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: 60,
	},
	left: {
		flex: 1,
		'& .buttons': {
			paddingTop: theme.spacing(2),
			marginLeft: -theme.spacing(.5),
			display: 'flex',
			alignItems: 'center',
			'& button': {
				margin: theme.spacing(.5),
			}
		}
	},
	switch: {
		display: 'flex',
		alignItems: 'center',
	}
}));