import { Button, createStyles, Link, makeStyles, TableCell, TableRow, Tooltip } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/CheckCircle';
import moment from "moment";
import React from "react";
import { environment } from "../../environment";
import { AttendeeAttendanceModel } from "../../models/attendee-attendance.model";
import { AttendeeCertificateModel } from "../../models/attendee-certificate.model";
import { getDurationText } from "../../utils/time-range";
import { CertAttendeeCol } from "./use-attendance-columns";

type Props = {
    item: AttendeeAttendanceModel;
    toggleAssign: (item: AttendeeAttendanceModel, assign: boolean) => void;
    showColumn: (id: CertAttendeeCol) => boolean;
}

export const CertificateAttendeeItem = ({
    item,
    toggleAssign,
    showColumn,
}: Props) => {
    const classes = useStyles({});
    const attendee_dropin = React.useMemo(() => item
        .dropins && item.dropins.length > 0 ?
        item.dropins[0] : undefined, [item.dropins]);

    return <TableRow
        hover
    >
        <TableCell>
            <div className={classes.buttons}>
                {/* <Tooltip title='Assign certificate'>
                    <Button
                        variant={!!attendee_dropin ? 'contained' : 'text'}
                        color={!!attendee_dropin ? 'secondary' : 'inherit'}
                        startIcon={<CheckIcon />}
                        size='small' onClick={(e) => toggleAssign(
                            item, !!attendee_dropin ? false : true)}>
                        {!!attendee_dropin ? 'Remove' : 'Assign'}
                    </Button>
                </Tooltip> */}
            </div>
        </TableCell>
        <TableCell style={{ width: 50 }}>
            {item.id}
        </TableCell>
        <TableCell style={{
            minWidth: 150,
        }}>{item.name}</TableCell>
        {showColumn('email') ? <TableCell style={{
            minWidth: 200,
        }}>{item.email}</TableCell> : null}
        <TableCell style={{
            minWidth: 200,
        }}>{attendee_dropin?.checkin_at ? moment(attendee_dropin.checkin_at).format('L hh:mm a') : null}</TableCell>
        <TableCell style={{
            minWidth: 200,
        }}>
            {attendee_dropin?.checkout_at ? moment(attendee_dropin.checkout_at).format('L hh:mm a') : null}
        </TableCell>
        <TableCell>{!!attendee_dropin?.checkin_at && !!attendee_dropin?.checkout_at
            ? getDurationText((+new Date(attendee_dropin.checkout_at) - +new Date(attendee_dropin.checkin_at))/1000) : '-'}</TableCell>
        {showColumn('ticket') ? <TableCell>{item.ticket.name}</TableCell> : null}
        {showColumn('agd_id') ? <TableCell>{item.agd_id}</TableCell> : null}
    </TableRow>
}

const useStyles = makeStyles(theme => createStyles({
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}))