import { gql, useMutation } from "@apollo/client";
import { useAppDialog } from "../../../app-dialog";
import { InvoiceItemFragment, InvoiceItemModel } from "../../../models/invoice-item.model";

const UpdateInvoiceItemMutation = gql`
    mutation move_invoice_item($invoice_item_id: bigint!, $ticket_id: bigint!) {
        update_invoice_item_by_pk(
            pk_columns: {
                id: $invoice_item_id
            },
            _set: {
                ticket_id: $ticket_id
            }
        ) {
            ${InvoiceItemFragment}
        }
    }
`;

export const useInvoiceItemMove = () => {
    const app_dialog = useAppDialog();
    const [updateInvoiceItem] = useMutation(UpdateInvoiceItemMutation);

    return {
        moveinvoiceItem: async (invoice_item: InvoiceItemModel, ticket_id: number) => {
            try {
                await updateInvoiceItem({
                    variables: {
                        invoice_item_id: invoice_item.id,
                        ticket_id,
                    }
                });
                app_dialog.showSnackbar('Invoice item ticket changed');
                return true;
            } catch (e) {
                app_dialog.showError(e);
                return false;
            }
        }
    }
}