import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import React from 'react';
import { DynamicForm } from '../../ui/dynamic-form';
import { VisualEditorComponent } from './visual-form-builder';

interface Props extends FormikProps<any> {
}

export const EditQuestionnaireFormComponent = ({
	setFieldValue,
	values,
}: Props) => {
	const classes = useStyles({});
	const [form_data, setFormData] = React.useState({});

	return <div className={classes.root}>
		<div className={classes.formEditor}>
			<VisualEditorComponent
				schema={values.schema}
				ui_schema={values.ui_schema}
				onChangeSchema={schema => setFieldValue('schema', schema)}
				onChangeUiSchema={schema => setFieldValue('ui_schema', schema)}
			/>
		</div>
		{/* <DevFormValues values={values} /> */}

		<div className={classes.preview}>
			<Typography variant='h6'>Live Preview</Typography>
			<DynamicForm
				schema={values.schema}
				ui_schema={values.ui_schema}
				id='questionnaire-preview'
				form_data={form_data}
				onChange={({ formData }) => setFormData(formData)}
			/>
		</div>
	</div>;
}

const useStyles = makeStyles(theme => createStyles({
	root: {
		display: 'flex',
	},
	formEditor: {
		flex: 1,
		paddingRight: theme.spacing(2),
		borderRight: `1px solid ${theme.palette.divider}`,
		[theme.breakpoints.down('sm')]: {
			borderRight: 0,
			paddingRight: 0,
		}
	},
	preview: {
		paddingLeft: theme.spacing(2),
		width: '40%',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		}
	},
}))