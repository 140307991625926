import { DialogContent } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { useUpdateByPk } from "../../../apollo/networking/use-update-by-pk";
import { useAppDialog } from "../../../app-dialog";
import { AttendanceFragment, AttendanceModel } from "../../../models/attendance.model";
import { BasicDialogActions } from "../../../ui/dialog";
import { LoadingButton } from "../../../ui/loading-button";
import { AttendanceForm } from "./attendance.form";
import { getTicketSubmission, AttendanceInitialValues, AttendanceValidationSchema } from "./attendance.validation";


type Props = {
    item: AttendanceModel;
    onFinish: (product: AttendanceModel) => void;
}

export const AttendanceEdit = ({
    onFinish,
    item,
}: Props) => {
    const {
        onUpdate,
        loading,
    } = useUpdateByPk({
        entity_name: 'attendance',
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: item.id,
        }],
        fragment: AttendanceFragment,
    });

    const app_dialog = useAppDialog();

    const onSubmit = async (values: any) => {
        try {
            const result = await onUpdate(
                getTicketSubmission(values)
            ) as AttendanceModel;
            if (!!result) {
                onFinish(result);
            }
        } catch (e: any) {
            app_dialog.showError(e);
        }
    }
    return <Formik initialValues={AttendanceInitialValues(item)}
        validationSchema={AttendanceValidationSchema}
        onSubmit={onSubmit}>{(form_props) => <Form noValidate>
            <DialogContent>
                <AttendanceForm {...form_props} />
            </DialogContent>
            <BasicDialogActions>
                <LoadingButton loading={loading}
                    color='primary'
                    size='large'
                    type='submit'
                    variant='contained'>
                    Save
                </LoadingButton>
            </BasicDialogActions>
        </Form>}
    </Formik>
}
