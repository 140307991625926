import { createStyles, makeStyles, Typography, Chip } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import React from 'react';
import { getNodeTypeString, getOptionsForNode, nodeTypeFromJSON, nodeTypeHasEnum, VisualNodeModel } from './models';

type Props = {
	node: VisualNodeModel;
}

export const NonEditableNodeComponent = ({
	node,
}: Props) => {
	const classes = useStyles({});
	const node_type = nodeTypeFromJSON(node)
	const { property } = node;
	const has_enum = nodeTypeHasEnum(node_type);
	const chips = getOptionsForNode(node_type, property)

	return <div className={classes.item}>
		<Typography variant='body1'>{property.title}</Typography>
		<Typography variant='body1'>{getNodeTypeString(node_type)}</Typography>
		<Typography variant='body2'
			color='secondary'>{node.is_required ? 'Required' : 'Not required'}</Typography>
		{has_enum ? <>
			<Typography gutterBottom variant='subtitle2'>Options</Typography>
			{chips.map(chip => <Chip key={chip} style={{marginRight: 8}} label={chip} />)}
		</> : null}
	</div>;
}

const useStyles = makeStyles((theme) => createStyles({
	item: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		borderBottom: `1px solid ${theme.palette.divider}`,
		position: 'relative',
		'&:last-child': {
			borderBottom: 0,
		}
	},
	options: {
		marginTop: theme.spacing(1),
	}
}));