import { Box, Button, createStyles, makeStyles, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { useLocation } from "@reach/router";
import * as qs from 'query-string';
import React from "react";
import { useKeysetPagination } from "../../apollo/networking/use-keyset-pagination";
import { AttendeeFragment, AttendeeModel } from "../../models/attendee.model";
import { EventModel } from "../../models/event.model";
import { ColumnSelector } from "../../ui/column-selector";
import { DeleteConfirmDialog } from "../../ui/delete-confirm.dialog";
import { LoadingButton } from "../../ui/loading-button";
import { AttendeeEditorDialog } from "./attendee-editor/attendee-editor.dialog";
import { AttendeeImportCsv } from "./attendee-import-csv";
import { AttendeeItem } from "./attendee-item";
import AttendeeMoveDialog from "./attendee-move";
import { AttendeeMoveTicketModel } from "./attendee-move/attendee-move.model";
import { useAttendeeMove } from "./attendee-move/use-attendee-move";
import { ExportAttendees } from "./export";
import { AttendeeCol, useAttendeeCols } from "./use-attendee-cols";
import { useUpdateAttendee } from "./use-update-attendee";


type Props = {
    event: EventModel;
}

export const AttendeeList = ({
    event,
}: Props) => {
    const location = useLocation();
    const params = qs.parse(location.search || '');
    const add = params?.add;
    const classes = useStyles({});
    const [show_export, setShowExport] = React.useState(false);
    const [show_move_dialog, setShowMoveDialog] = React.useState<AttendeeModel | undefined>();
    const {
        columns,
        showColumn,
        toggleColumn,
    } = useAttendeeCols();
    const {
        deleteAttendee,
        updating,
    } = useUpdateAttendee();
    const {
        moveAttendee,
    } = useAttendeeMove();
    const [delete_confirm, setDeleteConfirm] = React.useState<AttendeeModel | undefined>();
    const [anchor_el, setAnchorEl] = React.useState<{
        anchor: HTMLButtonElement;
        item: AttendeeModel;
    } | undefined>();
    const [editor, setEditor] = React.useState<{
        action: 'edit' | 'create';
        item?: AttendeeModel;
    } | undefined>();
    const {
        items,
        loading,
        refetch,
        fetchMore,
        has_more,
    } = useKeysetPagination<AttendeeModel>({
        entity_name: 'attendee',
        fragment: AttendeeFragment,
        clause_outer: `$event_id: bigint!, $cursor: bigint!`,
        variables: {
            event_id: event.id,
            cursor: Number.MAX_SAFE_INTEGER,
        },
        query_inner: `
            where: {_and: [
                {ticket: {event_id: {_eq: $event_id}}},
                {id: {_lt: $cursor}},
            ]},
            order_by: {id: desc}
        `
    });

    React.useEffect(() => {
        if (!!add) {
            setEditor({ action: 'create' });
        }
    }, [add]);

    const onFinish = (_: AttendeeModel, is_new?: boolean) => {
        if (is_new) {
            refetch();
        }
        setEditor(undefined);
    }

    const onEdit = (item?: AttendeeModel) => () => {
        if (!item) {
            return;
        }
        setAnchorEl(undefined);
        setEditor({
            action: 'edit',
            item,
        })
    }

    const onDelete = (item?: AttendeeModel) => () => {
        if (!item) {
            return;
        }
        setAnchorEl(undefined);
        setDeleteConfirm(item)
    }

    const onMove = (item?: AttendeeModel) => () => {
        if (!item) {
            return;
        }
        setAnchorEl(undefined);
        setShowMoveDialog(item);
    }

    const onMoveConfirm = async ({
        ticket,
    }: {
        ticket: AttendeeMoveTicketModel;
    }) => {
        const attendee = show_move_dialog;
        if (!attendee) {
            return;
        }
        setShowMoveDialog(undefined);
        await moveAttendee(attendee, ticket.id);
        refetch();
    }

    const onConfirmDelete = async () => {
        if (!delete_confirm) {
            return;
        }
        if (!!await deleteAttendee(delete_confirm.id)) {
            refetch();
        }
        setDeleteConfirm(undefined);
    }

    const loadMore = () => {
        if (items.length === 0) {
            return;
        }
        fetchMore({
            event_id: event.id,
            cursor: items[items.length - 1].id,
        })
    }


    return <>
        <Menu
            open={Boolean(anchor_el)}
            anchorEl={anchor_el?.anchor}
            onClose={() => setAnchorEl(undefined)}
        >
            <MenuItem onClick={onEdit(anchor_el?.item)}>Edit</MenuItem>
            <MenuItem onClick={onMove(anchor_el?.item)}>Change Event/Ticket</MenuItem>
            <MenuItem onClick={onDelete(anchor_el?.item)}><Typography color='secondary'>Delete</Typography></MenuItem>
        </Menu>
        {show_move_dialog ? <AttendeeMoveDialog
            onSelectTicket={onMoveConfirm}
            onClose={() => setShowMoveDialog(undefined)}
        /> : null}
        {delete_confirm ? <DeleteConfirmDialog
            onCancel={() => setDeleteConfirm(undefined)}
            title='Delete Attendee'
            message={`Are you sure you want to delete: ${delete_confirm.name}? This cannot be undone!`}
            // confirm_text='confirm'
            onConfirm={onConfirmDelete}
            deleting={updating}
        /> : null}
        {show_export ? <ExportAttendees
            event_id={event.id}
            event_title={event.title}
            onClose={() => setShowExport(false)}
        /> : null}
        {editor ? <AttendeeEditorDialog
            action={editor.action}
            item={editor.item}
            event_id={event.id}
            onFinish={onFinish}
            onClose={() => setEditor(undefined)} /> : null}

        <div className={classes.header}>
            <Button size='small'
                color='primary'
                onClick={() => setShowExport(true)}
                variant='contained'
                style={{
                    marginRight: 8,
                }}>
                Export
            </Button>
            <Button
                variant='outlined'
                size='small'
                style={{
                    marginRight: 8,
                }}
                onClick={() => setEditor({
                    action: 'create',
                })}>+ Add Attendee</Button>
            <AttendeeImportCsv
                event_id={event.id}
                onRefresh={refetch} />

            <div style={{ flex: 1 }} />
            <ColumnSelector<AttendeeCol>
                showColumn={showColumn}
                toggleColumn={toggleColumn}
                columns={columns}
            />
        </div>
        <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow className='header'>
                        <TableCell />
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                        {showColumn('email') ? <TableCell>Email</TableCell> : null}
                        {showColumn('agd_id') ? <TableCell>AGD ID</TableCell> : null}
                        <TableCell>Created</TableCell>
                        {showColumn('ticket') ? <TableCell>Ticket</TableCell> : null}
                        {showColumn('is_email_enabled') ? <TableCell>Send Email</TableCell> : null}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => <AttendeeItem
                        key={item.id}
                        item={item}
                        showColumn={showColumn}
                        onMore={(e, item) => setAnchorEl({
                            item,
                            anchor: e.currentTarget,
                        })} />)}
                </TableBody>
            </Table>
        </TableContainer>

        {has_more ? <Box py={2}>
            <LoadingButton
                loading={loading}
                onClick={loadMore}
                fullWidth>Load More...</LoadingButton>
        </Box> : null}
    </>
}

const useStyles = makeStyles(theme => createStyles({
    table: {
        width: '100%',
        minWidth: 700,
        background: 'white',
        border: `1px solid ${theme.palette.divider}`,
        '& .header': {
            background: theme.palette.background.paper,
        }
    },
    header: {
        display: 'flex',
        paddingBottom: theme.spacing(2),
    }
}))