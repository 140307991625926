import { Button, createStyles, makeStyles, MenuItem, TextField, Typography } from "@material-ui/core"
import React from "react"
import { useUpdateByPk } from "../../apollo/networking/use-update-by-pk";
import { AttendanceStateOptions } from "../../models/attendance.model";
import { WebinarModel } from "../../models/webinar.model";
import { LoadingButton } from "../../ui/loading-button";


type Props = {
    webinar: WebinarModel;
}

export const WebinarState = ({
    webinar,
}: Props) => {
    const classes = useStyles({});
    const {
        onUpdate,
        loading,
    } = useUpdateByPk({
        entity_name: 'attendance',
        fragment: `id state`,
        pk_columns: [{
            value: webinar.id,
            type: 'bigint',
            name: 'id',
        }],
    })
    const [editable, setEditable] = React.useState(false);
    const [state, setState] = React.useState(webinar.state);
    const [url, setUrl] = React.useState(webinar.youtube_url);

    const handleStateChange = (event: any) => {
        const new_state = event.target.value;
        setState(new_state);
    };

    const handleUrlChange = (event: any) => {
        const new_url = event.target.value;
        setUrl(new_url);
    };

    const apply = () => {
        onUpdate({
            state,
            youtube_url: url,
        })
        setEditable(false);
    }

    const cancel = () => {
        setState(webinar.state);
        setUrl(webinar.youtube_url);
    }

    if (editable) {
        return <>
            <TextField
                label='Webinar Status'
                value={state}
                margin='normal'
                size='small'
                onChange={handleStateChange}
                style={{ minWidth: 300 }}
                select>
                {AttendanceStateOptions.map(o => <MenuItem
                    key={o.id}
                    value={o.id}>{o.label}</MenuItem>)}
            </TextField>
            <TextField
                label='Webinar Url'
                value={url}
                margin='normal'
                size='small'
                onChange={handleUrlChange}
                fullWidth />
            <LoadingButton loading={loading}
                variant='outlined'
                color='primary'
                size='small' onClick={apply}>Apply</LoadingButton>
            <LoadingButton
                variant='outlined'
                loading={loading}
                size='small' onClick={cancel}>Cancel</LoadingButton>
        </>
    }

    return <>
        <Typography variant='overline'>Webinar Status</Typography>
        <Typography variant='body1'>
            {AttendanceStateOptions.find(o => o.id === state)?.label}
        </Typography>
        <Typography variant='overline'>Webinar Url</Typography>
        <Typography variant='body1'>
            {url}
        </Typography>
        <Button
            size='small'
            variant='outlined' onClick={() => setEditable(true)}>Edit</Button>
    </>
}

const useStyles = makeStyles(theme => createStyles({

}))