import { gql, useMutation } from "@apollo/client";

const InsertMutation = gql`
    mutation insert_post($object: webinar_post_insert_input!) {
        insert_webinar_post_one(object: $object) {
            id
        }
    }
`

export const usePostQuestion = (attendance_id: number) => {
    const [insert, insert_status] = useMutation(InsertMutation);

    const postQuestion = async (props: {
        message: string;
        name: string;
    }) => {
        const { data } = await insert({
            variables: {
                object: {
                    ...props,
                    is_public: true,
                    attendance_id,
                }
            }
        });
        return true;
    }

    return {
        postQuestion,
    }
}