import gql from "graphql-tag";
import React from "react";
import { ApolloQueryResult, useQuery } from "@apollo/client";
import { environment } from "../../environment";

const QUERY_LIST = (props: {
	entity_name: string;
	clause?: string;
	query?: string;
	fragment: string;
}) => gql`
query query_${props.entity_name}(${props.clause}, $limit: Int!) {
	${props.entity_name}(${props.query}, limit: $limit) {
		${props.fragment}
	}
}
`;

export type UseListProps<T> = {
	entity_name: string;
	clause_outer?: string;
	query_inner?: string;
	variables?: any;
	fragment: string;
	limit?: number;
}

export type ListResponse<T> = {
	loading?: boolean;
	error?: any;
	data: any;
	refetch: (v?: any) => Promise<ApolloQueryResult<any>>;
	items: T[];
	fetchMore: (s: any) => void;
	has_more?: boolean;
}

export const useKeysetPagination = <T extends any>({
	...props
}: UseListProps<T>): ListResponse<T> => {
	const [has_more, setHasMore] = React.useState(true);
	const limit = props.limit || environment.default_page_size;
	const {
		entity_name,
		clause_outer: clause,
		query_inner: query,
		variables,
		fragment,
	} = props;
	const document_node = React.useMemo(() => {
		return QUERY_LIST({
			entity_name,
			clause,
			query,
			fragment,
		});
	}, [clause, query])
	const {
		data,
		loading,
		error,
		refetch,
		fetchMore: _fetchMore
	} = useQuery(document_node, {
		variables: {
			...variables,
			limit,
		},
		onCompleted: (data => {
			const items = data ? data[entity_name] || [] : [];
			setHasMore(items.length % limit === 0);
		})
	});

	const onRefetch = (v: any) => {
		return refetch(v || variables)
	}

	const fetchMore = (v: any) => {
		_fetchMore({
			variables: {
				...v,
				limit,
			},
			updateQuery: (prev_result: any, { fetchMoreResult }) => {
				const prev_items = prev_result ? prev_result[entity_name] || [] : [];
				const new_items = fetchMoreResult ? fetchMoreResult[entity_name] || [] : [];
				setHasMore(new_items.length === limit);
				return {
					[entity_name]: [...prev_items, ...new_items],
					__typename: prev_result.__typename,
				}
			}
		})
	}

	const items = data ? (data[entity_name] || []) as T[] : [];
	return {
		loading,
		error,
		data,
		items,
		has_more,
		fetchMore,
		refetch: onRefetch,
	}
}