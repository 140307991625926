import { gql } from '@apollo/client';
import { Button, Typography } from '@material-ui/core';
import React from "react";
import { useSubscribeByPk } from '../apollo/networking/use-subscribe-by-pk';
import firebase from '../firebase';
import { OrganizationFragment, OrganizationModel } from '../models/organization.model';
import { EdContainer } from '../ui/ed-container';
import { LoadingDots } from '../ui/loading-dots';
import { FeatureFlag } from './feature-flags';


type OrgContextModel = {
	org_id: number;
	// subscription_status: SubscriptionStatus;
	org: OrganizationModel;
	loading?: boolean;
	getFeatureFlag: (flag: FeatureFlag) => boolean
}


const SUBSCRIBE_TO_ORG = gql`
	subscription org($org_id: bigint!) {
		organization_by_pk(id: $org_id) {
			${OrganizationFragment}		
		}
	}
`;

const OrgContext = React.createContext<OrgContextModel>({} as any);

type Props = {
	org_id: number;
}
const WeekInSeconds = 7 * 24 * 60 * 60;


export const ProvideOrg: React.FC<Props> = ({ org_id, children }) => {
	const { 
		entity: org,
		loading,
	} = useSubscribeByPk<OrganizationModel>({
		entity_name: 'organization',
		fragment: OrganizationFragment,
		pk_columns: [{
			value: org_id,
			type: 'bigint',
			name: 'id',
		}]
	})

	const signout = async () => {
		return firebase.auth().signOut();
	}

	const getFeatureFlag = (flag: FeatureFlag) => {
		if (!org || !org.feature_flags) {
			return false;
		}
		return org.feature_flags[flag] || false;
	}

	if (loading) {
		return <EdContainer>
			{loading ? <LoadingDots /> : null}
		</EdContainer>
	}

	if (!org) {
		return <EdContainer>
			<Typography align='center' variant='h4'>Organization not found</Typography>
			<Button fullWidth onClick={signout}>Sign Out</Button>
		</EdContainer>
	}

	return <OrgContext.Provider value={{
		org_id: org.id,
		loading: loading,
		// subscription_status,
		org,
		getFeatureFlag 
	}}>
		{children}
	</OrgContext.Provider>
}

export const useOrg = (): OrgContextModel => {
	return React.useContext(OrgContext);
}