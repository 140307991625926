import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useGetByPk } from "../../apollo/networking/use-get-by-pk"
import { AppLayout } from "../../layout/app-layout"
import { EventFragment, EventModel } from "../../models/event.model"
import { LoadingDots } from "../../ui/loading-dots"
import { PageHeader } from "../../ui/page-header"
import { AttendeeList } from "./attendee-list"

type Props = {
    event_id: number;
}

export const EventAttendeesPage = ({
    event_id,
}: RouteComponentProps<Props>) => {
    const { 
        loading,
        entity: event,
    } = useGetByPk<EventModel>({
        entity_name: 'event',
        fragment: EventFragment,
        pk_columns: [{
            value: event_id,
            name: 'id',
            type: 'bigint',
        }],
    });
    return <AppLayout>
        <PageHeader title='Attendees' subtitle={event?.title} back_url={`/events/${event?.id}`} />
        {loading ? <LoadingDots /> : null}
        {event ? <AttendeeList event={event} /> : null}
    </AppLayout>
}
