import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from '@apollo/client/utilities';
import { fromPromise } from 'apollo-link';
import React from 'react';
import { ProvideOrg } from '../auth/use-org';
import { environment } from '../environment';
import firebase from '../firebase';
import { SigninPage } from '../pages/signin/signin.page';
import { LoadingDots } from '../ui/loading-dots';


type Props = {
	children: React.ReactNode;
}

export const ProvideApollo = ({ children }: Props) => {
	const [is_ready, setIsReady] = React.useState(false);
	const [id_token, setIdToken] = React.useState<string>();
	const [user_id, setUserId] = React.useState<string>();
	const [org_id, setOrgId] = React.useState<number | undefined>();

	React.useEffect(() => {
		const sub = firebase.auth().onAuthStateChanged(async (firebase_user) => {
			setIsReady(true);
			if (!firebase_user) {
				setIdToken(undefined);
				setUserId(undefined);
				return;
			}
			setUserId(firebase_user.uid);
			const result = await firebase_user.getIdTokenResult();
			const claims = result.claims['https://hasura.io/jwt/claims'];
			if (!!claims && claims['x-hasura-org-id']) {
				setOrgId(claims['x-hasura-org-id'] as number);
				setIdToken(result.token);
				return;
			}
			console.log('need to refresh claims');
			const endpoint = environment.firebase_refresh_url;
			const refresh_result = await fetch(`${endpoint}?uid=${firebase_user.uid}`);
			if (refresh_result.status !== 200) {
				console.error(await refresh_result.json());
				return;
			}
			const refreshed_token = await firebase_user.getIdToken(true);
			setIdToken(refreshed_token);
		});
		return () => sub();
	}, []);

	const onRefresh = async (persist?: boolean) => {
		const id_token = await firebase.auth().currentUser?.getIdToken(true);
		if (persist) {
			setIdToken(id_token);
		}
		return id_token;
	}

	const client = React.useMemo(() => {
		return initApolloClient({ onRefresh });
	}, [id_token]);

	if (!is_ready || !client) {
		return <LoadingDots />
	}

	if (!user_id || !org_id) {
		return <SigninPage />
	}

	return <ApolloProvider client={client}>
		<ProvideOrg org_id={org_id}>
			{children}
		</ProvideOrg>
	</ApolloProvider>
}

const initApolloClient = ({
	onRefresh,
}: {
	onRefresh: (persist?: boolean) => Promise<string | undefined>;
}) => {
	let isRefreshing = false;
	let pendingRequests: any = [];
	const resolvePendingRequests = () => {
		pendingRequests.map((callback: any) => callback());
		pendingRequests = [];
	};
	const errorLink = onError(
		({ networkError, graphQLErrors, operation, forward }) => {
			if (networkError) {
				const err = networkError as any;
				const code = err && err.extensions && err.extensions.code;
				if (code === "start-failed") {
					console.error("Network: websocket start failed:", err.message);
				}
				console.error('Network error:', err);
				return;
			}
			if (graphQLErrors && graphQLErrors
				.findIndex(error => error.message === 'Could not verify JWT: JWTExpired') > -1) {
				let forward$;
				if (!isRefreshing) {
					isRefreshing = true;
					console.info('Refreshing token');
					forward$ = fromPromise(
						onRefresh(true)
							.then(() => {
								return true;
							})
							.then(() => {
								resolvePendingRequests();
								return true;
							})
							.catch(() => {
								pendingRequests = [];
								return false;
							})
							.finally(() => {
								isRefreshing = false;
							})
					);
				} else {
					forward$ = fromPromise(
						new Promise(resolve => {
							pendingRequests.push(() => resolve(0));
						})
					);
				}
				return forward$.flatMap(() => {
					return forward(operation);
				}) as any;
			}
		}
	);

	const authLink = setContext(async (operation, { headers }) => {
		const current_user = firebase.auth().currentUser;
		if (!current_user) {
			return {
				headers: {},
			}
		}
		return current_user.getIdToken()
			.then((token) => {
				return {
					headers: {
						...headers,
						'x-hasura-role': 'editor',
						authorization: token ? `Bearer ${token}` : ''
					}
				}
			})
	});

	const httpLink = new HttpLink({
		uri: environment.http_url,
	});

	const wsLink = new WebSocketLink({
		uri: environment.ws_url,
		options: {
			reconnect: true,
			lazy: true,
			connectionParams: async () => {
				const id_token = await onRefresh(false);
				if (!id_token) {
					return;
				}
				return {
					headers: {
						'x-hasura-role': 'editor',
						Authorization: `Bearer ${id_token}`,
					},
				}
			},
		}
	});

	const link = split(
		({ query }) => {
			const definition = getMainDefinition(query);
			return (
				definition.kind === 'OperationDefinition' &&
				definition.operation === 'subscription'
			)
		},
		wsLink,
		from([authLink, httpLink]),
	);

	return new ApolloClient({
		link: from([errorLink, link]),
		connectToDevTools: !environment.production,
		cache: new InMemoryCache(),
	});
}
