import { gql, useMutation } from "@apollo/client";
import { Box, Button, createStyles, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import { useKeysetPagination } from "../../apollo/networking/use-keyset-pagination";
import { useAppDialog } from "../../app-dialog";
import { AttendanceModel } from "../../models/attendance.model";
import { AttendeeAttendanceFragment, AttendeeAttendanceModel } from "../../models/attendee-attendance.model";
import { AttendeeCertificateFragment } from "../../models/attendee-certificate.model";
import { ColumnSelector } from "../../ui/column-selector";
import { LoadingButton } from "../../ui/loading-button";
import { AttendanceEditorDialog } from "../event-attendance/attendance-editor/attendance-editor.dialog";
import { CertificateAttendeeItem } from "./attendance-item";
import { ExportCertAttendees } from "./export-attendance";
import { CertAttendeeCol, useCertAttendeeColumns } from "./use-attendance-columns";



type Props = {
    attendance: AttendanceModel;
}


const MutationAssign = gql`
    mutation upsert_ac($object: attendee_certificate_insert_input!, $certificate_id: bigint!) {
        insert_attendee_certificate_one(object: $object, on_conflict: {
            constraint: attendee_certificate_pkey,
            update_columns: [deleted_at]
        }) {
            id
            attendee {
                ${AttendeeCertificateFragment}
            }
        }
    }
`;


export const AttendanceAttendeeList = ({
    attendance,
}: Props) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const [show_export, setShowExport] = React.useState(false);
    const {
        showColumn,
        columns,
        toggleColumn,
    } = useCertAttendeeColumns();
    const [upsert] = useMutation(MutationAssign);
    const [show_edit, setShowEdit] = React.useState(false);
    const {
        items,
        loading,
        fetchMore,
        has_more,
    } = useKeysetPagination<AttendeeAttendanceModel>({
        entity_name: 'attendee',
        fragment: AttendeeAttendanceFragment,
        clause_outer: `
            $event_id: bigint!, 
            $attendance_id: bigint!
            $cursor: bigint!
        `,
        variables: {
            event_id: attendance.event_id,
            attendance_id: attendance.id,
            cursor: Number.MAX_SAFE_INTEGER,
        },
        query_inner: `
            where: {_and: [
                {ticket: {event_id: {_eq: $event_id}}},
                {id: {_lt: $cursor}},
            ]},
            order_by: {id: desc}
        `
    });

    const toggleAssign = async (item: AttendeeAttendanceModel, assign: boolean) => {
        try {
            await upsert({
                variables: {
                    certificate_id: attendance.id,
                    object: {
                        attendee_id: item.id,
                        certificate_id: attendance.id,
                        deleted_at: assign ? null : 'now()',
                    }
                }
            })
        } catch (e: any) {
            app_dialog.showError(e);
        }
    }

    const loadMore = () => {
        if (items.length === 0) {
            return;
        }
        fetchMore({
            event_id: attendance.event_id,
            certificate_id: attendance.id,
            cursor: items[items.length - 1].id,
        })
    }

    return <>
        {show_export ? <ExportCertAttendees
            attendance_id={attendance.id}
            event_id={attendance.event.id}
            event_title={attendance.event.title}
            onClose={() => setShowExport(false)}
        /> : null}
        {show_edit ? <AttendanceEditorDialog
            action='edit'
            event_id={attendance.event_id}
            item={attendance}
            onFinish={() => setShowEdit(false)}
            onClose={() => setShowEdit(false)}
        /> : null}
        <div className={classes.header}>
            <Button
                size='small'
                color='primary'
                onClick={() => setShowExport(true)}
                variant='contained'
                style={{
                    marginRight: 8,
                }}>
                Export
            </Button>
            <Button
                variant='outlined'
                size='small'
                onClick={() => setShowEdit(true)}>Edit Attendance</Button>

            <div style={{ flex: 1 }} />
            <ColumnSelector<CertAttendeeCol>
                showColumn={showColumn}
                toggleColumn={toggleColumn}
                columns={columns}
            />
        </div>
        <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow className='header'>
                        <TableCell />
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                        {showColumn('email') ? <TableCell>Email</TableCell> : null}
                        <TableCell>Check In</TableCell>
                        <TableCell>Check Out</TableCell>
                        <TableCell>Duration</TableCell>
                        {showColumn('ticket') ? <TableCell>Ticket</TableCell> : null}
                        {showColumn('agd_id') ? <TableCell>AGD Id</TableCell> : null}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => <CertificateAttendeeItem
                        key={item.id}
                        item={item}
                        showColumn={showColumn}
                        toggleAssign={toggleAssign}
                    />)}
                </TableBody>
            </Table>
        </TableContainer>
        {has_more ? <Box py={2}>
            <LoadingButton
                loading={loading}
                onClick={loadMore}
                fullWidth>Load More...</LoadingButton>
        </Box> : null}

    </>
}

const useStyles = makeStyles(theme => createStyles({
    table: {
        width: '100%',
        minWidth: 700,
        background: 'white',
        border: `1px solid ${theme.palette.divider}`,
        '& .header': {
            background: theme.palette.background.paper,
        }
    },
    header: {
        display: 'flex',
        paddingBottom: theme.spacing(2),
    }
}))