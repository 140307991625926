import { Button, createStyles, Link, makeStyles, TableCell, TableRow, Tooltip } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/CheckCircle';
import moment from "moment";
import React from "react";
import { environment } from "../../environment";
import { AttendeeCertificateModel } from "../../models/attendee-certificate.model";
import { CertAttendeeCol } from "./use-cert-attendee-columns";

type Props = {
    item: AttendeeCertificateModel;
    toggleAssign: (item: AttendeeCertificateModel, assign: boolean) => void;
    showColumn: (id: CertAttendeeCol) => boolean;
}

export const CertificateAttendeeItem = ({
    item,
    toggleAssign,
    showColumn,
}: Props) => {
    const classes = useStyles({});
    const attendee_certificate = React.useMemo(() => item
        .attendee_certificates && item.attendee_certificates.length > 0
        && !item.attendee_certificates[0].deleted_at ?
        item.attendee_certificates[0] : undefined, [item.attendee_certificates]);

    return <TableRow
        hover
    >
        <TableCell>
            <div className={classes.buttons}>
                <Tooltip title='Assign certificate'>
                    <Button
                        variant={!!attendee_certificate ? 'contained' : 'text'}
                        color={!!attendee_certificate ? 'secondary' : 'inherit'}
                        startIcon={<CheckIcon />}
                        size='small' onClick={(e) => toggleAssign(
                            item, !!attendee_certificate ? false : true)}>
                        {!!attendee_certificate ? 'Remove' : 'Assign'}
                    </Button>
                </Tooltip>
            </div>
        </TableCell>
        <TableCell style={{ width: 50 }}>
            {item.id}
        </TableCell>
        <TableCell style={{
            minWidth: 150,
        }}>{item.name}</TableCell>
        {showColumn('email') ? <TableCell style={{
            minWidth: 200,
        }}>{item.email}</TableCell> : null}
        <TableCell style={{
            minWidth: 200,
        }}>{attendee_certificate ? moment(attendee_certificate.updated_at).calendar() : null}</TableCell>
        <TableCell>
            {attendee_certificate ? <Link
                target='_blank'
                href={`${environment.frontend_url}/certificates/${attendee_certificate.secret}`}>Viewer Link</Link>
                : null}
        </TableCell>
        <TableCell>{attendee_certificate?.pace_submission_id}</TableCell>
        {showColumn('ticket') ? <TableCell>{item.ticket.name}</TableCell> : null}
        {showColumn('agd_id') ? <TableCell>{item.agd_id}</TableCell> : null}
    </TableRow>
}

const useStyles = makeStyles(theme => createStyles({
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}))