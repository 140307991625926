import { Button, createStyles, makeStyles } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/CameraAlt';
import clsx from 'clsx';
import { FieldProps } from 'formik';
import React from 'react';
import { Image, ImageSize, ImageUpload, LazyImage } from '../image-upload';

interface Props extends FieldProps {
	prompt?: string;
	className?: string;
}

export const ProfileImageEditorComponent = ({
	field: {
		name,
		value,
	},
	form: {
		setFieldValue,
		handleSubmit,
	},
	prompt,
	className,
}: Props) => {
	const [image_upload, setImageUpload] = React.useState(false);
	const classes = useStyles({});


	const onChangeImage = (images: Image[]) => {
		if (images.length === 0) {
			return;
		}
		setFieldValue(name, images[0].key);
		handleSubmit();
		setImageUpload(false);
	}

	return <div className={classes.root}>
		{image_upload ? <ImageUpload
			onSelect={onChangeImage}
			handleClose={() => setImageUpload(false)}
		/> : null}
		<LazyImage
			image_key={value}
			placeholder={'/images/avatar.jpg'}
			image_opt={{ size: ImageSize.medium }}
			className={clsx(classes.avatar, className)}
			onClick={() => setImageUpload(true)}
		/>
		<div>
			<Button
				startIcon={<CameraIcon />}
				onClick={() => setImageUpload(true)}
			>{prompt || 'Change picture'}</Button>
		</div>
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		width: 150,
		height: 150,
		display: 'block',
		borderRadius: theme.shape.borderRadius,
		cursor: 'pointer',
		border: `5px solid ${theme.palette.divider}`,
		marginBottom: theme.spacing(1),
		boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
		transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
		'&:hover': {
			boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
		}
	}
}));