import { Box, Button, createStyles, makeStyles, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import { useKeysetPagination } from "../../apollo/networking/use-keyset-pagination";
import { EventModel } from "../../models/event.model";
import { InvoiceItemFragment, InvoiceItemModel } from "../../models/invoice-item.model";
import { ColumnSelector } from "../../ui/column-selector";
import { LoadingButton } from "../../ui/loading-button";
import { InvoiceDetailsDialog } from "../sales/invoice-details";
import { ExportEventSales } from "./export-event-sales";
import { InvoiceItem } from "./invoice-item";
import InvoiceItemMoveDialog from "./invoice-item-move";
import { InvoiceItemMoveTicketModel } from "./invoice-item-move/invoice-item-move.model";
import { useInvoiceItemMove } from "./invoice-item-move/use-invoice-item-move";
import { InvoiceItemCol, useInvoiceItemCols } from "./use-invoice-item-cols";


type Props = {
    event: EventModel;
}

export const InvoiceItemList = ({
    event,
}: Props) => {
    const classes = useStyles({})
    const [show_move_dialog, setShowMoveDialog] = React.useState<InvoiceItemModel | undefined>();
    const [show_export, setShowExport] = React.useState(false);
    const { moveinvoiceItem } = useInvoiceItemMove()
    const {
        columns,
        showColumn,
        toggleColumn,
    } = useInvoiceItemCols();
    const [anchor_el, setAnchorEl] = React.useState<{
        anchor: HTMLButtonElement;
        item: InvoiceItemModel;
    } | undefined>();
    const [editor, setEditor] = React.useState<InvoiceItemModel | undefined>();
    const {
        items,
        loading,
        refetch,
        fetchMore,
        has_more
    } = useKeysetPagination<InvoiceItemModel>({
        entity_name: 'invoice_item',
        fragment: InvoiceItemFragment,
        clause_outer: `$event_id: bigint!, $cursor: bigint!`,
        variables: {
            event_id: event.id,
            cursor: Number.MAX_SAFE_INTEGER,
        },
        query_inner: `
            where: {_and: [
                {id: {_lt: $cursor}}
                {sold_at: {_is_null: false}}
                {ticket: {event_id: {_eq: $event_id}}},
            ]},
            order_by: {id: desc}
        `
    });

    const loadMore = () => {
        if (items.length === 0) {
            return;
        }
        fetchMore({
            event_id: event.id,
            cursor: items[items.length - 1].id,
        })
    }

    const viewInvoice = () => {
        if (!anchor_el?.item) {
            return;
        }
        setEditor(anchor_el?.item as InvoiceItemModel)
        setAnchorEl(undefined);
    }

    const onMove = () => {
        if (!anchor_el?.item) {
            return;
        }
        setShowMoveDialog(anchor_el?.item as InvoiceItemModel);
        setAnchorEl(undefined);
    }

    const onMoveConfirm = async ({
        ticket,
    }: {
        ticket: InvoiceItemMoveTicketModel;
    }) => {
        const invoice_item = show_move_dialog;
        if (!invoice_item) {
            return;
        }
        setShowMoveDialog(undefined);
        await moveinvoiceItem(invoice_item, ticket.id);
        refetch();
    }

    return <>
        <Menu
            open={Boolean(anchor_el)}
            anchorEl={anchor_el?.anchor}
            onClose={() => setAnchorEl(undefined)}
        >
            <MenuItem onClick={viewInvoice}>View Invoice</MenuItem>
            <MenuItem onClick={onMove}>Change Event/Ticket</MenuItem>
        </Menu>
        {show_move_dialog ? <InvoiceItemMoveDialog
            onSelectTicket={onMoveConfirm}
            onClose={() => setShowMoveDialog(undefined)}
        /> : null}
        {show_export ? <ExportEventSales
            event_id={event.id}
            event_title={event.title}
            onClose={() => setShowExport(false)}
        /> : null}
        {editor ? <InvoiceDetailsDialog
            invoice_id={editor.invoice_id}
            onClose={() => setEditor(undefined)} /> : null}

        <div className={classes.header}>
            <Button size='small'
                color='primary'
                onClick={() => setShowExport(true)}
                variant='contained'
                style={{
                    marginRight: 8,
                }}>
                Export
            </Button>
            <div style={{ flex: 1 }} />
            <ColumnSelector<InvoiceItemCol>
                showColumn={showColumn}
                toggleColumn={toggleColumn}
                columns={columns}
            />
        </div>
        <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow className='header'>
                        <TableCell />
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                        {showColumn('email') ? <TableCell>Email</TableCell> : null}
                        {showColumn('referrer') ? <TableCell>Referred By</TableCell> : null}
                        {showColumn('agd_id') ? <TableCell>AGD ID</TableCell> : null}
                        <TableCell>Quantity</TableCell>
                        {showColumn('price') ? <TableCell>Price</TableCell> : null}
                        {showColumn('subtotal') ? <TableCell>Subtotal</TableCell> : null}
                        {showColumn('discount') ? <TableCell>Discount</TableCell> : null}
                        <TableCell>Amount</TableCell>
                        <TableCell>Refund</TableCell>
                        {showColumn('sold_at') ? <TableCell>Sold At</TableCell> : null}
                        {showColumn('ticket') ? <TableCell>Ticket</TableCell> : null}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => <InvoiceItem
                        key={item.id}
                        item={item}
                        showColumn={showColumn}
                        onMore={(e, item) => setAnchorEl({
                            item,
                            anchor: e.currentTarget,
                        })} />)}
                </TableBody>
            </Table>
        </TableContainer>
        {has_more ? <Box py={2}>
            <LoadingButton
                loading={loading}
                onClick={loadMore}
                fullWidth>Load More...</LoadingButton>
        </Box> : null}

    </>
}

const useStyles = makeStyles(theme => createStyles({
    table: {
        width: '100%',
        minWidth: 700,
        background: 'white',
        border: `1px solid ${theme.palette.divider}`,
        '& .header': {
            background: theme.palette.background.paper,
        }
    },
    header: {
        display: 'flex',
        paddingBottom: theme.spacing(2),
    }
}))