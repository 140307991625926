import * as yup from 'yup';
import { AttendeeModel } from '../../../models/attendee.model';

export const AttendeeInitialValues = (attendee?: AttendeeModel) => ({
    name: attendee?.name || '',
    email: attendee?.email || '',
    ticket_id: attendee?.ticket_id || '',
    agd_id: attendee?.agd_id || '',
    is_email_enabled: attendee ? attendee.is_email_enabled : true,
})

export const AttendeeValidationSchema = yup.object().shape({
    name: yup.string().required('Please provide a ticket name'),
    email: yup.string().email('Email is invalid').required('Email is required'),
    agd_id: yup.string(),
})