import React from "react"
import { RouteComponentProps } from "@reach/router"
import { AppLayout } from "../../layout/app-layout"
import { Typography } from "@material-ui/core"

type Props = {

}

export const BillingPage = (props: RouteComponentProps<Props>) => {
    return <AppLayout>
        <Typography gutterBottom variant='h3'>Billing coming soon</Typography>
        <Typography variant='h6'>It will be $25/month and a year minimum to start. For now you can use CertifySimple for free.</Typography>
    </AppLayout>
}
