import { Currency } from "./currency";
import { OrganizationFragment, OrganizationModel } from "./organization.model";
import { StripePaymentIntentStatus } from "./stripe-payment-intent-status.enum";

export type InvoiceModel = {
    id: number;
    created_at: string;
    name: string;
    secret: string;
    agd_id?: string;
    email: string;
    payment_intent_id: string;
    organization: OrganizationModel;
    currency: Currency;
    subtotal: number;
    tax_total: number;
    tax_info?: {
        name: string;
        description?: string;
        tax_percent: number;
    }[];
    discount_total: number;
    discounts?: {
        type: string;
    }[];
    status: StripePaymentIntentStatus;
    status_updated_at?: string;
    amount: number;
    invoice_items: {
        id: number;
        quantity: number;
        schema: any;
        price: number;
        ticket: {
            id: number;
            name: string;
            price: number;
            event: {
                id: number;
                title: string;
            }
        }
    }[];
    refund_tally?: {
        total_amount?: number;
    }
}

export const InvoiceFragment = `
    id
    created_at
    name
    email
    agd_id
    secret
    payment_intent_id
    organization {
        ${OrganizationFragment}
    }
    currency
    subtotal
    tax_total
    tax_info
    discount_total
    discounts
    amount
    status
    status_updated_at
    invoice_items {
        id
        quantity
        schema
        price
        ticket {
            id
            name
            price
            event {
                id
                title
            }
        }
    }
    refund_tally {
        total_amount
    }
`;
