import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useGetByPk } from "../../apollo/networking/use-get-by-pk"
import { FeatureFlag } from "../../auth/feature-flags"
import { useOrg } from "../../auth/use-org"
import { AppLayout } from "../../layout/app-layout"
import { AttendanceFragment, AttendanceModel } from "../../models/attendance.model"
import { ErrorPage } from "../../ui/errors"
import { LoadingDots } from "../../ui/loading-dots"
import { PageHeader } from "../../ui/page-header"
import { AttendanceAttendeeList } from "./attendance-attendee-list"
import HostingWidgets from "./HostingWidgets"

type Props = {
    attendance_id: number;
}

export const AttendancePage = ({
    attendance_id,
}: RouteComponentProps<Props>) => {
    const {
        entity: attendance,
        loading,
    } = useGetByPk<AttendanceModel>({
        entity_name: 'attendance',
        fragment: AttendanceFragment,
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: attendance_id,
        }]
    });
    const is_attendance_active = useOrg().getFeatureFlag(FeatureFlag.attendance);

    if (!is_attendance_active) {
        return <ErrorPage
            title='Feature is not active!'
            message='Check in/Check out feature is not active on your account. Please contact us at help@certifysimple.com to activate this feature.'
        />
    }

    return <AppLayout>
        {loading ? <LoadingDots /> : null}
        {attendance ? <>
            <PageHeader
                title={attendance.name}
            />
            <HostingWidgets
                event_id={attendance.event_id}
                attendance_id={attendance.id}
            />

            <AttendanceAttendeeList
                attendance={attendance}
            />
        </> : null}
    </AppLayout>
}
