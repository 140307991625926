export type WebinarPostAttendeeModel = {
    id: number;
    created_at: string;
    name: string;
    email: string;
    invoice_item_id?: number;
    ticket_id: number;
    is_webinar_messaging_blocked?: boolean;
    message: string;
    is_public?: boolean;
    replied_at?: string; 
}

export const WebinarPostAttendeeFragment = `
    id
    created_at
    name
    email
    invoice_item_id
    ticket_id
    is_webinar_messaging_blocked
    message
    is_public
    replied_at
`;
