import { createStyles, makeStyles } from "@material-ui/core";
import draftToHtml from 'draftjs-to-html';
import React from 'react';
import parse from 'react-html-parser';
// import 'draft-js/dist/Draft.css';

type Props = {
    content?: any;
}

export const EventBody = ({
    content,
}: Props) => {
    const classes = useStyles({});
    const html = React.useMemo(() =>
        parse(draftToHtml(content)), [content]);

    return <div className={classes.editor}>
        {html}
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    editor: {
        position: 'relative',
    }
}))