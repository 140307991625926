import { AttendeeModel, AttendeeFragment } from "./attendee.model";

export interface AttendeeCertificateModel extends AttendeeModel {
    attendee_certificates: {
        id: number;
        updated_at: string;
        deleted_at?: string;
        secret: string;
        pace_submission_id: string;
    }[];
}

export const AttendeeCertificateFragment = `
    ${AttendeeFragment}
    attendee_certificates(where: {
        certificate_id: {_eq: $certificate_id}
    }) {
        id
        updated_at
        deleted_at
        secret
        pace_submission_id
    }
`;