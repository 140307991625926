import { DialogContent } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { useUpdateByPk } from "../../../apollo/networking/use-update-by-pk";
import { useAppDialog } from "../../../app-dialog";
import { TicketFragment, TicketModel } from "../../../models/ticket.model";
import { BasicDialogActions } from "../../../ui/dialog";
import { LoadingButton } from "../../../ui/loading-button";
import { TicketForm } from "./ticket.form";
import { getTicketSubmission, TicketInitialValues, TicketValidationSchema } from "./ticket.validation";


type Props = {
    item: TicketModel;
    onFinish: (product: TicketModel) => void;
}

export const TicketEdit = ({
    onFinish,
    item,
}: Props) => {
    const {
        onUpdate,
        loading,
    } = useUpdateByPk({
        entity_name: 'ticket',
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: item.id,
        }],
        fragment: TicketFragment,
    });

    const app_dialog = useAppDialog();

    const onSubmit = async (values: any) => {
        try {
            const result = await onUpdate(
                getTicketSubmission(values)
            ) as TicketModel;
            if (!!result) {
                onFinish(result);
            }
        } catch (e: any) {
            app_dialog.showError(e);
        }
    }
    return <Formik initialValues={TicketInitialValues(item)}
        validationSchema={TicketValidationSchema}
        onSubmit={onSubmit}>{(form_props) => <Form noValidate>
            <DialogContent>
                <TicketForm {...form_props} />
            </DialogContent>
            <BasicDialogActions>
                <LoadingButton loading={loading}
                    color='primary'
                    size='large'
                    type='submit'
                    variant='contained'>
                    Save
                </LoadingButton>
            </BasicDialogActions>
        </Form>}
    </Formik>
}
