import { RouteComponentProps } from "@reach/router"
import React from "react"
import { AppLayout } from "../../layout/app-layout"
import { PageHeader } from "../../ui/page-header"
import { DiplomaList } from "./diploma-list"

type Props = {
    event_id: number;
}

export const EventDiplomasPage = ({
    event_id,
}: RouteComponentProps<Props>) => {

    return <AppLayout>
        <PageHeader title='Diplomas' />
        <DiplomaList event_id={event_id as number} />
    </AppLayout>
}
