import DateFnsUtils from '@date-io/moment';
import { Box, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Field, FormikProps } from "formik";
import { Switch, TextField } from "formik-material-ui";
import { DateTimePicker } from 'formik-material-ui-pickers';
import React from "react";
import { TicketDiscountField } from './ticket-discount';

interface Props extends FormikProps<any> {

}

export const TicketForm = ({
    values,
}: Props) => {
    const classes = useStyles({});
    return <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Field
            name='name'
            component={TextField}
            label='Name'
            margin='normal'
            fullWidth
        />
        <Field
            name='description'
            component={TextField}
            label='Description (Optional)'
            margin='normal'
            fullWidth
            multiline
            helperText='Short 1-2 sentences'
        />
        <Field
            name='price'
            type='number'
            component={TextField}
            label='Price (set to $0 for free)'
            margin='normal'
            fullWidth
            style={{
                maxWidth: 220,
            }}
        />
        <Box display='flex'
            pt={1}
            alignItems='center'
            justifyContent='space-between'>
            <Typography variant='body1'>Limit total number of tickets?</Typography>
            <Field
                name='is_inventory'
                component={Switch}
                type='checkbox'
            />
        </Box>
        {values.is_inventory ? <Field
            name='total_inventory'
            type='number'
            component={TextField}
            label='Total number of tickets'
            margin='normal'
            fullWidth
            style={{
                maxWidth: 220,
            }}
        /> : null}
        <Field
            name='sale_ends_at'
            component={DateTimePicker}
            fullWidth
            margin='normal'
            helperText='Leave this blank and sale will end when event ends'
            label='Sale ends at (Optional)'
        />
        <Box display='flex'
            pt={1}
            alignItems='center'
            justifyContent='space-between'>
            <Typography variant='body1'>Ask for AGD ID?</Typography>
            <Field
                name='ask_agd_id'
                component={Switch}
                type='checkbox'
            />
        </Box>
        {values.ask_agd_id ? <Box display='flex'
            pt={1}
            alignItems='center'
            justifyContent='space-between'>
            <Typography variant='body1'>Is AGD ID required?</Typography>
            <Field
                name='is_agd_id_required'
                component={Switch}
                type='checkbox'
            />
        </Box> : null}
        <Box display='flex'
            pt={1}
            alignItems='center'
            justifyContent='space-between'>
            <Typography variant='body1'>Offer an AGD Discount?</Typography>
            <Field
                name='offer_agd_discount'
                component={Switch}
                type='checkbox'
            />
        </Box>
        {values.offer_agd_discount ?
            <Field
                name='agd_discount_price'
                component={TextField}
                label='AGD Price ($0 = free)'
                margin='normal'
                fullWidth
                helperText='New price for AGD members'
                style={{
                    maxWidth: 300,
                }}
            /> : null}
        <Box display='flex'
            pt={1}
            alignItems='center'
            justifyContent='space-between'>
            <Typography variant='body1'>Offer a Referral Discount?</Typography>
            <Field
                name='offer_referral_discount'
                component={Switch}
                type='checkbox'
            />
        </Box>
        {values.offer_referral_discount ?
            <Field
                name='referral_discount_price'
                component={TextField}
                label='Referral Discount Price ($0 = free)'
                margin='normal'
                fullWidth
                helperText='New price for a referred guest'
                style={{
                    maxWidth: 300,
                }}
            /> : null}

        <Field
            component={TicketDiscountField}
            name='discounts'
        />
        <Box display='flex'
            pt={1}
            alignItems='center'
            justifyContent='space-between'>
            <Box flex={1}>
                <Typography variant='body1'>Ticket is visible</Typography>
                <Typography variant='body2' color='textSecondary'>Turn this off for tickets you want to keep secret</Typography>
            </Box>
            <Field
                name='is_visible'
                component={Switch}
                type='checkbox'
            />
        </Box>
    </MuiPickersUtilsProvider>
}

const useStyles = makeStyles(theme => createStyles({

}))