import React from "react";
import { TicketModel } from "../../../models/ticket.model";
import { BasicDialog, BasicDialogTitle } from "../../../ui/dialog";
import { TicketCreate } from "./ticket-create";
import { TicketEdit } from "./ticket-edit";


type Props = {
    event_id: number;
    action: 'edit' | 'create';
    item?: TicketModel;
    onClose: () => void;
    onFinish: (event: TicketModel, is_new?: boolean) => void;
}

export const TicketEditorDialog = ({
    event_id,
    action,
    onClose,
    onFinish,
    item,
}: Props) => {
    return <BasicDialog
        open={true}
        id='update-event'
        onClose={onClose}>
        <BasicDialogTitle onClose={onClose} title={action === 'create' ? 'Create ticket' : 'Edit ticket'} />
        {item
            ? <TicketEdit
                onFinish={onFinish}
                item={item} />
            : <TicketCreate
                event_id={event_id}
                onFinish={onFinish}
            />}
    </BasicDialog>
}
