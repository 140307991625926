import React from "react";
import { EventResourceModel } from "../../../models/event-resource.model";
import { BasicDialog, BasicDialogTitle } from "../../../ui/dialog";
import { ResourceCreate } from "./resource-create";
import { ResourceEdit } from "./resource-edit";


type Props = {
    event_id: number;
    action: 'edit' | 'create';
    item?: EventResourceModel;
    onClose: () => void;
    onFinish: (event: EventResourceModel, is_new?: boolean) => void;
}

export const ResourceEditorDialog = ({
    event_id,
    action,
    onClose,
    onFinish,
    item,
}: Props) => {
    return <BasicDialog
        open={true}
        id='update-event'
        onClose={onClose}>
        <BasicDialogTitle onClose={onClose} title={action === 'create' ? 'Create resource' : 'Edit resource'} />
        {item
            ? <ResourceEdit
                onFinish={onFinish}
                item={item} />
            : <ResourceCreate
                event_id={event_id}
                onFinish={onFinish}
            />}
    </BasicDialog>
}
