
export enum AttendanceState {
    off=0,
    checkin=1,
    checkout=2,
}

export const AttendanceStateOptions = [{
    id: AttendanceState.off,
    label: 'Locked',
    action: 'Is Locked',
},{
    id: AttendanceState.checkin,
    label: 'Check In',
    action: 'Checking In',
},{
    id: AttendanceState.checkout,
    label: 'Check Out',
    action: 'Checking Out',
},]

export type AttendanceModel = { 
    id: number;
    name: string;
    event_id: number;
    deleted_at?: string;
    state: AttendanceState;
    youtube_url?: string;
    event: {
        id: number;
        title: string;
    };
    checkin_tally?: {
        total_checkins?: number;
    }
    checkout_tally?: {
        total_checkouts?: number;
    }
}

export const AttendanceFragment = `
    id
    name
    event_id
    deleted_at
    state
    youtube_url
    event {
        id
        title
    }
    checkin_tally {
        id
        total_checkins
    }
    checkout_tally {
        id
        total_checkouts
    }
`;