import React from "react";
import { AttendanceModel } from "../../../models/attendance.model";
import { TicketModel } from "../../../models/ticket.model";
import { BasicDialog, BasicDialogTitle } from "../../../ui/dialog";
import { AttendanceCreate } from "./attendance-create";
import { AttendanceEdit } from "./attendance-edit";


type Props = {
    event_id: number;
    action: 'edit' | 'create';
    item?: AttendanceModel;
    onClose: () => void;
    onFinish: (event: AttendanceModel, is_new?: boolean) => void;
}

export const AttendanceEditorDialog = ({
    event_id,
    action,
    onClose,
    onFinish,
    item,
}: Props) => {
    return <BasicDialog
        open={true}
        id='update-attendance'
        onClose={onClose}>
        <BasicDialogTitle onClose={onClose} title={action === 'create' ? 'Create attendance' : 'Edit attendance'} />
        {item
            ? <AttendanceEdit
                onFinish={onFinish}
                item={item} />
            : <AttendanceCreate
                event_id={event_id}
                onFinish={onFinish}
            />}
    </BasicDialog>
}
