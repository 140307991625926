import React from "react";
import { DiplomaModel } from "../../../models/diploma.model";
import { BasicDialog, BasicDialogTitle } from "../../../ui/dialog";
import { DiplomaCreate as DiplomaCreate } from "./diploma-create";
import { DiplomaEdit as DiplomaEdit } from "./diploma-edit";


type Props = {
    event_id: number;
    action: 'edit' | 'create';
    item?: DiplomaModel;
    onClose: () => void;
    onFinish: (event: DiplomaModel, is_new?: boolean) => void;
}

export const DiplomaEditorDialog = ({
    event_id,
    action,
    onClose,
    onFinish,
    item,
}: Props) => {
    return <BasicDialog
        open={true}
        id='update-event'
        onClose={onClose}>
        <BasicDialogTitle onClose={onClose} title={action === 'create' ? 'Create certificate' : 'Edit certificate'} />
        {item
            ? <DiplomaEdit
                onFinish={onFinish}
                item={item} />
            : <DiplomaCreate
                event_id={event_id}
                onFinish={onFinish}
            />}
    </BasicDialog>
}
