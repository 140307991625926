import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useGetByPk } from "../../apollo/networking/use-get-by-pk"
import { AppLayout } from "../../layout/app-layout"
import { CertificateFragment, CertificateModel } from "../../models/certificate.model"
import { LoadingDots } from "../../ui/loading-dots"
import { PageHeader } from "../../ui/page-header"
import { CertificateAttendeeList } from "./certificate-attendee-list"

type Props = {
    certificate_id: number;
}

export const CertificatePage = ({
    certificate_id,
}: RouteComponentProps<Props>) => {
    const {
        entity: cert,
        loading,
    } = useGetByPk<CertificateModel>({
        entity_name: 'certificate',
        fragment: CertificateFragment,
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: certificate_id,
        }]
    });

    return <AppLayout>
        {loading ? <LoadingDots /> : null}
        {cert ? <>
            <PageHeader
                title={cert.title}
            />
            <CertificateAttendeeList
                certificate={cert}
            />
        </> : null}
    </AppLayout>
}
