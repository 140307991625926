import { AttendeeModel, AttendeeFragment } from "./attendee.model";

export interface AttendeeAttendanceModel extends AttendeeModel {
    dropins: {
        id: number;
        checkin_at?: string;
        checkout_at?: string;
    }[];
}

export const AttendeeAttendanceFragment = `
    ${AttendeeFragment}
    dropins(where: {
        attendance_id: {_eq: $attendance_id}
    }) {
        id
        checkin_at
        checkout_at
    }
`;