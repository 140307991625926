import { useMutation, gql } from "@apollo/client";
import React from "react";
import { useAppDialog } from "../../app-dialog";

const MUTATION_UPLOAD_IMAGES = gql`
	mutation upload_image($object: upload_image_input!) {
		upload_image(object: $object) {
			upload_urls
		}
	}
`;

export type MediaUploadStatus = {
	key: string;
	upload_url: string;
	progress: number;
	complete?: boolean;
	failed?: boolean;
	preview: File;
	order: number;
}

type Props = {
	skip_save?: boolean;
}

export const useImageUploader = ({
	skip_save,
}: Props) => {
	const app_dialog = useAppDialog();
	const [images, setUploadStatus] = React.useState<{ [id: string]: MediaUploadStatus }>({});
	const [_getUploadUrls] = useMutation(MUTATION_UPLOAD_IMAGES);

	const getUploadUrls = async (images: File[]) => {
		try {
			const { data } = await _getUploadUrls({
				variables: {
					object: {
						images: images.map(img => ({
							filename: img.name,
							type: img.type,
							size: img.size,
						})),
						skip_save,
					}
				}
			});
			return (data?.upload_image?.upload_urls || []) as {
				key: string;
				upload_url: string;
			}[];
		} catch (e: any) {
			app_dialog.showError(e);
			return [];
		}
	}

	const buildPreview = (urls: { key: string; upload_url: string; }[], images: File[]) => {
		const image_upload_map = urls.reduce((obj, { key, upload_url }, idx) => ({
			...obj,
			[key]: {
				key,
				upload_url,
				preview: images[idx],
				progress: 0,
				complete: false,
				order: idx,
			},
		}), {});
		setUploadStatus(
			image_upload_map
		);
		return image_upload_map;
	}

	const doUpload = async (items: { [id: string]: MediaUploadStatus }) => {
		Object.keys(items).forEach((key) => {
			const {
				upload_url,
				preview,
			} = items[key];
			const uploadProgress = (e: ProgressEvent<XMLHttpRequestEventTarget>) => {
				if (e.lengthComputable) {
					const percentComplete = (e.loaded / e.total);
					setUploadStatus(state => ({
						...state,
						[key]: {
							...state[key],
							progress: percentComplete,
						},
					}));
				}
			}

			const uploadComplete = (e: ProgressEvent<XMLHttpRequestEventTarget>) => {
				setUploadStatus(state => ({
					...state,
					[key]: {
						...state[key],
						progress: 1,
						complete: true,
					},
				}));
			}

			const uploadFailed = () => {
				setUploadStatus(state => ({
					...state,
					[key]: {
						...state[key],
						progress: 1,
						failed: true,
					},
				}));
			}

			const xhr = new XMLHttpRequest();
			xhr.upload.addEventListener("progress", uploadProgress, false);
			xhr.addEventListener("load", uploadComplete, false);
			xhr.addEventListener("error", uploadFailed, false);
			xhr.open('PUT', upload_url, true);
			xhr.setRequestHeader('Content-Type', preview.type);
			xhr.send(preview)
		})
	}

	const uploadImages = async (images: File[]) => {
		if (images.length === 0) {
			return;
		}
		const urls = await getUploadUrls(images);
		if (urls.length !== images.length) {
			app_dialog.showDialog({ title: 'Upload failed', buttons: [] });
			return;
		}
		const upload_status = buildPreview(urls, images);
		doUpload(upload_status);
	}

	return {
		uploadImages,
		images,
		reset: () => setUploadStatus({}),
	}
}