import { Currency } from "./currency";

export type ReferrerModel = {
    id: number;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
    name: string;
    email: string;
    code: string;
    summary?: {
        total_referrals?: number;
        total_referral_amount?: number;
        total_refund_amount?: number;
    }
}

export const ReferrerFragment = `
    id
    created_at
    updated_at
    deleted_at
    name
    email
    code
    summary {
        id
        total_referrals
        total_referral_amount
        total_refund_amount
    }
`;