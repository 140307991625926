import * as yup from 'yup';
import { EventModel } from '../../../models/event.model';

export const EventInitialValues = (event?: EventModel) => ({
    title: event?.title || '',
    starts_at: event?.starts_at || null,
    ends_at: event?.ends_at || null,
    timezone: event?.timezone || '',
    is_live: event?.is_live || false,
    url: event?.url || '',
})

export const EventValidationSchema = yup.object().shape({
    title: yup.string().required('Please provide an event title'),
    starts_at: yup.date().typeError('Please select a date time'),
    ends_at: yup.date()
        .typeError('Please select a date time')
        .min(yup.ref('starts_at'), 'Must be after start date and time'),
})

export const validateEventValues = (object: any) => ({
    ...object,
    url: cleanUrl(object.url),
})

export const cleanUrl = (url?: string) => {
    if (!url) {
        return '';
    }
    const allowed = 'abcdefghijklmnopqrstuvwxyz1234567890-';
    return url.toLowerCase()
    .replace(/ /g, '-')
    .split('').filter(l => allowed.indexOf(l) > -1).join('');
}