export type DiplomaCert1 = {
    version: string;
    subject: string;
    certificate_of: string;
    success_line: string;
    date: string;
    signature: {
        url: string;
        name: string;
        role: string;
    };
}

export type DiplomaModel = {
    id: number;
    created_at: string;
    updated_at: string;
    event_id: number;
    title: string;
    cert_json: DiplomaCert1;
    event: {
        id: number;
        title: string;
    }
}


export const DiplomaFragment = `
    id
    created_at
    updated_at
    event_id
    cert_json
    title
    event {
        id
        title
    }
`;
