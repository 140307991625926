import * as yup from 'yup';
import { CertificateModel } from '../../../models/certificate.model';

export const CertificateInitialValues = (cert?: CertificateModel) => ({
    title: cert?.title || '',
    units: cert?.units || 0,
    subject_code: cert?.subject_code || '',
    speaker_name: cert?.speaker_name || '',
    location: cert?.location || '',
    course_date: cert?.course_date || null,
    educational_method: cert?.educational_method || '',
})

export const CertificateValidationSchema = yup.object().shape({
    title: yup.string().required('Please provide a cert title (course name)'),
    units: yup.number().min(.1, 'Please make sure the value is bigger than .1').required('Please provide a # of units'),
    subject_code: yup.string().required('Please provide a PACE Subject code'),
    speaker_name: yup.string().required('Please provide at least one speaker name'),
    location: yup.string().required('Please provide a location'),
    course_date: yup.string().required('Please select a course date'),
    educational_method: yup.string().required('Please select the correct educational method'),
})