import * as yup from 'yup';
import { AttendanceModel, AttendanceState } from '../../../models/attendance.model';

export const AttendanceInitialValues = (att?: AttendanceModel) => ({
    name: att?.name || '',
    state: att ? att.state : AttendanceState.checkin,
    youtube_url: att?.youtube_url || '',
})

export const AttendanceValidationSchema = yup.object().shape({
    name: yup.string().required('Please provide a ticket name'),
    state: yup.number().required('Please select a state'),
})

export const getTicketSubmission = (values: any) => ({
    ...values,
})