import { Button, createStyles, Divider, IconButton, makeStyles, Menu, MenuItem, Tooltip, Typography } from "@material-ui/core";
import TicketIcon from '@material-ui/icons/ConfirmationNumber';
import EditIcon from '@material-ui/icons/Edit';
import CheckInIcon from '@material-ui/icons/MeetingRoom';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import AttendeeIcon from '@material-ui/icons/People';
import ShareIcon from "@material-ui/icons/Share";
import CertIcon from '@material-ui/icons/Star';
import { RouteComponentProps, useNavigate } from '@reach/router';
import moment from "moment";
import React from 'react';
import { useGetByPk } from "../../apollo/networking/use-get-by-pk";
import { useUpdateByPk } from "../../apollo/networking/use-update-by-pk";
import { useAppDialog } from "../../app-dialog";
import { useOrg } from "../../auth/use-org";
import { AppLayout } from '../../layout/app-layout';
import { EventFragment, EventModel } from "../../models/event.model";
import { DeleteConfirmDialog } from "../../ui/delete-confirm.dialog";
import { LoadingDots } from "../../ui/loading-dots";
import { PageHeader } from "../../ui/page-header";
import { Section } from "../../ui/section";
import { UnstyledLink } from "../../ui/unstyled-link";
import { getFormattedCurrency } from "../../utils/get-formatted-currency";
import { getTimeRangeTimezone } from "../../utils/time-range";
import { CertificateList } from "../event-certificates/certificate-list";
import { InvoiceItemList } from "../event-sales/invoice-item-list";
import { EventEditorDialog } from "../events/event-editor";
import { EventShare } from "../events/event-share";
import { RegistrationSummary } from "./registration-summary";

type Props = {
    event_id: number;
}

export const EventPage = ({
    event_id,
}: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const navigate = useNavigate();
    const { org: { currency } } = useOrg();
    const [show_share, setShowShare] = React.useState(false);
    const [show_editor, setShowEditor] = React.useState(false);
    const [anchor_el, setAnchorEl] = React.useState<HTMLButtonElement | undefined>();
    const [show_delete, setShowDelete] = React.useState(false);
    const app_dialog = useAppDialog();

    const {
        loading,
        entity: event,
    } = useGetByPk<EventModel>({
        entity_name: 'event',
        fragment: EventFragment,
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: event_id,
        }],
    });
    const { onUpdate, loading: updating } = useUpdateByPk({
        entity_name: 'event',
        fragment: EventFragment,
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: event_id,
        }],
    });
    const onMore = (ev: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(ev.currentTarget)
    }

    const onEdit = () => {
        setAnchorEl(undefined);
        setShowEditor(true);
    }

    const onDelete = () => {
        setShowDelete(true);
        setAnchorEl(undefined);
    }

    const onConfirmDelete = async () => {
        try {
            const result = (await onUpdate({
                deleted_at: 'now()',
            })) as any;
            if (result?.deleted_at) {
                app_dialog.showSnackbar('Event deleted');
                setShowDelete(false);
                navigate('/')
            }
        } catch (e: any) {
            app_dialog.showError(e);
        }
    }

    return <AppLayout>
        {loading ? <LoadingDots /> : null}
        {event ? <>
            {show_share ? <EventShare
                name={event.title}
                url={event.url}
                is_live={event.is_live}
                event_id={event.id}
                onClose={() => setShowShare(false)}
            /> : null}
            {show_delete ? <DeleteConfirmDialog
                onCancel={() => setShowDelete(false)}
                onConfirm={onConfirmDelete}
                message={`Are you sure you want to delete the event: ${event.title}. This is not reversible and if there are attendees assigned to this ticket, they won't be able to access it anymore.`}
                deleting={updating}
                confirm_text='confirm'
            /> : null}
            {show_editor ? <EventEditorDialog
                action='edit'
                item={event}
                no_editor_hint
                onFinish={() => setShowEditor(false)}
                onClose={() => setShowEditor(false)} /> : null}
            <Menu
                open={Boolean(anchor_el)}
                anchorEl={anchor_el}
                onClose={() => setAnchorEl(undefined)}
            >
                <MenuItem onClick={onEdit}>Change title & time</MenuItem>
                <Divider />
                <MenuItem onClick={onDelete}><Typography color='secondary'>Delete</Typography></MenuItem>
            </Menu>
            <PageHeader
                title={event.title}
                back_url={`/`}
                subtitle={
                    <Button endIcon={<EditIcon />}
                        onClick={() => setShowEditor(true)}
                        variant='text'>
                        {getTimeRangeTimezone(event.starts_at, event.ends_at, event.timezone)}
                    </Button>
                } />
            <div className={classes.toolbar}>
                <div style={{ flex: 1 }}>
                    <Typography
                        className='button'
                        variant='body2'
                        onClick={() => setShowEditor(true)}>
                        <strong>{event.is_live ? 'Live' : 'In Draft'}</strong> (Click to Edit)</Typography>
                    <Typography variant='body2'>{`Last updated ${moment(event.updated_at).fromNow()}`}</Typography>
                </div>
                <Tooltip title='Manage Attendees'>
                    <UnstyledLink to={`/events/${event_id}/attendees`}>
                        <IconButton>
                            <AttendeeIcon />
                        </IconButton>
                    </UnstyledLink>
                </Tooltip>
                <Tooltip title='Manage Tickets'>
                    <UnstyledLink to={`/events/${event_id}/tickets`}>
                        <IconButton>
                            <TicketIcon />
                        </IconButton>
                    </UnstyledLink>
                </Tooltip>
                <Tooltip title='Manage Certificates'>
                    <UnstyledLink to={`/events/${event_id}/certificates`}>
                        <IconButton>
                            <CertIcon />
                        </IconButton>
                    </UnstyledLink>
                </Tooltip>
                <Tooltip title='Manage attendance'>
                    <UnstyledLink to={`/events/${event_id}/attendance`}>
                        <IconButton size='small'>
                            <CheckInIcon fontSize='small' />
                        </IconButton>
                    </UnstyledLink>
                </Tooltip>
                <Tooltip title='Share Event'>
                    <IconButton onClick={() => setShowShare(true)}>
                        <ShareIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Edit Full Description'>
                    <UnstyledLink to={`/events/${event_id}/edit`}>
                        <IconButton>
                            <EditIcon />
                        </IconButton>
                    </UnstyledLink>
                </Tooltip>
                <Tooltip title='More'>
                    <IconButton onClick={onMore}>
                        <MoreIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <RegistrationSummary event_id={event.id}
                ticket_order={event.ticket_order}
                recent_attendees={event.attendees}
                attendee_tally={event.attendee_tally?.total_attendees}
            />
            <Section title={
                (event.invoice_item_tally?.total_amount || 0) > 0
                    ? `${getFormattedCurrency(event.invoice_item_tally?.total_amount || 0, currency)}`
                    : 'Sales summary'
            } subtitle={(event.invoice_item_tally?.total_amount || 0) > 0 ? 'Total sold' : undefined}>
                <InvoiceItemList event={event} />
            </Section>
            <Section title='Certificates'>
                <CertificateList event_id={event.id} />
            </Section>
        </> : null}
    </AppLayout>
}


const useStyles = makeStyles(theme => createStyles({
    menu: {
        paddingBottom: theme.spacing(2),
        margin: theme.spacing(0, -.5),
        '& button': {
            margin: theme.spacing(0, .5),
        }
    },
    images: {
        paddingBottom: theme.spacing(2),
        '& .flex': {
            display: 'flex',
            flexWrap: 'none',
            overflowY: 'auto',
            '& .wrapper': {
                position: 'relative',
                '& .item': {
                    width: 200,
                    height: 100,
                },
                '& button': {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                }
            },

        }
    },
    toolbar: {
        padding: theme.spacing(1, 2),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.divider,
        display: 'flex',
        alignItems: 'center',
        '& .button:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        }
    },
}))