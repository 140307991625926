import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useGetByPk } from "../../apollo/networking/use-get-by-pk"
import { AppLayout } from "../../layout/app-layout"
import { EventFragment, EventModel } from "../../models/event.model"
import { LoadingDots } from "../../ui/loading-dots"
import { PageHeader } from "../../ui/page-header"
import { TicketList } from "./ticket-list"

type Props = {
    event_id: number;
}

export const EventTicketsPage = ({
    event_id,
}: RouteComponentProps<Props>) => {
    const { entity, loading } = useGetByPk<EventModel>({
        entity_name: 'event',
        fragment: EventFragment,
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: event_id,
        }]
    })

    return <AppLayout>
        <PageHeader title='Tickets' back_url={`/events/${event_id}`} />
        {loading ? <LoadingDots /> : null}
        {entity ? <TicketList event={entity} /> : null}
    </AppLayout>
}
