import { RouteComponentProps } from "@reach/router"
import React from "react"
import { AppLayout } from "../../layout/app-layout"
import { PageHeader } from "../../ui/page-header"
import { CertificateList } from "./certificate-list"

type Props = {
    event_id: number;
}

export const EventCertificatesPage = ({
    event_id,
}: RouteComponentProps<Props>) => {

    return <AppLayout>
        <PageHeader title='Certificates' />
        <CertificateList event_id={event_id as number} />
    </AppLayout>
}
