import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';

type Props = {
	open?: boolean;
	disableBackdropClick?: boolean;
	disableEscapeKeyDown?: boolean;
	onClose: () => void;
	children: React.ReactNode;
	max_width?: 'sm' | 'md' | 'lg' | 'xl';
	full_screen?: 'never' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
	id: string;
	className?: string;
}

export const BasicDialog = ({
	open,
	disableBackdropClick,
	disableEscapeKeyDown,
	onClose,
	children,
	max_width = 'md',
	full_screen = 'xs',
	id,
	className,
}: Props) => {
	const theme = useTheme();
	const _full_screen = useMediaQuery(
		theme.breakpoints.down(full_screen === 'never' ? 'xs' : full_screen)
	);
	React.useEffect(() => {
		const $crisp = (window as any).$crisp;
		if (!$crisp || !$crisp.is || !$crisp.push) {
			return;
		}
		if ($crisp.is("chat:hidden")) {
			return;
		}
		$crisp.push(['do', 'chat:hide']);

		return () => {
			$crisp.push(['do', 'chat:show']);
		}
	}, [])

	return <Dialog
		id={id}
		open={open || false}
		onClose={onClose}
		disableBackdropClick={disableBackdropClick}
		disableEscapeKeyDown={disableEscapeKeyDown}
		maxWidth={max_width}
		fullWidth={true}
		aria-labelledby={id}
		fullScreen={full_screen === 'never' ? false : _full_screen}
		aria-describedby={`${id}-dialog`}
		className={className}
	>
		{children}
	</Dialog>
}