import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { Router } from "@reach/router";
import React from 'react';
import { ProvideApollo } from './apollo';
import { ProvideAppDialog } from './app-dialog/use-app-dialog';
import { NotFoundPage } from './pages/404';
import AttendancePage from './pages/attendance';
import { BillingPage } from './pages/billing';
import CertificatePage from './pages/certificate';
import DiplomaPage from './pages/diploma';
import EventPage from './pages/event';
import EventCheckinPage from './pages/event-attendance';
import EventAttendeesPage from './pages/event-attendees';
import EventCertificatesPage from './pages/event-certificates';
import EventDiplomasPage from './pages/event-diplomas';
import EventEditPage from './pages/event-edit';
import EventPreviewPage from './pages/event-preview';
import EventResourcesPage from './pages/event-resources';
import EventSalesPage from './pages/event-sales';
import EventTicketsPage from './pages/event-tickets';
import EventsPage from './pages/events';
import OrgEditPage from './pages/org-edit';
import OrgReferrerPage from './pages/org-referrer';
import SalesPage from './pages/sales';
import { SigninPage } from './pages/signin/signin.page';
import StripeConnectPage from './pages/stripe-connect';
import WebinarPage from './pages/webinar';
import { theme } from './theme';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ProvideAppDialog>
        <ProvideApollo>
          <Router>
            <SigninPage path='/signin' />
            <EventsPage path='/' />
            <OrgReferrerPage path='/referrers' />
            <EventsPage path='/events' />
            <EventPage path='/events/:event_id' />
            <EventEditPage path='/events/:event_id/edit' />
            <EventAttendeesPage path='/events/:event_id/attendees' />
            <EventSalesPage path='/events/:event_id/sales' />
            <EventTicketsPage path='/events/:event_id/tickets' />
            <EventCheckinPage path='/events/:event_id/attendance' />
            <EventCertificatesPage path='/events/:event_id/certificates' />
            <EventDiplomasPage path='/events/:event_id/diplomas' />
            <EventResourcesPage path='/events/:event_id/resources' />
            <EventPreviewPage path='/events/:event_id/preview' />
            <CertificatePage path='/events/:event_id/certificates/:certificate_id' />
            <DiplomaPage path='/events/:event_id/diplomas/:diploma_id' />
            <AttendancePage path='/events/:event_id/attendance/:attendance_id' />
            <WebinarPage path='/events/:event_id/webinar/:attendance_id' />
            <SalesPage path='/sales' />
            <OrgEditPage path='/edit' />
            <BillingPage path='/billing' />
            <StripeConnectPage path='/stripe' />
            <NotFoundPage default />
          </Router>
        </ProvideApollo>
      </ProvideAppDialog>
    </ThemeProvider>
  );
}

export default App;
