import DateFnsUtils from '@date-io/moment';
import { createStyles, makeStyles, MenuItem } from "@material-ui/core";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { DatePicker } from 'formik-material-ui-pickers';
import React from "react";

type Props = {

}

export const DiplomaForm = (props: Props) => {
    const classes = useStyles({});
    return <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Field
            name='title'
            component={TextField}
            label='Title'
            margin='normal'
            fullWidth
        />
        <Field
            name='cert_json.subject'
            component={TextField}
            label='Subject'
            margin='normal'
            fullWidth
            type='string'
        />
        <Field
            name='cert_json.certificate_of'
            component={TextField}
            label='Certificate For'
            margin='normal'
            fullWidth
            type='string'
            helperText='e.g. Completion'
        />
        <Field
            name='cert_json.success_line'
            component={TextField}
            multiline
            label='Success line'
            margin='normal'
            fullWidth
            type='string'
            helperText='e.g. has successfully completed this course'
        />
        <Field
            name='cert_json.date'
            component={DatePicker}
            label='Diploma Date'
            margin='normal'
            fullWidth
        />
        <Field
            name='cert_json.signature.name'
            component={TextField}
            label='Signer Name'
            margin='normal'
            fullWidth
            type='string'
            helperText='e.g. Dr. Saj Arora'
        />
        <Field
            name='cert_json.signature.url'
            component={TextField}
            label='Signature Image Url'
            margin='normal'
            fullWidth
            type='string'
            helperText='e.g. https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Barack_Obama_signature.svg/1200px-Barack_Obama_signature.svg.png'
        />
        <Field
            name='cert_json.signature.role'
            component={TextField}
            label='Signer Role'
            margin='normal'
            fullWidth
            type='string'
            helperText='e.g. United States President'
        />

    </MuiPickersUtilsProvider>
}

const useStyles = makeStyles(theme => createStyles({

}))