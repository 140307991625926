import { DialogContent, Link, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { useUpdateByPk } from "../../../apollo/networking/use-update-by-pk";
import { useAppDialog } from "../../../app-dialog";
import { EventFragment, EventModel } from "../../../models/event.model";
import { BasicDialogActions } from "../../../ui/dialog";
import { LoadingButton } from "../../../ui/loading-button";
import { EventForm } from "./event.form";
import { EventInitialValues, EventValidationSchema, validateEventValues } from "./event.validation";


type Props = {
    onFinish: (event: EventModel) => void;
    event: EventModel;
    no_editor_hint?: boolean;
}


export const EventEdit = ({
    onFinish,
    event,
    no_editor_hint,
}: Props) => {
    const {
        onUpdate,
        loading,
    } = useUpdateByPk({
        entity_name: 'event',
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: event.id,
        }],
        fragment: EventFragment,
    });

    const app_dialog = useAppDialog();

    const onSubmit = async (values: any) => {
        try {
            const result = await onUpdate(validateEventValues(values)) as EventModel;
            if (!!result) {
                onFinish(result);
            }
        } catch (e: any) {
            app_dialog.showError(e);
        }
    }

    return <Formik
        initialValues={EventInitialValues(event)}
        validationSchema={EventValidationSchema}
        onSubmit={onSubmit}>{form_props => <Form noValidate>
            <DialogContent>
                <EventForm {...form_props} />
                {no_editor_hint ? null : <Typography variant='body1'>Want to edit the event details, description & feature image? <Link
                    target='_blank' href={`/events/${event.id}`}>Open event editor</Link></Typography>}
            </DialogContent>
            <BasicDialogActions>
                <LoadingButton loading={loading}
                    color='primary'
                    type='submit'
                    variant='contained'>
                    Save Event
                </LoadingButton>
            </BasicDialogActions>

        </Form>}

    </Formik>
}
