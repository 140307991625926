import { Button, createStyles, DialogContent, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { CSVLink } from "react-csv";
import { BasicDialog, BasicDialogTitle } from "../../ui/dialog";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import moment from "moment";
import { gql, useApolloClient } from "@apollo/client";
import { LoadingDots } from "../../ui/loading-dots";
import { InvoiceItemFragment, InvoiceItemModel } from "../../models/invoice-item.model";
import { getFormattedCurrency } from "../../utils/get-formatted-currency";
import { useOrg } from "../../auth/use-org";


type Props = {
    event_id: number;
    event_title: string;
    onClose: () => void;
}


const QueryInvoiceItems = gql`
    query invoice_items($event_id: bigint!, $cursor: bigint!, $limit: Int!) {
        invoice_item(where: {
            _and: [
                {sold_at: {_is_null: false}},
                {ticket: {
                    event_id: {_eq: $event_id}
                }},
                {id: {_gt: $cursor}}
            ]
        }, limit: $limit, order_by: {id: asc}) {
            ${InvoiceItemFragment}
        }
    }
`;

export const ExportEventSales = ({
    event_id,
    event_title,
    onClose,
}: Props) => {
    const classes = useStyles({});
    const [is_ready, setIsReady] = React.useState(false);
    const [csv_data, setCsvData] = React.useState<any[]>([]);
    const [columns, setColumns] = React.useState<string[]>([]);
    const { org } = useOrg();
    const client = useApolloClient();

    const fetchData = async (invoice_items: InvoiceItemModel[], limit: number, cursor = 0): Promise<InvoiceItemModel[]> => {
        try {
            const { data, error } = await client.query({
                query: QueryInvoiceItems,
                variables: {
                    limit,
                    cursor,
                    event_id,
                }
            });
            const new_invoice_items = (data?.invoice_item || []) as InvoiceItemModel[];
            if (new_invoice_items.length < limit) {
                return [
                    ...invoice_items,
                    ...new_invoice_items,
                ];
            }
            return fetchData([
                ...invoice_items,
                ...new_invoice_items,
            ], limit, new_invoice_items[new_invoice_items.length - 1].id);
        } catch (e: any) {
            console.error(e);
            return [];
        }
    }

    React.useEffect(() => {
        const { currency } = org;
        fetchData([], 50).then(data => {
            const columns: string[] = [
                'id',
                'invoice_id',
                'name',
                'email',
                'agd_id',
                'ticket_id',
                'ticket_name',
                `price ${currency}`,
                'quantity',
                `subtotal ${currency}`,
                `discount ${currency}`,
                `amount ${currency}`,
                `refund ${currency}`,
                `stripe_fee ${currency}`,
                'sold_at',
            ];
            const csv_data = data.map(d => ({
                id: d.id,
                name: d.invoice.name,
                email: d.invoice.email,
                agd_id: d.invoice.agd_id,
                ticket_id: d.ticket.id,
                ticket_name: d.ticket.name,
                [`price ${currency}`]: getFormattedCurrency(d.price, currency, 'narrowSymbol'),
                quantity: d.quantity,
                [`subtotal ${currency}`]: getFormattedCurrency(d.subtotal, currency, 'narrowSymbol'),
                [`discount ${currency}`]: getFormattedCurrency(d.discount, currency, 'narrowSymbol'),
                [`amount ${currency}`]: getFormattedCurrency(d.amount, currency, 'narrowSymbol'),
                [`refund ${currency}`]: getFormattedCurrency(d.invoice.refund_tally?.total_amount || 0, currency, 'narrowSymbol'),
                [`stripe_fee ${currency}`]: getFormattedCurrency(d.amount * 0.030 + 30, currency, 'narrowSymbol'),
                invoice_id: d.invoice_id,
                sold_at: moment(d.sold_at).format('LLL'),
            }));
            setColumns(columns);
            setCsvData(csv_data);
            setIsReady(true);
        })
    }, [org.currency]);

    return <BasicDialog
        id='export-invoice-items'
        onClose={onClose}
        open={true}
    >
        <BasicDialogTitle title='Export event sales' onClose={onClose} />
        <DialogContent>
            <div className={classes.loading}>
                {is_ready ? <>
                    <Typography variant='h6' gutterBottom>
                        Your download is ready
                    </Typography>
                    <CSVLink
                        data={csv_data}
                        filename={`${event_title}_sales_${moment().format('LL')}.csv`}
                        headers={columns}
                        className={classes.button}
                        target="_blank">
                        <Button
                            variant='contained'
                            color='secondary'
                            size='large'
                            startIcon={<DownloadIcon />}
                        >Download Report</Button>
                    </CSVLink>
                </> : <>
                        <LoadingDots />
                        <Typography variant='h6' align='center'>
                            Preparing your export...one moment.</Typography>
                    </>}
            </div>
        </DialogContent>
    </BasicDialog>
}

const useStyles = makeStyles(theme => createStyles({
    button: {
        textDecoration: 'none !important',
    },
    loading: {
        textAlign: 'center',
        padding: theme.spacing(0, 0, 4, 0),
    }
}))