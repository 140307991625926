import { SchemaProperty, UiSchemaProperty, VisualNodeModel } from "./visual-node.model";

export enum NodeType {
	text_input = 'text_input',
	date = 'date',
	select_one_radio = 'select_one_radio',
	slider = 'slider',
	check_boxes = 'multiple_choice',
	agree = 'agreement_accept',
	opt_in = 'opt_in',
	dropdown = 'dropdown_select',
	membership = 'membership',
}

export const NodeTypeOptions = [
	{ id: NodeType.text_input, label: 'Text Input' },
	{ id: NodeType.date, label: 'Date Picker' },
	{ id: NodeType.select_one_radio, label: 'Select One (List all options)' },
	{ id: NodeType.dropdown, label: 'Select One (Options in dropdown)' },
	// { id: NodeType.slider, label: 'Range Slider' },
	{ id: NodeType.check_boxes, label: 'Select Multiple: Check Boxes' },
	{ id: NodeType.agree, label: 'Accept/Agreement (Requires checkmark)' },
	{ id: NodeType.opt_in, label: 'Opt In' },
	{ id: NodeType.membership, label: 'Membership' },
];

export const getNodeTypeString = (type: NodeType) => {
	return NodeTypeOptions.find(o => o.id === type)?.label;
}

export const nodeTypeHasEnum = (node_type: NodeType) => {
	switch (node_type) {
		case NodeType.select_one_radio:
		case NodeType.check_boxes:
		case NodeType.dropdown:
			return true;
		default:
			return false;
	}
}

export const nodeTypeFromJSON = (node: VisualNodeModel): NodeType => {
	const { property: { type, enum: _enum }, ui } = node;
	const widget = ui ? ui['ui:widget'] : null;
	if (widget) {
		switch (widget) {
			case 'datepicker':
				return NodeType.date;
			case 'radio':
				return NodeType.select_one_radio;
			case 'range':
				return NodeType.slider;
			case 'checkboxes':
				return NodeType.check_boxes;
			case 'membership':
				return NodeType.membership;
		}
	}
	if (type === 'boolean') {
		return !_enum ? NodeType.opt_in : NodeType.agree;
	}
	if (!!_enum) {
		return NodeType.dropdown;
	}
	return NodeType.text_input;
}

export const nodeTypeToJSON = (type: NodeType,
	property: SchemaProperty,
	additionalProperties?: any
): {
	property: SchemaProperty;
	ui: UiSchemaProperty;
	additionalProperties?: any;
} => {
	const { title, items, enum: _enum } = property;
	switch (type) {
		case NodeType.date:
			return {
				property: {
					title: title || '',
					description: 'Enter a date',
					type: 'string',
				},
				ui: {
					'ui:widget': 'datepicker',
				}
			}
		case NodeType.select_one_radio:
			return {
				property: {
					title: title || '',
					description: 'Select one option',
					type: 'string',
					enum: _enum || items?.enum || [],
				},
				ui: {
					'ui:widget': 'radio',
				}
			}
		case NodeType.dropdown:
			return {
				property: {
					title: title || '',
					description: 'Select one option',
					type: 'string',
					enum: _enum || items?.enum || [],
				},
				ui: {}
			}
		case NodeType.check_boxes:
			return {
				property: {
					title: title || '',
					description: 'Select options',
					type: 'array',
					items: {
						type: 'string',
						enum: _enum || items?.enum || [],
					},
					uniqueItems: true,
				},
				ui: {
					'ui:widget': 'checkboxes'
				},
			}
		case NodeType.agree:
			return {
				property: {
					title: title || '',
					description: 'Accept this statement',
					type: 'boolean',
					default: null,
					enum: [true],
				},
				ui: {},
			}
		case NodeType.opt_in:
			return {
				property: {
					title: title || '',
					description: 'Would you like to Opt-In',
					type: 'boolean',
				},
				ui: {},
			}
		case NodeType.membership:
			return {
				property: {
					title: title || '',
					description: 'Membership (Select Type)',
					type: 'string',
				},
				additionalProperties,
				ui: {
					'ui:widget': 'membership'
				},
			};
		default:
			return {
				property: {
					title: title || '',
					description: 'Please input a text',
					type: 'string',
				},
				ui: {}
			}
	}
}

export const getOptionsForNode = (node_type: NodeType, property: SchemaProperty) => {
	if (node_type === NodeType.check_boxes) {
		return property.items?.enum as string[] || [];
	}
	return property.enum as string[] || [];
}


// export const getAnswersForNode = (node_type: NodeType, property: SchemaProperty) => {
// 	if (node_type === NodeType.check_boxes) {
// 		return property.items?.enum as string[] || [];
// 	}
// 	return property.enum as string[] || [];
// }


export const updateOptionsForNode = (
	node_type: NodeType,
	property: SchemaProperty,
	_enum: string[]
): SchemaProperty => {
	if (node_type === NodeType.check_boxes) {
		return {
			...property,
			items: {
				...property.items || { type: 'string' },
				enum: _enum,
			},
		};
	}
	return {
		...property,
		enum: _enum,
	}
}