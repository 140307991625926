import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useKeysetPagination } from "../../apollo/networking/use-keyset-pagination";
import { useSubscribeList } from "../../apollo/networking/use-subscribe-list";
import { WebinarPostAttendeeFragment, WebinarPostAttendeeModel } from "../../models/webinar-post.model";
import { LoadingButton } from "../../ui/loading-button";
import { usePostQuestion } from "./use-post-question";


type Props = {
    attendance_id: number;
}

export const WebinarQuestions = ({
    attendance_id,
}: Props) => {
    const classes = useStyles({});
    const {
        postQuestion,
    } = usePostQuestion(attendance_id);
    const {
        items,
        loading,
        has_more,
        fetchMore,
        refetch,
    } = useKeysetPagination<WebinarPostAttendeeModel>({
        entity_name: 'webinar_post_attendee',
        fragment: WebinarPostAttendeeFragment,
        query_inner: `where: {
            _and: [
                {attendance_id: {_eq: $attendance_id}},
                {id: {_lt: $cursor}},
            ],
        }, order_by: {id: desc}`,
        clause_outer: `$attendance_id: bigint!, $cursor: bigint!`,
        limit: 20,
        variables: {
            attendance_id,
            cursor: Number.MAX_SAFE_INTEGER
        },
    });
    const {
        items: latest_items,
    } = useSubscribeList<WebinarPostAttendeeModel>({
        entity_name: 'webinar_post',
        fragment: `id created_at`,
        query_inner: `where: {
                attendance_id: {_eq: $attendance_id},
        }, order_by: {id: desc}, limit: 1`,
        clause_outer: `$attendance_id: bigint!`,
        variables: {
            attendance_id,
        },
    });

    React.useEffect(() => {
        if (!latest_items || latest_items.length === 0) {
            return;
        }
        if (items.length === 0 || items[0].id < latest_items[0].id) {
            refetch({
                attendance_id,
                cursor: Number.MAX_SAFE_INTEGER,
            });
        }
    }, [latest_items]);

    const loadMore = () => {
        if (items.length === 0) {
            return;
        }
        fetchMore({
            attendance_id,
            cursor: items[items.length - 1].id,
        })
    }

    return <Box pt={2}>
        <Typography variant='h6'>
            Questions & Comments <span role='img'>👇</span>
        </Typography>
        {items.map(item => <div
            key={item.id}
            className={classes.message}>
            <Typography variant='body2'><strong>{item.name}</strong> &middot; {moment(item.created_at).subtract(20, 's').fromNow()}</Typography>
            <Typography variant='body1'>{item.message}</Typography>
        </div>)}
        <Box py={2}>
            <LoadingButton
                loading={loading}
                fullWidth
                disabled={!has_more}
                onClick={loadMore}>{has_more ? 'Load more posts' : 'End of posts'}</LoadingButton>
        </Box>
    </Box>
}

const useStyles = makeStyles(theme => createStyles({
    message: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 0),
    },
    host: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(0, 1),
    }
}))