import { createStyles, makeStyles } from "@material-ui/core";
import { Field, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";

interface Props extends FormikProps<any> {

}

export const ResourceForm = ({
    values,
}: Props) => {
    const classes = useStyles({});
    return <>
        <Field
            name='name'
            component={TextField}
            label='Resource name'
            margin='normal'
            fullWidth
        />
         <Field
            name='link'
            component={TextField}
            label='Resource link'
            margin='normal'
            fullWidth
        />
    </>
}

const useStyles = makeStyles(theme => createStyles({

}))