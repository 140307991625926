import { createStyles, IconButton, Link, makeStyles, Tooltip, Typography } from "@material-ui/core";
import TicketIcon from '@material-ui/icons/ConfirmationNumber';
import DasbhoardIcon from '@material-ui/icons/Dashboard';
import ResourceIcon from '@material-ui/icons/FileCopy';
import CheckInIcon from '@material-ui/icons/MeetingRoom';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import AttendeeIcon from '@material-ui/icons/People';
import ShareIcon from '@material-ui/icons/Share';
import CertificateIcon from '@material-ui/icons/Star';
import DiplomaIcon from '@material-ui/icons/EmojiEvents'
import moment from "moment";
import React from "react";
import { Currency } from "../../models/currency";
import { EventModel } from "../../models/event.model";
import { getFormattedCurrency } from "../../utils/get-formatted-currency";
import { getTimeRangeTimezone } from "../../utils/time-range";

type Props = {
    item: EventModel;
    currency: Currency;
    onMore: (ev: React.MouseEvent<HTMLButtonElement>, item: EventModel) => void;
    onShare: (item: EventModel) => void;
}

export const EventListItem = ({
    item,
    currency,
    onMore,
    onShare,
}: Props) => {
    const classes = useStyles({});

    return <div className={classes.item}>
        <div className={'header'}>
            <Link href={`/events/${item.id}`} target='_blank'>
                <Typography
                    style={{
                        fontWeight: 'bold',
                    }} variant='body1'>{item.title}</Typography>
            </Link>
            <Typography variant='body2' color='textSecondary'>
                {getTimeRangeTimezone(item.starts_at, item.ends_at, item.timezone)}
            </Typography>
            <div className='buttons'>
                <Tooltip title='Event Dashboard'>
                    <IconButton target='_blank' href={`/events/${item.id}`} size='small'>
                        <DasbhoardIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Manage attendees'>
                    <IconButton target='_blank' href={`/events/${item.id}/attendees`} size='small'>
                        <AttendeeIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Manage tickets'>
                    <IconButton target='_blank' size='small' href={`/events/${item.id}/tickets`}>
                        <TicketIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Manage certificates'>
                    <IconButton target='_blank' href={`/events/${item.id}/certificates`} size='small'>
                        <CertificateIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Manage diplomas'>
                    <IconButton target='_blank' href={`/events/${item.id}/diplomas`} size='small'>
                        <DiplomaIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Manage Notes & Resources'>
                    <IconButton target='_blank' href={`/events/${item.id}/resources`} size='small'>
                        <ResourceIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Manage attendance'>
                    <IconButton target='_blank' href={`/events/${item.id}/attendance`} size='small'>
                        <CheckInIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Share event'>
                    <IconButton size='small' onClick={() => onShare(item)}>
                        <ShareIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='More'>
                    <IconButton size='small' onClick={e => onMore(e, item)}>
                        <MoreIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
        <div className='stat'>
            <Link target='_blank' href={`/events/${item.id}/attendees`}>
                <div className='padded'>
                    <Typography variant='h6'
                        className='number'>{item.attendee_tally?.total_attendees || 0}</Typography>
                    <Typography
                        color='textSecondary'
                        variant='body2'>REGISTRATIONS</Typography>
                </div>
            </Link>
            {item.invoice_item_tally &&
                !!item.invoice_item_tally.total_amount ? <Link target='_blank' href={`/events/${item.id}/sales`}>
                    <div className='padded'>
                        <Typography variant='h6'
                            className='number'>{getFormattedCurrency(item.invoice_item_tally?.total_amount || 0, currency)}</Typography>
                        <Typography
                            color='textSecondary'
                            variant='body2'>{item.invoice_item_tally?.total_quantity || 0} SALES</Typography>
                    </div>
                </Link> : null}
        </div>
        <div className='orders'>
            <Typography variant='overline'>Recently added</Typography>
            {(item.attendees || []).map(attendee => <Link
                href={`/events/${item.id}/attendees?filter=id:${attendee.id}`}
                key={attendee.id}>
                <span className='name'>{attendee.name}</span> {moment(attendee.created_at).fromNow()}
            </Link>)}
        </div>
        {(item.attendees || []).length === 0 ? <Typography variant='body2'>
            No attendees yet. <Link target='_blank' href={`/events/${item.id}/attendees?add=1`}>Add an attendee</Link></Typography> : null}
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    item: {
        border: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '& .header': {
            borderBottom: `1px solid ${theme.palette.divider}`,
            paddingBottom: theme.spacing(1),
            '& a': {
                // color: 'inherit',
                textDecoration: 'none',
                '&:hover': {
                    textDecoration: 'underline',
                }
            },
            '& .buttons': {
                margin: theme.spacing(0, -.5),
                '& a': {
                    margin: theme.spacing(0, .5),
                },
                '& button': {
                    margin: theme.spacing(0, .5),
                }
            }
        },
        '& .stat': {
            padding: theme.spacing(3, 0),
            textAlign: 'center',
            borderBottom: `1px solid ${theme.palette.divider}`,
            justifyContent: 'space-around',
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
            },
            '& .padded': {
                padding: theme.spacing(1, 2),
            },
            '& a': {
                color: 'inherit',
                textDecoration: 'none',
            },
            '& .number': {
                fontSize: '3.5em',
                lineHeight: '1em',
            },
            '& p': {
                fontSize: '.9em',
            }
        },
        '& .orders': {
            '& a': {
                display: 'block',
                textDecoration: 'none',
                color: 'inherit',
                '& .name': {
                    fontWeight: 'bold',
                },
                '&:hover': {
                    textDecoration: 'underline',
                }
            },

        }
    }
}))