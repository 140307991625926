import { Button, Container, createStyles, IconButton, makeStyles, Menu, MenuItem, Tooltip, Typography } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import DashboardIcon from '@material-ui/icons/Dashboard';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import ShareIcon from "@material-ui/icons/Share";
import { navigate, RouteComponentProps } from '@reach/router';
import moment from "moment";
import React from 'react';
import { useGetByPk } from "../../apollo/networking/use-get-by-pk";
import { useUpdateByPk } from "../../apollo/networking/use-update-by-pk";
import { useAppDialog } from "../../app-dialog";
import { Image, ImageSize, ImageUpload, LazyImage } from "../../image-upload";
import { AppLayout } from '../../layout/app-layout';
import { EventFragment, EventModel } from "../../models/event.model";
import { DeleteConfirmDialog } from "../../ui/delete-confirm.dialog";
import { LoadingDots } from "../../ui/loading-dots";
import { PageHeader } from "../../ui/page-header";
import { UnstyledLink } from "../../ui/unstyled-link";
import { getTimeRangeTimezone } from "../../utils/time-range";
import { EventEditorDialog } from "../events/event-editor";
import { EventShare } from "../events/event-share";
import { EventEditor } from "./event-editor";

type Props = {
    event_id: number;
}

export const EventEditPage = ({
    event_id,
}: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const [show_share, setShowShare] = React.useState(false);
    const [show_delete, setShowDelete] = React.useState(false);
    const [show_editor, setShowEditor] = React.useState(false);
    const [anchor_el, setAnchorEl] = React.useState<HTMLButtonElement | undefined>();
    const [image_upload, setImageUpload] = React.useState(false);

    const {
        loading,
        entity: event,
    } = useGetByPk<EventModel>({
        entity_name: 'event',
        fragment: EventFragment,
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: event_id,
        }],
    });
    const { onUpdate, loading: updating } = useUpdateByPk({
        entity_name: 'event',
        fragment: EventFragment,
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: event_id,
        }],
    });

    const feature_images = React.useMemo(() => {
        return event?.feature_images || [];
    }, [event?.feature_images])

    const onMore = (ev: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(ev.currentTarget)
    }

    const onEdit = () => {
        setAnchorEl(undefined);
        setShowEditor(true);
    }

    const onDelete = () => {
        setShowDelete(true);
        setAnchorEl(undefined);
    }

    const onConfirmDelete = async () => {
        try {
            const result = (await onUpdate({
                deleted_at: 'now()',
            })) as any;
            if (result?.deleted_at) {
                app_dialog.showSnackbar('Event deleted');
                setShowDelete(false);
                navigate('/')
            }
        } catch (e: any) {
            app_dialog.showError(e);
        }
    }

    const onChangeImage = (images: Image[]) => {
        if (images.length === 0) {
            return;
        }
        onUpdate({ feature_images: images.reverse().map(i => i.key) });
        setImageUpload(false);
    }

    const removeImage = (idx: number) => () => {
        onUpdate({
            feature_images: feature_images
                .filter((_, i) => idx !== i)
        });
    }


    return <AppLayout>
        {loading ? <LoadingDots /> : null}
        {event ? <>
            {show_share ? <EventShare
                name={event.title}
                url={event.url}
                is_live={event.is_live}
                event_id={event.id}
                onClose={() => setShowShare(false)}
            /> : null}
            {show_delete ? <DeleteConfirmDialog
                onCancel={() => setShowDelete(false)}
                onConfirm={onConfirmDelete}
                message={`Are you sure you want to delete the event: ${event.title}. This is not reversible and if there are attendees assigned to this ticket, they won't be able to access it anymore.`}
                deleting={updating}
                confirm_text='confirm'
            /> : null}
            {show_editor ? <EventEditorDialog
                action='edit'
                item={event}
                no_editor_hint
                onFinish={() => setShowEditor(false)}
                onClose={() => setShowEditor(false)} /> : null}
            {image_upload ? <ImageUpload
                onSelect={onChangeImage}
                multiple
                handleClose={() => setImageUpload(false)}
            /> : null}
            <Menu
                open={Boolean(anchor_el)}
                anchorEl={anchor_el}
                onClose={() => setAnchorEl(undefined)}
            >
                <MenuItem onClick={onEdit}>Change title & time</MenuItem>
                <MenuItem onClick={onDelete}><Typography color='secondary'>Delete</Typography></MenuItem>
            </Menu>
            <PageHeader title={event.title} subtitle={
                getTimeRangeTimezone(event.starts_at, event.ends_at, event.timezone)
            } />
            {feature_images.length > 0 ? <div className={classes.images}>
                <Typography variant='overline'>Featured images</Typography>
                <div className='flex'>
                    {feature_images.map((image, idx) => <div
                        key={`${image}.${idx}`}
                        className='wrapper'>
                        <LazyImage
                            image_key={image}
                            className={'item'}
                            image_opt={{ size: ImageSize.medium, aspect_ratio: 2 }}
                        />
                        <IconButton color='secondary' size='small' onClick={removeImage(idx)}>
                            <RemoveIcon fontSize='small' />
                        </IconButton>
                    </div>)}
                </div>
            </div> : null}

            <div className={classes.menu}>
                <UnstyledLink to={`/events/${event_id}/preview`}>
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'>Preview</Button>
                </UnstyledLink>
                <Button variant='contained'
                    onClick={onEdit}
                    endIcon={<ChevronRight />}
                    size='small'>Update Title | Time | Live/Draft</Button>

                <Button
                    variant='outlined'
                    onClick={() => setImageUpload(true)}
                    size='small'>Set Featured Image</Button>
            </div>
            <EventEditor
                content={event.content}
                onUpdate={onUpdate} />
            <div className={classes.footer}>
                <Container maxWidth='md' className='content'>
                    <div style={{ flex: 1 }}>
                        <Typography
                            className='button' variant='body2' onClick={() => setShowEditor(true)}><strong>{event.is_live ? 'Live' : 'Draft'}</strong></Typography>
                        <Typography variant='body2'>{updating ? 'Saving...' : `Last saved ${moment(event.updated_at).subtract(30, 's').fromNow()}`}</Typography>
                    </div>
                    <Tooltip title='Event Dashboard'>
                        <UnstyledLink to={`/events/${event_id}`}>
                            <IconButton>
                                <DashboardIcon />
                            </IconButton>
                        </UnstyledLink>
                    </Tooltip>
                    <Tooltip title='Share Event'>
                        <IconButton onClick={() => setShowShare(true)}>
                            <ShareIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='More'>
                        <IconButton onClick={onMore}>
                            <MoreIcon />
                        </IconButton>
                    </Tooltip>
                </Container>
            </div>
        </> : null}
    </AppLayout>
}


const useStyles = makeStyles(theme => createStyles({
    menu: {
        paddingBottom: theme.spacing(2),
        margin: theme.spacing(0, -.5),
        '& button': {
            margin: theme.spacing(0, .5),
        }
    },
    images: {
        paddingBottom: theme.spacing(2),
        '& .flex': {
            display: 'flex',
            flexWrap: 'none',
            overflowY: 'auto',
            '& .wrapper': {
                position: 'relative',
                '& .item': {
                    width: 200,
                    height: 100,
                },
                '& button': {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                }
            },

        }
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        boxShadow: theme.shadows[2],
        padding: theme.spacing(2, 0),
        zIndex: 99,
        '& .content': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .button:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        }
    },
}))