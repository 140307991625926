import { AttendanceState } from "./attendance.model";

export type WebinarModel = {
    id: number;
    youtube_url: string;
    name: string;
    state: AttendanceState;
}

export const WebinarFragment = `
    id
    youtube_url
    name
    state
`;
