import { gql, useApolloClient } from "@apollo/client";
import { Button, createStyles, DialogContent, makeStyles, Typography } from "@material-ui/core";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import moment from "moment";
import React from "react";
import { CSVLink } from "react-csv";
import { AttendeeAttendanceFragment, AttendeeAttendanceModel } from "../../models/attendee-attendance.model";
import { BasicDialog, BasicDialogTitle } from "../../ui/dialog";
import { LoadingDots } from "../../ui/loading-dots";
import { getDurationText } from "../../utils/time-range";


type Props = {
    attendance_id: number;
    event_id: number;
    event_title: string;
    onClose: () => void;
}


const QueryAttendee = gql`
    query attendees(
        $event_id: bigint!,
        $attendance_id: bigint!,
        $cursor: bigint!,
        $limit: Int!
    ) {
        attendee(where: {
            _and: [
                {ticket: {
                    event_id: {_eq: $event_id}
                }},
                {id: {_gt: $cursor}}
            ]
        }, limit: $limit, order_by: {id: asc}) {
            ${AttendeeAttendanceFragment}
        }
    }
`;

export const ExportCertAttendees = ({
    attendance_id,
    event_id,
    event_title,
    onClose,
}: Props) => {
    const classes = useStyles({});
    const [is_ready, setIsReady] = React.useState(false);
    const [csv_data, setCsvData] = React.useState<any[]>([]);
    const [columns, setColumns] = React.useState<string[]>([]);
    const [total_rows, setTotalRows] = React.useState(0);
    const client = useApolloClient();

    const fetchAttendees = async (attendees: AttendeeAttendanceModel[], limit: number, cursor = 0): Promise<AttendeeAttendanceModel[]> => {
        try {
            const { data, error } = await client.query({
                query: QueryAttendee,
                variables: {
                    limit,
                    cursor,
                    event_id,
                    attendance_id,
                }
            });
            const new_attendees = (data?.attendee || []) as AttendeeAttendanceModel[];
            if (new_attendees.length < limit) {
                return [
                    ...attendees,
                    ...new_attendees,
                ];
            }
            return fetchAttendees([
                ...attendees,
                ...new_attendees,
            ], limit, new_attendees[new_attendees.length - 1].id);
        } catch (e: any) {
            console.error(e);
            return [];
        }
    }

    React.useEffect(() => {
        fetchAttendees([], 50).then(data => {
            const columns: string[] = [
                'id',
                'name',
                'email',
                'agd_id',
                'checkin_at',
                'checkout_at',
                'duration',
                'ticket_id',
                'ticket_name',
                'invoice_item_id',
            ];
            const csv_data = data
                .filter(item => item
                    .dropins && item.dropins.length > 0)
                .map(d => {
                    const dropin = d.dropins[0];
                    return {
                        ...d,
                        created_at: moment(d.created_at).format('LLL'),
                        ticket_name: d.ticket.name,
                        checkin_at: dropin.checkin_at ? moment(dropin.checkin_at).format('LLL') : '-',
                        checkout_at: dropin.checkout_at ? moment(dropin.checkout_at).format('LLL') : '-',
                        duration: dropin.checkin_at && dropin.checkout_at ? getDurationText((+new Date(dropin.checkout_at) - +new Date(dropin.checkin_at))/1000) : '-',
                        ...(d.data ? Object.keys(d.ticket.schema?.properties || {}).reduce(
                            (obj, pkey, idx) => {
                                const property = d.ticket.schema.properties[pkey] as any;
                                const label = `${property.title}_${idx}`;
                                columns.push(label)
                                return {
                                    ...obj,
                                    [label]: d.data[pkey] || '',
                                };
                            }, {}) : {})
                    }
                });
            setTotalRows(csv_data.length);
            setColumns(columns);
            setCsvData(csv_data);
            setIsReady(true);
        })
    }, []);

    return <BasicDialog
        id='export-attendees'
        onClose={onClose}
        open={true}
    >
        <BasicDialogTitle title='Export Attendees' onClose={onClose} />
        <DialogContent>
            <div className={classes.loading}>
                {is_ready ? <>
                    <Typography variant='h6' gutterBottom>
                        Your download is ready. {total_rows} attendees found.
                    </Typography>
                    <CSVLink
                        data={csv_data}
                        filename={`${event_title}_attendees_${moment().format('LL')}.csv`}
                        headers={columns}
                        className={classes.button}
                        target="_blank">
                        <Button
                            variant='contained'
                            color='secondary'
                            size='large'
                            startIcon={<DownloadIcon />}
                        >Download Report</Button>
                    </CSVLink>
                </> : <>
                        <LoadingDots />
                        <Typography variant='h6' align='center'>
                            Preparing your export...one moment.</Typography>
                    </>}
            </div>
        </DialogContent>
    </BasicDialog>
}

const useStyles = makeStyles(theme => createStyles({
    button: {
        textDecoration: 'none !important',
    },
    loading: {
        textAlign: 'center',
        padding: theme.spacing(0, 0, 4, 0),
    }
}))