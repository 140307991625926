import { Button, createStyles, DialogContent, makeStyles, Typography } from "@material-ui/core";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TreeItem, TreeView } from "@material-ui/lab";
import moment from "moment";
import React from "react";
import { useList } from "../../../apollo/networking";
import { BasicDialog, BasicDialogActions, BasicDialogTitle } from "../../../ui/dialog";
import { LoadingDots } from "../../../ui/loading-dots";
import { getTime } from "../../../utils/time-range";
import { InvoiceItemMoveEventFragment, InvoiceItemMoveEventModel, InvoiceItemMoveTicketModel } from "./invoice-item-move.model";


type Props = {
    onClose: () => void;
    onSelectTicket: (props: {
        event: InvoiceItemMoveEventModel;
        ticket: InvoiceItemMoveTicketModel;
    }) => void;
}

export const InvoiceItemMoveDialog = ({
    onClose,
    onSelectTicket,
}: Props) => {
    const classes = useStyles({});
    const [show_future, setShowFuture] = React.useState(true);
    const timestamp = React.useMemo(() => moment().subtract(show_future ? 6 : 0, 'hour').toISOString(), [show_future]);
    const [selected_ticket, setSelectedTicket] = React.useState<{
        event: InvoiceItemMoveEventModel;
        ticket: InvoiceItemMoveTicketModel;
    } | undefined>();
    const {
        items,
        loading,
        error,
        refetch,
    } = useList<InvoiceItemMoveEventModel>({
        entity_name: 'event',
        fragment: InvoiceItemMoveEventFragment,
        clause_outer: `$timestamp: timestamptz!`,
        query_inner: `where:
            ${show_future ? `{
                ends_at: {_gte: $timestamp},
            }` : `{
                ends_at: {_lt: $timestamp},
            }`}, order_by: {starts_at: ${show_future ? 'asc' : 'desc'}}`,
        variables: {
            timestamp,
        }
    });


    return <BasicDialog
        open={true}
        id='move attendee'
        onClose={onClose}>
        <BasicDialogTitle onClose={onClose} title='Change Event or Ticket' />
        <DialogContent>
            <Typography variant='h6'>Select the new Event/Ticket</Typography>
            <div className={classes.buttongroup}>
                <Button
                    size='small'
                    variant={show_future ? 'contained' : 'outlined'}
                    color={show_future ? 'primary' : 'default'}
                    onClick={() => setShowFuture(true)}
                >Future Events</Button>
                <Button
                    size='small'
                    variant={!show_future ? 'contained' : 'outlined'}
                    color={!show_future ? 'primary' : 'default'}
                    onClick={() => setShowFuture(false)}
                >Past Events</Button>
            </div>
            {loading ? <LoadingDots /> : null}
            {JSON.stringify(error)}
            <TreeView
                // className={classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
            >
                {items.map(item => <TreeItem
                    key={item.id}
                    nodeId={`${item.id}`}
                    label={`${item.title.substring(0, 40)}${item.title.length > 40 ? '...' : ''} (${getTime(item.starts_at, item.ends_at)})`}>
                    {item.tickets.map(ticket => <TreeItem
                        key={ticket.id}
                        onClick={() => setSelectedTicket({
                            event: item,
                            ticket,
                        })}
                        nodeId={`${ticket.id}`}
                        label={`${ticket.name} - ${ticket.ticket_tally?.total_sold || 0}/${ticket.is_inventory ? ticket.total_inventory : '∞'}`} />)}
                </TreeItem>)}
            </TreeView>
        </DialogContent>
        <BasicDialogActions>
            {selected_ticket ? <Typography
            variant='body2'
            style={{flex: 1}}>Attendee will be moved to: 
            <br/>Event: {selected_ticket.event.title.substring(0, 40)}{selected_ticket.event.title.length > 40 ? '...' : ''}<br/>Ticket: {selected_ticket.ticket.name}</Typography> : null}
            <Button
                size='large'
                onClick={onClose}
            >Dismiss</Button>
            <Button
                size='large'
                variant='contained'
                color='secondary'
                disabled={!selected_ticket}
                onClick={() => onSelectTicket(selected_ticket as any)}
            >Change Ticket</Button>
        </BasicDialogActions>
    </BasicDialog>
}

const useStyles = makeStyles(theme => createStyles({
    buttongroup: {
        marginBottom: theme.spacing(2),
        '& button': {
            marginRight: theme.spacing(1),
        }
    }
}))