export type CertificateModel = {
    id: number;
    created_at: string;
    updated_at: string;
    event_id: number;
    title: string;
    speaker_name?: string;
    units: number;
    subject_code: string;
    location?: string;
    course_date?: string;
    educational_method?: string;
    event: {
        id: number;
        title: string;
    }
}

export const CertificateFragment  = `
    id
    created_at
    updated_at
    event_id
    title
    speaker_name
    units
    subject_code
    location
    course_date
    educational_method
    event {
        id
        title
    }
`;
