import { Button, createStyles, makeStyles, MenuItem, TextField, Typography } from "@material-ui/core";
import { Link } from "@reach/router";
import moment from "moment";
import React from "react";
import { useList } from "../../apollo/networking";
import { EventFragment, EventModel } from "../../models/event.model";


type Props = {
    onCreate: () => void;
    show_future: boolean;
    setShowFuture: (v: boolean) => void;
}

export const EventManageSidebar = ({
    onCreate,
    show_future,
    setShowFuture,
}: Props) => {
    const classes = useStyles({});
    const {
        items,
        loading,
        refetch,
    } = useList<EventModel>({
        entity_name: 'event',
        fragment: EventFragment,
        query_inner: `where: {
            is_live: {_eq: false}
       }, order_by: {created_at: desc}`,
    });

    return <>
        <div className={classes.section}>
            <Typography variant='h6'>Create</Typography>
            <Button
                variant='contained'
                onClick={onCreate}
                color='primary'>+ New Event</Button>
            <TextField
                value={show_future ? 'future' : 'past'}
                onChange={e => setShowFuture(e.target.value === 'future' ? true : false)}
                fullWidth
                margin='normal'
                size='small'
                select
            >
                <MenuItem value='future'>Show Future Events</MenuItem>
                <MenuItem value='past'>Show Past Events</MenuItem>
            </TextField>
        </div>
        {items.length > 0 ? <div className={classes.section}>
            <Typography variant='h6'>Drafts</Typography>
            {items.map(item => <div key={item.id} className={classes.draftItem}>
                <Link to={`/events/${item.id}`}>
                    <Typography variant='body1'>
                        {item.title}
                    </Typography>
                </Link>
                <Typography variant='body2' color='textSecondary'>
                    Created {moment(item.created_at).calendar()}
                </Typography>
            </div>)}
        </div> : null}
    </>
}

const useStyles = makeStyles(theme => createStyles({
    section: {
        paddingBottom: theme.spacing(2),
    },
    draftItem: {
        padding: theme.spacing(1, 0),
        '& a': {
            color: 'inherit',
            fontWeight: 'bold',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            }
        }
    }
}))