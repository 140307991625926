import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useGetByPk } from "../../apollo/networking/use-get-by-pk"
import { AppLayout } from "../../layout/app-layout"
import { DiplomaFragment, DiplomaModel } from "../../models/diploma.model"
import { LoadingDots } from "../../ui/loading-dots"
import { PageHeader } from "../../ui/page-header"
import { DiplomaAttendeeList } from "./diploma-attendee-list"

type Props = {
    diploma_id: number;
}

export const DiplomaPage = ({
    diploma_id,
}: RouteComponentProps<Props>) => {
    const {
        entity: diploma,
        loading,
    } = useGetByPk<DiplomaModel>({
        entity_name: 'diploma',
        fragment: DiplomaFragment,
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: diploma_id,
        }]
    });

    return <AppLayout>
        {loading ? <LoadingDots /> : null}
        {diploma ? <>
            <PageHeader
                title={diploma.title}
            />
            <DiplomaAttendeeList
                diploma={diploma}
            />
        </> : null}
    </AppLayout>
}
