import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core"
import { Link } from "@reach/router";
import moment from "moment";
import pluralize from "pluralize";
import React from "react"
import { useList } from "../../apollo/networking";
import { useOrg } from "../../auth/use-org";
import { TicketFragment, TicketModel } from "../../models/ticket.model";
import { Section } from "../../ui/section";
import { UnstyledLink } from "../../ui/unstyled-link";
import { getFormattedCurrency } from "../../utils/get-formatted-currency";
import { sortItemsByIdList } from "../../utils/sort-items-by-id-list";


type Props = {
    event_id: number;
    ticket_order?: number[];
    recent_attendees?: {
        id: number;
        name: string;
        created_at: string;
    }[];
    attendee_tally?: number;
}

export const RegistrationSummary = ({
    event_id,
    ticket_order,
    recent_attendees,
    attendee_tally,
}: Props) => {
    const classes = useStyles({});
    const {
        org: {
            currency,
        }
    } = useOrg();
    const {
        items,
        loading
    } = useList<TicketModel>({
        entity_name: 'ticket',
        fragment: TicketFragment,
        query_inner: `where: {
            event_id: {_eq: $event_id}
        }`,
        clause_outer: `$event_id: bigint!`,
        variables: {
            event_id,
        }
    });
    const _tickets = React.useMemo(() => sortItemsByIdList(
        ticket_order || [],
        items,
    ), [ticket_order, items]);

    return (
        <Section title={
            (attendee_tally || 0) > 0 ?
                `${attendee_tally} ${pluralize('Registration', attendee_tally)}`
                : 'Registration summary'}>
            <Grid container spacing={1} className={classes.grid}>
                {_tickets.map(t => <Grid
                    key={t.id}
                    item
                    xs={6}>
                    <UnstyledLink to={`/events/${event_id}/attendees?filter=ticket_id:${t.id}`}>
                        <div className={classes.ticket}>
                            <Typography variant='h2'>
                                <strong>{t.ticket_tally?.total_sold || 0}</strong>
                            </Typography>
                            <Typography variant='h6'>{t.name}</Typography>
                            <Typography variant='body1'>{getFormattedCurrency(t.price, currency)}</Typography>
                        </div>
                    </UnstyledLink>
                </Grid>)}
            </Grid>
            <Typography variant='overline'>Recently added</Typography>
            {(recent_attendees || []).map(attendee => <Typography
                key={attendee.id}>
                <strong>{attendee.name}</strong> {moment(attendee.created_at).fromNow()}
            </Typography>)}
            <Link to={`/events/${event_id}/attendees`}><Typography color='primary'>View all registrations</Typography></Link>
        </Section>
    )
}

const useStyles = makeStyles(theme => createStyles({
    ticket: {
        border: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(1),
        transition: 'all .3s ease',
        '&:hover': {
            boxShadow: theme.shadows[2],
        }
    },
    grid: {
        paddingBottom: theme.spacing(4),
    }
}))