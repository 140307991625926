import { Box, Button, createStyles, IconButton, Link, makeStyles, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@material-ui/core";
import MoreIcon from '@material-ui/icons/MoreHoriz';
import moment from "moment";
import React from "react";
import { useKeysetPagination } from "../../apollo/networking/use-keyset-pagination";
import { environment } from "../../environment";
import { InvoiceFragment, InvoiceModel } from "../../models/invoice.model";
import { StripePaymentIntentStatus } from "../../models/stripe-payment-intent-status.enum";
import { ColumnSelector } from "../../ui/column-selector";
import { LoadingButton } from "../../ui/loading-button";
import { getFormattedCurrency } from "../../utils/get-formatted-currency";
import { ExportInvoiceItem } from "./export-invoice-item";
import { InvoiceDetailsDialog } from "./invoice-details";
import { InvoiceCol, useInvoiceCols } from "./use-invoice-cols";


type Props = {
}

export const InvoiceList = ({
}: Props) => {
    const classes = useStyles({});
    const [status, setStatus] = React.useState(StripePaymentIntentStatus.Succeeded);
    const [show_export, setShowExport] = React.useState(false);
    const {
        columns,
        showColumn,
        toggleColumn,
    } = useInvoiceCols();
    const [anchor_el, setAnchorEl] = React.useState<{
        anchor: HTMLButtonElement;
        item: InvoiceModel;
    } | undefined>();
    const cursor = React.useMemo(() => moment().endOf('hour').toISOString(), []);
    const [editor, setEditor] = React.useState<InvoiceModel | undefined>();
    const {
        items,
        loading,
        has_more,
        fetchMore
    } = useKeysetPagination<InvoiceModel>({
        entity_name: 'invoice',
        fragment: InvoiceFragment,
        clause_outer: `$status: String!, $cursor: timestamptz!`,
        variables: {
            status,
            cursor,
        },
        query_inner: `
            where: {_and: [
                {status: {_eq: $status}},
                {created_at: {_lt: $cursor}},
            ]},
            order_by: {
                created_at: desc,
            }
        `
    });

    const onRefund = () => {
        if (!anchor_el?.item) {
            return;
        }
        setEditor(anchor_el.item);
        setAnchorEl(undefined);
    }


    const loadMore = () => {
        if (items.length === 0) {
            return;
        }
        fetchMore({
            status,
            cursor: items[items.length - 1].created_at,
        })
    }

    return <>
        {show_export ? <ExportInvoiceItem
            onClose={() => setShowExport(false)}
        /> : null}
        <Menu
            open={Boolean(anchor_el)}
            anchorEl={anchor_el?.anchor}
            onClose={() => setAnchorEl(undefined)}
        >
            <MenuItem onClick={onRefund}>Refund</MenuItem>
        </Menu>

        {editor ? <InvoiceDetailsDialog
            invoice_id={editor.id}
            onClose={() => setEditor(undefined)} /> : null}

        <div className={classes.header}>
            <Button
                size='small'
                color='primary'
                onClick={() => setShowExport(true)}
                variant='contained'
                style={{
                    marginRight: 8,
                }}>
                Export
            </Button>
            <div style={{ flex: 1 }} />
            <ColumnSelector<InvoiceCol>
                showColumn={showColumn}
                toggleColumn={toggleColumn}
                columns={columns}
            />
        </div>
        <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow className='header'>
                        <TableCell style={{ width: 50 }} />
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                        {showColumn('email') ? <TableCell>Email</TableCell> : null}
                        {showColumn('agd_id') ? <TableCell>AGD ID</TableCell> : null}
                        <TableCell>Link</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                        <TableRow
                            key={item.id}
                            hover
                        >
                            <TableCell style={{ width: 50 }}>
                                <Tooltip title='More Options'>
                                    <IconButton size='small' onClick={(e) => setAnchorEl({
                                        anchor: e.currentTarget,
                                        item,
                                    })}>
                                        <MoreIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell style={{ width: 50 }}>
                                {item.id}
                            </TableCell>
                            <TableCell>{item.name}</TableCell>
                            {showColumn('email') ? <TableCell>{item.email}</TableCell> : null}
                            {showColumn('agd_id') ? <TableCell>{item.agd_id}</TableCell> : null}
                            <TableCell><Link
                                target='_blank'
                                href={`${environment.checkout_url}/${item.id}?secret=${item.secret}`}>Viewer Link</Link>
                            </TableCell>
                            <TableCell>{moment(item.status_updated_at).format('LLL')}</TableCell>
                            <TableCell>
                                {getFormattedCurrency(item.amount, item.currency)} <span className='refund'>{(item.refund_tally?.total_amount || 0) > 0
                                    ? `-${getFormattedCurrency(item.refund_tally?.total_amount || 0, item.currency)}`
                                    : ''}</span></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer >

        {has_more ? <Box py={2}>
            <LoadingButton
                loading={loading}
                onClick={loadMore}
                fullWidth>Load More...</LoadingButton>
        </Box> : null}
    </>
}

const useStyles = makeStyles(theme => createStyles({
    table: {
        width: '100%',
        background: 'white',
        border: `1px solid ${theme.palette.divider}`,
        '& .header': {
            background: theme.palette.background.paper,
        },
        '& .refund': {
            color: theme.palette.secondary.main,
        }
    },
    buttons: {
        width: 50,
    },
    header: {
        display: 'flex',
        paddingBottom: theme.spacing(2),
    }
}))