import { useMutation } from "@apollo/client";
import { useAppDialog } from "../../../app-dialog";
import { useOrg } from "../../../auth/use-org";
import { STRIPE_CONNECT_MUTATION } from "./stripe-connect.query";


export const useStripeConnect = () => {
	const { org: {
		stripe_id,
		id,
	} } = useOrg();

	const app_dialog = useAppDialog();
	const [connect, connect_result] = useMutation(STRIPE_CONNECT_MUTATION);

	const onConnect = async (code: string) => {
		try {
			const { data } = await connect({
				variables: {
					code,
				}
			})
			if (data && data.stripe_connect && data.stripe_connect.success) {
				app_dialog.showSnackbar('Stripe connected');
			}
		} catch (e: any) {
			app_dialog.showError(e);
		}
	}

	const connect_data = connect_result.data && connect_result.data.stripe_connect
		? connect_result.data.stripe_connect : undefined;

	return {
		stripe_id,
		onConnect,
		success: stripe_id || connect_data?.success,
		error: connect_result.error,
		loading: connect_result.loading,
	}
}