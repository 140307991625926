import { gql, useMutation } from "@apollo/client";
import { useAppDialog } from "../../../app-dialog";
import { AttendeeFragment, AttendeeModel } from "../../../models/attendee.model"

const UpdateAttendeeMutation = gql`
    mutation move_attendee($attendee_id: bigint!, $ticket_id: bigint!) {
        update_attendee_by_pk(
            pk_columns: {
                id: $attendee_id
            },
            _set: {
                ticket_id: $ticket_id
            }
        ) {
            ${AttendeeFragment}
        }
    }
`;

export const useAttendeeMove = () => {
    const app_dialog = useAppDialog();
    const [updateAttendee] = useMutation(UpdateAttendeeMutation);

    return {
        moveAttendee: async (attendee: AttendeeModel, ticket_id: number) => {
            try {
                await updateAttendee({
                    variables: {
                        attendee_id: attendee.id,
                        ticket_id,
                    }
                });
                app_dialog.showSnackbar('Attendee ticket changed');
                return true;
            } catch (e) {
                app_dialog.showError(e);
                return false;
            }
        }
    }
}