import { useStorage } from "../../utils/use-storage";

const columns_key = 'attendee_cols';

export type InvoiceItemCol = ('email' | 'referrer' | 'ticket' | 'agd_id' | 'price' | 'discount' | 'subtotal' | 'sold_at');
export type InvoiceItemCols = InvoiceItemCol[];

const columns: {
    id: InvoiceItemCol;
    label: string;
}[] = [{
    id: 'email',
    label: 'Email',
},{
    id: 'referrer',
    label: 'Referred By',
},{
    id: 'ticket',
    label: 'Ticket',
},{
    id: 'agd_id',
    label: 'AGD ID',
},{
    id: 'price',
    label: 'Price',
},{
    id: 'discount',
    label: 'Discount',
},{
    id: 'subtotal',
    label: 'Subtotal',
},{
    id: 'sold_at',
    label: 'Sold At',
},]
export const useInvoiceItemCols = () => {
    const {
        getKey,
        setKey,
    } = useStorage();
    const cols = getKey(columns_key, []) as InvoiceItemCols;
    const setCols = (v: InvoiceItemCols) => setKey(columns_key, v);

    const showColumn = (id: InvoiceItemCol) => {
        return cols.indexOf(id) > -1;
    }

    const toggleColumn = (id: InvoiceItemCol) => {
        if (!showColumn(id)) {
            setCols([
                ...cols, id,
            ]);
        } else {
            setCols(cols.filter(c => c !== id));
        }
    }

    return {
        cols,
        columns,
        setCols,
        showColumn,
        toggleColumn,
    };
}