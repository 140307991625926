import { gql, useMutation } from "@apollo/client";
import { DialogContent } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { useAppDialog } from "../../../app-dialog";
import { CertificateFragment, CertificateModel } from "../../../models/certificate.model";
import { BasicDialogActions } from "../../../ui/dialog";
import { LoadingButton } from "../../../ui/loading-button";
import { CertificateForm } from "./certificate.form";
import { CertificateInitialValues, CertificateValidationSchema } from "./certificate.validation";

const MUTATION = gql`
    mutation insert($object: certificate_insert_input!) {
        insert_certificate_one(object: $object) {
            ${CertificateFragment}
        }
    }
`;

type Props = {
    event_id: number;
    onFinish: (product: CertificateModel, is_new?: boolean) => void;
}

export const CertificateCreate = ({
    event_id,
    onFinish,
}: Props) => {
    const [insert, status] = useMutation(MUTATION)
    const app_dialog = useAppDialog();

    const onSubmit = async (values: any) => {
        try {
            const { data } = await insert({
                variables: {
                    object: {
                        ...values,
                        event_id,
                    }
                }
            });
            const ticket = data?.insert_certificate_one;
            if (!ticket) {
                return;
            }
            onFinish(ticket, true);
        } catch (e: any) {
            app_dialog.showError(e);
        }
    }
    return <Formik initialValues={CertificateInitialValues()}
        validationSchema={CertificateValidationSchema}
        onSubmit={onSubmit}>{() => <Form noValidate>
            <DialogContent>
                <CertificateForm />
            </DialogContent>
            <BasicDialogActions>
                <LoadingButton loading={status.loading}
                    color='primary'
                    type='submit'
                    size='large'
                    variant='contained'>
                    Create
                </LoadingButton>
            </BasicDialogActions>
        </Form>}
    </Formik>
}
