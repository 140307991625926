import { DialogContent } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { useUpdateByPk } from "../../../apollo/networking/use-update-by-pk";
import { useAppDialog } from "../../../app-dialog";
import { AttendeeFragment, AttendeeModel } from "../../../models/attendee.model";
import { BasicDialogActions } from "../../../ui/dialog";
import { LoadingButton } from "../../../ui/loading-button";
import { AttendeeForm } from "./attendee.form";
import { AttendeeInitialValues, AttendeeValidationSchema } from "./attendee.validation";


type Props = {
    event_id: number;
    item: AttendeeModel;
    onFinish: (product: AttendeeModel) => void;
}

export const AttendeeEdit = ({
    event_id,
    onFinish,
    item,
}: Props) => {
    const {
        onUpdate,
        loading,
    } = useUpdateByPk({
        entity_name: 'attendee',
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: item.id,
        }],
        fragment: AttendeeFragment,
    });

    const app_dialog = useAppDialog();

    const onSubmit = async (values: any) => {
        try {
            const result = await onUpdate({
                ...values,
            }) as AttendeeModel;
            if (!!result) {
                onFinish(result);
            }
        } catch (e: any) {
            app_dialog.showError(e);
        }
    }
    return <Formik initialValues={AttendeeInitialValues(item)}
        validationSchema={AttendeeValidationSchema}
        onSubmit={onSubmit}>{() => <Form noValidate>
            <DialogContent>
                <AttendeeForm event_id={event_id} />
                {/* {JSON.stringify(item.data)} */}
            </DialogContent>
            <BasicDialogActions>
                <LoadingButton loading={loading}
                    color='primary'
                    size='large'
                    type='submit'
                    variant='contained'>
                    Save
                </LoadingButton>
            </BasicDialogActions>
        </Form>}
    </Formik>
}
