import { gql, useMutation } from "@apollo/client"
import { Box, Button, createStyles, IconButton, InputAdornment, makeStyles, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@material-ui/core"
import MoreIcon from '@material-ui/icons/MoreHoriz'
import { RouteComponentProps } from "@reach/router"
import React from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { useKeysetPagination } from "../../apollo/networking/use-keyset-pagination"
import { useAppDialog } from "../../app-dialog"
import { useOrg } from "../../auth/use-org"
import { environment } from "../../environment"
import { AppLayout } from "../../layout/app-layout"
import { ReferrerFragment, ReferrerModel } from "../../models/referrer.model"
import { DeleteConfirmDialog } from "../../ui/delete-confirm.dialog"
import { LoadingDots } from "../../ui/loading-dots"
import { PageHeader } from "../../ui/page-header"
import { getFormattedCurrency } from "../../utils/get-formatted-currency"

type Props = {
    event_id: number;
}

const UpdateReferrerMutation = gql`
    mutation update_referrer($referrer_id: bigint!, $set: referrer_set_input!) {
        update_referrer_by_pk(
            pk_columns: {id: $referrer_id},
            _set: $set,
        ) {
            id
        }
    }
`;

export const OrgReferrerPage = ({
    event_id,
}: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const {
        org: {
            currency,
            username,
        }
    } = useOrg();
    const app_dialog = useAppDialog();
    const [confirm_delete, setConfirmDelete] = React.useState<ReferrerModel | false>(false);
    const [anchor_el, setAnchorEl] = React.useState<{
        anchor: HTMLButtonElement;
        item: ReferrerModel;
    } | undefined>();
    const [update, update_status] = useMutation(UpdateReferrerMutation)

    const {
        items,
        loading
    } = useKeysetPagination<ReferrerModel>({
        entity_name: 'referrer',
        fragment: ReferrerFragment,
        clause_outer: `$cursor: bigint!`,
        query_inner: `where: {
            id: {_gt: $cursor}
        }, order_by: {id: asc}`,
        variables: {
            cursor: 0,
        }
    })
    const onDelete = () => {
        if (!anchor_el) {
            return;
        }
        setConfirmDelete(anchor_el.item);
        setAnchorEl(undefined);
    }

    const onConfirmDelete = async () => {
        // if (!confirm_delete) {
        //     return;
        // }
        // try {
        //     await updateTicket({
        //         variables: {
        //             ticket_id: confirm_delete.id,
        //             set: {
        //                 deleted_at: 'now()',
        //             }
        //         },
        //     });
        //     setConfirmDelete(false);
        //     app_dialog.showSnackbar('Ticket deleted');
        //     refetch();
        // } catch (e: any) {
        //     app_dialog.showError(e);
        // }
    }
    return <AppLayout>
        {confirm_delete ? <DeleteConfirmDialog
            onCancel={() => setConfirmDelete(false)}
            onConfirm={onConfirmDelete}
            message={`Are you sure you want to remove referrer: ${confirm_delete.name}. This is not reversible and if there are attendees assigned to this ticket, they won't be able to access it anymore.`}
            deleting={update_status.loading}
            confirm_text='confirm'
        /> : null}
        <Menu
            open={Boolean(anchor_el)}
            anchorEl={anchor_el?.anchor}
            onClose={() => setAnchorEl(undefined)}
        >
            <MenuItem onClick={onDelete}><Typography color='secondary'>Delete</Typography></MenuItem>
        </Menu>
        <PageHeader title='Referrers' />
        <Box pb={2}>
            <Typography variant='body1'>Share referral program link</Typography>
            <TextField
                fullWidth
                margin='dense'
                value={`${environment.frontend_url}/r/${username}`}
                InputProps={{
                    endAdornment: <InputAdornment position='end'>
                        <CopyToClipboard
                            text={`${environment.frontend_url}/r/${username}`}
                            onCopy={() => {
                                app_dialog.showSnackbar('Link copied')
                            }}>
                            <Button size='small'>Copy</Button>
                        </CopyToClipboard>
                    </InputAdornment>
                }}
            />
        </Box>
        {loading ? <LoadingDots /> : null}
        <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow className='header'>
                        <TableCell />
                        <TableCell style={{ width: 50 }}>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Code</TableCell>
                        <TableCell align="right">Total Referrals</TableCell>
                        <TableCell align="right">Referred Amount</TableCell>
                        <TableCell align="right">Refunds</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, index) => (
                        <TableRow
                            key={item.id}
                            hover
                        >
                            <TableCell>
                                <div className={classes.buttons}>
                                    <Tooltip title='More Options'>
                                        <IconButton size='small' onClick={(e) => setAnchorEl({
                                            anchor: e.currentTarget,
                                            item,
                                        })}>
                                            <MoreIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </TableCell>
                            <TableCell style={{ width: 50 }}>
                                {item.id}
                            </TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.code}</TableCell>
                            <TableCell align='right'>{item.summary?.total_referrals || 0}</TableCell>
                            <TableCell align="right">{getFormattedCurrency(item.summary?.total_referral_amount || 0, currency)}</TableCell>
                            <TableCell align="right">{getFormattedCurrency(item.summary?.total_refund_amount || 0, currency)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </AppLayout>
}



const useStyles = makeStyles(theme => createStyles({
    table: {
        width: '100%',
        background: 'white',
        border: `1px solid ${theme.palette.divider}`,
        '& .header': {
            background: theme.palette.background.paper,
        }
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 50,
    },
}));