import { Typography } from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useAppDialog } from "../../app-dialog"
import firebase from '../../firebase'
import { EdContainer } from "../../ui/ed-container"
import { authErrors } from "./firebase-auth-errors"
import { SigninForm } from "./signin-form"

type Props = {

}

export const SigninPage = (props: RouteComponentProps<Props>) => {
    const [loading, setLoading] = React.useState(false);
    const app_dialog = useAppDialog();
    const onSubmit = async (values: {
        email: string;
        password: string;
    }) => {
        setLoading(true);
        try {
            await firebase.auth().signInWithEmailAndPassword(values.email, values.password);
        } catch (e: any) {
            const code = e.code.split('/');
            let message = 'Something went wrong on our end. Please contact help@edropin.com if this continues';
            if (code.length === 2) {
                const error = authErrors[code[1]];
                if (error) {
                    message = error;
                }
            }

            app_dialog.showDialog({
                title: 'Signin failed',
                message,
                buttons: [],
            })
        } finally {
            setLoading(false);
        }
    }

    return <EdContainer max_width='sm'>
        <Typography variant='h3' align='center'>CertifySimple</Typography>
        <Typography variant='h5' align='center'>Admin Portal</Typography>
        <SigninForm
            onSubmit={onSubmit}
        />
    </EdContainer>
}
