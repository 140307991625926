export type TicketModel = {
    id: number;
    created_at: string;
    type: string;
    name: string;
    description?: string;
    price: number;
    schema?: any;
    ui_schema?: any;
    sale_ends_at?: string;
    ask_agd_id?: boolean;
    is_agd_id_required?: boolean;
    offer_agd_discount?: boolean;
    agd_discount_price?: number;
    offer_referral_discount?: boolean;
    referral_discount_price?: number;
    is_inventory: boolean;
    total_inventory?: number;
    is_visible: boolean;
    discounts?: {
        code: string;
        price: number;
    }[];
    ticket_tally?: {
        id: number;
        total_sold?: number;
    }
}

export const TicketFragment = `
    id
    created_at
    type
    name
    description
    price
    schema
    ui_schema
    sale_ends_at
    offer_agd_discount
    agd_discount_price
    offer_referral_discount
    referral_discount_price
    discounts
    ask_agd_id
    is_agd_id_required
    is_inventory
    total_inventory
    is_visible
    ticket_tally {
        id
        total_sold
    }
`;
