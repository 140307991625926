import { gql, useMutation } from "@apollo/client";
import { Button, createStyles, DialogContent, makeStyles, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import React from "react";
import { CSVReader } from "react-papaparse";
import { useList } from "../../apollo/networking";
import { useAppDialog } from "../../app-dialog";
import { TicketModel } from "../../models/ticket.model";
import { BasicDialog, BasicDialogActions, BasicDialogTitle } from "../../ui/dialog";
import { LoadingButton } from "../../ui/loading-button";
import { LoadingDots } from "../../ui/loading-dots";
import { getFormattedCurrency } from "../../utils/get-formatted-currency";


type Props = {
    onRefresh: () => void;
    event_id: number;
}

const ImportMutation = gql`
    mutation import_attendees($objects: [attendee_insert_input!]!) {
        insert_attendee(objects: $objects) {
            affected_rows
        }
    }
`;

export const AttendeeImportCsv = ({
    onRefresh,
    event_id,
}: Props) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const csv_ref = React.createRef<CSVReader>();
    const [show_dialog, setShowDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [ticket_id, setTicketId] = React.useState<number>();
    const [attendees, setAttendees] = React.useState<{
        name: string;
        email: string;
        agd_id?: string;
    }[]>([]);
    const [_import, import_status] = useMutation(ImportMutation);
    const {
        items: tickets,
    } = useList<TicketModel>({
        entity_name: 'ticket',
        fragment: `id name price`,
        query_inner: `where: {event_id: {_eq: $event_id}}`,
        clause_outer: `$event_id: bigint!`,
        variables: {
            event_id,
        }
    });

    const startImport = (e: any) => {
        setAttendees([]);
        setShowDialog(true)
        setLoading(true);
        if (csv_ref.current) {
            csv_ref.current.open(e)
        }
    }

    const removeFile = () => {
        if (csv_ref.current) {
            csv_ref.current.removeFile();
        }
        setShowDialog(false);
    }

    const handleOnFileLoad = (data: any[]) => {
        if (!Array.isArray(data)) {
            removeFile();
        }
        if (data.length === 0) {
            setShowDialog(false);
            return;
        }
        data.forEach(_item => {
            if (!_item.data) {
                return;
            }
            const item = _item.data as any;
            if (!!item.email && typeof item.email === 'string'
                && !!item.name && typeof item.name === 'string') {
                setAttendees(a => [...a, {
                    email: item.email,
                    name: item.name,
                    agd_id: item.agd_id,
                }])
            }
        });
        setLoading(false);
    }

    const handleOnError = () => {
        setLoading(false);
    }

    const doImport = async () => {
        try {
            const { data } = await _import({
                variables: {
                    objects: attendees.map(at => ({
                        ...at,
                        ticket_id,
                        is_email_enabled: false,
                    }))
                }
            });
            onRefresh();
            removeFile();
            app_dialog.showSnackbar(`Imported ${data?.insert_attendee?.affected_rows} records`)
        } catch (e: any) {
            app_dialog.showError(e);
        }
    }

    if (tickets.length === 0) {
        return null;
    }

    return <>
        <CSVReader
            ref={csv_ref}
            onFileLoad={handleOnFileLoad}
            config={{
                header: true,
            }}
            onError={handleOnError}
            noClick
            noDrag
        >
            {({ file }: any) => (
                <>
                    <Button
                        variant='outlined'
                        size='small'
                        onClick={startImport}>Import .csv</Button>
                    {show_dialog ? <BasicDialog
                        id='csv-reader'
                        onClose={removeFile}
                        open={true}>
                        <BasicDialogTitle
                            title='Import Attendees'
                            onClose={removeFile}
                        />
                        {file ? <DialogContent>
                            <Typography variant='body1'>
                                <strong>
                                    Found {attendees.length} records
                                </strong>
                            </Typography>
                            <Typography gutterBottom variant='body2'>
                                Filename: {file?.name}
                            </Typography>
                            {loading && <LoadingDots />}
                            <TextField
                                label='Select Ticket'
                                margin='normal'
                                select
                                value={ticket_id}
                                onChange={e => setTicketId(+e.target.value)}
                                fullWidth
                            >{tickets.map(i => <MenuItem key={i.id} value={i.id}>
                                {i.name} - {getFormattedCurrency(i.price)}
                            </MenuItem>)}
                            </TextField>
                            <TableContainer>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow className='header'>
                                            <TableCell>Id</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>AGD ID</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {attendees.map((attendee, idx) => <TableRow
                                            key={idx}>
                                            <TableCell>
                                                {idx + 1}
                                            </TableCell>
                                            <TableCell>
                                                {attendee.name}
                                            </TableCell>
                                            <TableCell>
                                                {attendee.email}
                                            </TableCell>
                                            <TableCell>
                                                {attendee.agd_id}
                                            </TableCell>
                                        </TableRow>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent> : null}
                        <BasicDialogActions>
                            <LoadingButton
                                loading={import_status.loading}
                                disabled={!ticket_id}
                                color='secondary'
                                variant='contained'
                                onClick={doImport}
                            >{!ticket_id ? 'Select a ticket'
                                : `Import ${attendees.length} Attendees`}</LoadingButton>
                        </BasicDialogActions>
                    </BasicDialog> : null}
                </>
            )}
        </CSVReader>
    </>
}

const useStyles = makeStyles(theme => createStyles({
    table: {
        width: '100%',
        minWidth: 700,
        background: 'white',
        border: `1px solid ${theme.palette.divider}`,
        '& .header': {
            background: theme.palette.background.paper,
        }
    },
}))