import { createStyles, FormHelperText, makeStyles } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import React from 'react';
import { getOptionsForNode, NodeType, SchemaProperty, updateOptionsForNode } from './models';

type Props = {
	node_type: NodeType;
	property: SchemaProperty;
	onUpdateNode: (json: SchemaProperty) => void;
}

export const NodeOptionEditorComponent = ({
	node_type,
	property,
	onUpdateNode,
}: Props) => {
	const classes = useStyles({});

	const chips = getOptionsForNode(node_type, property)

	const handleAddEnum = (chip: string) => {
		if (chips.indexOf(chip) === -1) {
			onUpdateNode(updateOptionsForNode(node_type,
				property,
				[...chips, chip]
			)
			);
		}
	}

	const handleRemoveEnum = (chip: string) => {
		onUpdateNode(
			updateOptionsForNode(
				node_type,
				property,
				chips.filter(c => c !== chip)
			)
		);
	}
	return <div className={classes.enum}>
		<ChipInput
			value={chips}
			variant='outlined'
			onAdd={(chip) => handleAddEnum(chip)}
			label='Enter Options'
			onDelete={(chip) => handleRemoveEnum(chip)}
		/>
		<FormHelperText>Press the enter/return key to create a new option</FormHelperText>
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	enum: {
		marginTop: theme.spacing(2),
	}
}));