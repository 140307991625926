import * as yup from 'yup';
import { EventNotificationModel } from '../../../models/event-notification.model';
import { EventResourceModel } from '../../../models/event-resource.model';

export const ResourceInitialValues = (notification?: EventResourceModel) => ({
    name: notification?.name || '',
    link: notification?.link || '',
})

export const ResourceValidationSchema = yup.object().shape({
    name: yup.string().required('Resource name is required'),
    link: yup.string().url('Invalid link').required('Resource link is required'),
})
