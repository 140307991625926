import { Box, Button, createStyles, makeStyles } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useGetByPk } from "../../apollo/networking/use-get-by-pk";
import { ImageSize, LazyImage } from "../../image-upload";
import { AppLayout } from '../../layout/app-layout';
import { EventFragment, EventModel } from "../../models/event.model";
import { LoadingDots } from "../../ui/loading-dots";
import { PageHeader } from "../../ui/page-header";
import { UnstyledLink } from "../../ui/unstyled-link";
import { getTimeRangeTimezone } from "../../utils/time-range";
import { EventShare } from "../events/event-share";
import { EventBody } from "./event-body";
import { EventOrganizer } from "./event-organizer";

type Props = {
    event_id: number;
}

export const EventPreviewPage = ({
    event_id,
}: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const [show_share, setShowShare] = React.useState(false);

    const {
        loading,
        entity: event,
    } = useGetByPk<EventModel>({
        entity_name: 'event',
        fragment: EventFragment,
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: event_id,
        }],
    });


    return <AppLayout>
        {loading ? <LoadingDots /> : null}
        {event ? <>
            {show_share ? <EventShare
                name={event.title}
                url={event.url}
                is_live={event.is_live}
                event_id={event.id}
                onClose={() => setShowShare(false)}
            /> : null}
            <PageHeader title={event.title} subtitle={
                getTimeRangeTimezone(event.starts_at, event.ends_at, event.timezone)
            } />
            <EventOrganizer />
            {(event.feature_images || []).length > 0 ? <Carousel
                autoPlay
                interval={6000}
                infiniteLoop
                className={classes.carousel}
                showStatus={false}>
                {event.feature_images?.map(image => <div className='image'
                    key={image}><LazyImage
                        image_key={image}
                        image_opt={{
                            size: ImageSize.xlarge,
                            aspect_ratio: 'none',
                        }}
                    />
                </div>)}
            </Carousel> : null}
            <Box pb={2}>
                <UnstyledLink to={`/events/${event_id}`}>
                    <Button variant='contained'
                        endIcon={<ChevronRight />}
                        size='small'>Edit event</Button>
                </UnstyledLink>
            </Box>
            <EventBody
                content={event.content} />
        </> : null
        }
    </AppLayout >
}


const useStyles = makeStyles(theme => createStyles({
    footer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        boxShadow: theme.shadows[2],
        padding: theme.spacing(2, 0),
        zIndex: 99,
        '& .content': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .button:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        }
    },
    carousel: {
        '& .image': {
            width: '100%',
            paddingTop: '50%',
            height: 0,
            backgroundColor: 'black',
            position: 'relative',
            '& img': {
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                objectFit: 'contain',
            }
        }
    }
}))