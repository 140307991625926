import { RouteComponentProps } from '@reach/router';
import * as queryString from 'query-string';
import React from 'react';
import { useOrg } from '../../auth/use-org';
import { AppLayout } from '../../layout/app-layout';
import { useStripeConnect } from './hooks';
import { StripeConnectComponent } from './stripe-connect.component';

type Props = {
}

export const StripeConnectPage = ({ location }: RouteComponentProps) => {
	const { org_id } = useOrg();
	const query_params = queryString.parse(location ? location.search : '');
	const [local_error, setError] = React.useState<string | undefined>();
	const {
		stripe_id,
		onConnect,
		loading,
		error: connect_error,
		success,
	} = useStripeConnect();

	const { code, state } = query_params;
	React.useEffect(() => {
		if (!!stripe_id) {
			return;
		}
		if (!org_id || !code || !state) {
			setError('Something went wrong with the Stripe setup. Failed to connect.');
			return;
		}
		if (org_id !== parseInt(state as string, 10)) {
			setError('Invalid code. Please make sure you do not log out while setting up Stripe.')
			return;
		}
		onConnect(code as string);
	}, [stripe_id, org_id, code, state]);

	let error: string | undefined = undefined;

	if (!loading && !success) {
		error = 'Failed to save Stripe Connection to your shop. Try again. If this continues, contact us at help@edropin.com';
	}
	if (connect_error) {
		error = 'Something went wrong when linking your Stripe account.';
	}
	if (local_error) {
		error = local_error;
	}

	return <AppLayout>
		<StripeConnectComponent
			error={error}
			loading={loading}
			success={success}
			stripe_id={stripe_id}
		/>
	</AppLayout>
}