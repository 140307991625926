export type AttendeeMoveTicketModel = {
    id: number;
    name: string;
    is_inventory: boolean;
    total_inventory?: number;
    ticket_tally?: {
        id: number;
        total_sold?: number;
    }
}

export type AttendeeMoveEventModel = {
    id: number;
    title: string;
    starts_at: string;
    ends_at: string;
    timezone: string;
    tickets: AttendeeMoveTicketModel[];
}

export const AttendeeMoveEventFragment = `
    id
    title
    starts_at
    ends_at
    timezone
    tickets {
        id
        name
        is_inventory
        total_inventory
        ticket_tally {
            id
            total_sold
        }
    }
`