import React from "react";
import { AttendeeModel } from "../../../models/attendee.model";
import { TicketModel } from "../../../models/ticket.model";
import { BasicDialog, BasicDialogTitle } from "../../../ui/dialog";
import { AttendeeCreate } from "./attendee-create";
import { AttendeeEdit } from "./attendee-edit";


type Props = {
    event_id: number;
    action: 'edit' | 'create';
    item?: AttendeeModel;
    onClose: () => void;
    onFinish: (event: AttendeeModel, is_new?: boolean) => void;
}

export const AttendeeEditorDialog = ({
    event_id,
    action,
    onClose,
    onFinish,
    item,
}: Props) => {
    return <BasicDialog
        open={true}
        id='update-attendee'
        onClose={onClose}>
        <BasicDialogTitle onClose={onClose} title={action === 'create' ? 'Create attendee' : 'Edit attendee'} />
        {item
            ? <AttendeeEdit
                event_id={event_id}
                onFinish={onFinish}
                item={item} />
            : <AttendeeCreate
                event_id={event_id}
                onFinish={onFinish}
            />}
    </BasicDialog>
}
