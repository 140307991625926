import { Box, Button, createStyles, FormHelperText, makeStyles, Typography } from '@material-ui/core';
import ExternalIcon from '@material-ui/icons/Launch';
import React from 'react';
import { useOrg } from '../../auth/use-org';
import { environment } from '../../environment';
import { SupportedCardsComponent } from '../../ui/supported-cards';

type Props = {
	stripe_id?: string;
	is_dirty?: boolean;
}

export const StripeAuthorization = ({
	stripe_id,
	is_dirty,
}: Props) => {
	const classes = useStyles({});
	const { org_id } = useOrg();

	const link = React.useMemo(() => {
		const location = `${window.location.protocol}//${window.location.host}`;
		return `https://connect.stripe.com/oauth/authorize?response_type=code&state=${org_id}` +
			`&client_id=${environment.stripe_connect_key}&scope=read_write&` +
			`redirect_uri=${location}/stripe`;
	}, [])

	return <Box mt={2} mb={2}>
		<Typography variant='h6'>Connect Stripe</Typography>
		<Typography variant='body1' gutterBottom>
			<strong>edropin</strong> utilizes Stripe to process all your payments securely.
			All money is directly transferred to your bank seamlessly.
		</Typography>
		<SupportedCardsComponent />
		<Button
			component='a'
			href={link}
			fullWidth
			variant='contained'
			color='secondary'
			disabled={is_dirty || !!stripe_id}
			className={classes.root}
			size='large'
			endIcon={<ExternalIcon />}>{!!stripe_id ? 'Connected' : 'Connect Stripe'}</Button>
		{!!stripe_id ? <Typography variant='body1' color='primary'>Stripe account is connected. <strong>{stripe_id}</strong></Typography>
			: is_dirty ? <FormHelperText
				error>Please save your billing information before connecting to Stripe</FormHelperText>
				: null}
	</Box>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
		cursor: 'pointer',
		'& img': {
			maxWidth: '100%',
		},
	}
}));