import { gql, useMutation } from "@apollo/client";
import { useAppDialog } from "../../app-dialog";
import { AttendeeFragment } from "../../models/attendee.model";

const UpdateMutation = gql`
    mutation update_attendee($attendee_id: bigint!, $set: attendee_set_input!) {
        update_attendee_by_pk(
            pk_columns: {id: $attendee_id},
            _set: $set,
        ) {
            ${AttendeeFragment}
        }
    }
`;

export const useUpdateAttendee = () => {
    const [
        update,
        update_result
    ] = useMutation(UpdateMutation);
    const {
        showError,
        showSnackbar
    } = useAppDialog();

    const updateAttendee = async (attendee_id: number, set: any) => {
        try {
            const result = await update({
                variables: {
                    attendee_id,
                    set,
                }
            });
            return result.data?.update_attendee_by_pk;
        } catch (e: any) {
            showError(e);
            return null;
        }
    }

    const deleteAttendee = async (attendee_id: number) => {
        const result = await updateAttendee(attendee_id, {
            deleted_at: 'now()',
        })
        if (!!result) {
            showSnackbar('Attendee deleted');
        }
        return result;
    }

    return {
        updateAttendee,
        deleteAttendee,
        updating: update_result.loading,
    }
}