import * as yup from 'yup';
import { TicketModel } from "../../../models/ticket.model";

export const TicketInitialValues = (ticket?: TicketModel) => ({
    name: ticket?.name || '',
    description: ticket?.description || '',
    price: (ticket?.price || 0) / 100,
    sale_ends_at: ticket?.sale_ends_at || null,
    offer_agd_discount: ticket?.offer_agd_discount || false,
    agd_discount_price: (ticket?.agd_discount_price || 0) / 100,
    offer_referral_discount: ticket?.offer_referral_discount || false,
    referral_discount_price: (ticket?.referral_discount_price || 0) / 100,
    is_visible: ticket ? ticket.is_visible : true,
    discounts: (ticket?.discounts || []).map(d => ({
        ...d,
        price: (d.price || 0) / 100
    })),
    ask_agd_id: ticket ? ticket.ask_agd_id : true,
    is_agd_id_required: ticket?.is_agd_id_required || false,
    is_inventory: ticket?.is_inventory || false,
    total_inventory: ticket?.total_inventory || 0,
})

export const TicketValidationSchema = yup.object().shape({
    name: yup.string().required('Please provide a ticket name'),
    description: yup.string().optional(),
    price: yup.number().min(0, 'Price must be 0 or more.').required('Price is required (Free = $0)'),
    sale_ends_at: yup.date().nullable(),
    offer_agd_discount: yup.boolean(),
    offer_referral_discount: yup.boolean(),
    agd_discount_price: yup.number()
        .when('offer_agd_discount', {
            is: true,
            then: yup.number().min(0, 'Price must be 0 or more.')
                .required('Price is required (Free = $0)'),
        }),
    referral_discount_price: yup.number()
        .when('offer_referral_discount', {
            is: true,
            then: yup.number().min(0, 'Price must be 0 or more.')
                .required('Price is required (Free = $0)'),
        }),
    discounts: yup.array(yup.object({
        code: yup.string().required('Discount code is required'),
        price: yup.number().min(0, 'Price must be 0 or more.')
            .required('Price is required (Free = $0)'),
    })),
    is_visible: yup.boolean(),
    total_inventory: yup.number().integer().min(0),
})

export const getTicketSubmission = (values: any) => ({
    ...values,
    price: Math.floor((values.price || 0) * 100),
    agd_discount_price: Math.floor((values.agd_discount_price || 0) * 100),
    referral_discount_price: Math.floor((values.referral_discount_price || 0) * 100),
    discounts: (values.discounts || []).map((d: any) => ({
        ...d,
        price: Math.floor((d.price || 0) * 100)
    }))
})