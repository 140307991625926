import { createStyles, DialogContent, makeStyles, Typography } from "@material-ui/core"
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React from "react"
import { Currency } from "../../../models/currency";
import { BasicDialog, BasicDialogActions, BasicDialogTitle } from "../../../ui/dialog";
import { LoadingButton } from "../../../ui/loading-button";
import { getFormattedCurrency } from "../../../utils/get-formatted-currency";
import * as yup from 'yup';

type Props = {
    onClose: () => void;
    max_refund: number;
    currency: Currency;
    onRefund: (amount: number) => Promise<boolean>;
    loading?: boolean;
}

export const RefundAmountDialog = ({
    onClose,
    max_refund,
    currency,
    onRefund,
    loading,
}: Props) => {
    const classes = useStyles({});

    const onSubmit = async (values: any) => {
        const amount = Math.floor(values.amount * 100);
        if (await onRefund(amount)) {
            onClose();
        }
    }

    return <BasicDialog open={true} id='refund-amount' onClose={onClose}>
        <BasicDialogTitle title='Refund Amount' onClose={onClose} />
        <Formik
            initialValues={{
                amount: 0,
                confirm_amount: 0,
            }}
            validationSchema={yup.object().shape({
                amount: yup.number()
                    .min(.01, 'Must be greater than $0.01')
                    .max(max_refund / 100, `Must be less than ${getFormattedCurrency(max_refund, currency)}`),
                confirm_amount: yup.number()
                    .oneOf([yup.ref('amount'), null], 'Amounts must match')
                    .required('Please re-type the amount to refund')
            })}
            onSubmit={onSubmit}>{({ values }) => <Form>
                <DialogContent>
                    <Typography variant='body1'>How much would you like to refund? <strong>Max: {getFormattedCurrency(max_refund, currency)}</strong></Typography>
                    <Field
                        component={TextField}
                        name='amount'
                        label='Amount to refund'
                        type='number'
                        margin='normal'
                        fullWidth
                        helperText='How much would you like to refund'
                    />
                    <Field
                        component={TextField}
                        name='confirm_amount'
                        label='Confirm the amount to refund'
                        type='number'
                        fullWidth
                        margin='normal'
                        helperText='Re-type the amount to refund'
                    />
                </DialogContent>
                <BasicDialogActions>
                    <LoadingButton
                        type='submit'
                        color='secondary'
                        loading={loading}
                        variant='contained'>
                        Refund {getFormattedCurrency(values.amount * 100, currency)}
                    </LoadingButton>
                </BasicDialogActions>

            </Form>
            }</Formik>
    </BasicDialog>
}

const useStyles = makeStyles(theme => createStyles({

}))