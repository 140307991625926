import moment from 'moment';
import * as yup from 'yup';
import { DiplomaModel } from '../../../models/diploma.model';

export const DiplomaInitialValues = (cert?: DiplomaModel) => ({
    title: cert?.title || '',
    cert_json: cert?.cert_json || {
        subject: '',
        date: '',
        signature: {
            url: '',
            name: '',
            role: '',
        },
        certificate_of: 'completion',
        success_line: 'has successfully completed this course'
    },
})

export const DiplomaValidationSchema = yup.object().shape({
    title: yup.string().required('Please provide a cert title (course name)'),
    cert_json: yup.object().required('Please provide a cert json'),
})

export const validateDiploma = (values: any) => {
    return {
        ...values,
        cert_json: {
            ...(values.cert_json || {}),
            date: values.cert_json?.date ? moment(values.cert_json.date).format('LL') : '',
            version: '1_classic'
        },
    }
}