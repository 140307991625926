import { AttendeeFragment, AttendeeModel } from "./attendee.model";

export interface AttendeeDiplomaModel extends AttendeeModel {
    attendee_diplomas: {
        id: number;
        updated_at: string;
        deleted_at?: string;
        secret: string;
    }[];
}

export const AttendeeDiplomaFragment = `
    ${AttendeeFragment}
    attendee_diplomas(where: {
        diploma_id: {_eq: $diploma_id}
    }) {
        id
        updated_at
        deleted_at
        secret
    }
`;