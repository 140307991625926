import { Button, Container, createStyles, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { LoadingDots } from '../../ui/loading-dots';
import { SupportedCardsComponent } from '../../ui/supported-cards';
import { UnstyledLink } from '../../ui/unstyled-link';
import { StripeAuthorization } from './stripe-authorization';

type Props = {
	loading?: boolean;
	error?: string;
	success?: boolean;
	stripe_id?: string;
}

export const StripeConnectComponent = ({
	loading,
	error,
	success,
	stripe_id
}: Props) => {
	const classes = useStyles({});

	return <Container maxWidth='md' className={classes.root}>
		<Typography gutterBottom variant='h2'>Connect Stripe</Typography>
		{loading ? <LoadingDots /> : error ? <>
			<Typography variant='h6' color='secondary'>
				{error}
			</Typography>
			<StripeAuthorization />
		</> : null}
		{success ? <>
			<Typography align='center' variant='h6' color='primary'>
				Awesome, your Stripe account is now linked with <strong>edropin.</strong>
			</Typography>
			<Typography variant='body1'>
				Stripe ID {stripe_id}
			</Typography>
			<SupportedCardsComponent />
		</> : null}
		<UnstyledLink to='/edit'>
			<Button
				variant='outlined'
			>Back to Org Editor</Button>
		</UnstyledLink>
		<UnstyledLink to='/'>
			<Button>Manage events</Button>
		</UnstyledLink>
	</Container>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
		paddingTop: theme.spacing(6),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	shopHeader: {
		paddingTop: theme.spacing(6),
	},
	nextButtons: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		padding: theme.spacing(2),
	}
}));