import { useStorage } from "../../utils/use-storage";

const columns_key = 'attendance_attendee_cols';

export type CertAttendeeCol = ('email' | 'ticket' | 'agd_id');
export type CertAttendeeCols = CertAttendeeCol[];

const columns: {
    id: CertAttendeeCol;
    label: string;
}[] = [{
    id: 'email',
    label: 'Email',
},{
    id: 'ticket',
    label: 'Ticket',
},{
    id: 'agd_id',
    label: 'AGD ID',
},]

export const useCertAttendeeColumns = () => {
    const {
        getKey,
        setKey,
    } = useStorage();
    const cols = getKey(columns_key, []) as CertAttendeeCols;
    const setCols = (v: CertAttendeeCols) => setKey(columns_key, v);

    const showColumn = (id: CertAttendeeCol) => {
        return cols.indexOf(id) > -1;
    }

    const toggleColumn = (id: CertAttendeeCol) => {
        if (!showColumn(id)) {
            setCols([
                ...cols, id,
            ]);
        } else {
            setCols(cols.filter(c => c !== id));
        }
    }

    return {
        cols,
        columns,
        setCols,
        showColumn,
        toggleColumn,
    };
}