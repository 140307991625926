import { Button, createStyles, IconButton, makeStyles, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import MoreIcon from '@material-ui/icons/MoreHoriz';
import AttendeeIcon from '@material-ui/icons/People';
import QuestionIcon from '@material-ui/icons/QuestionAnswer';
import { Link, useLocation } from "@reach/router";
import moment from "moment";
import React from "react";
import { useList } from "../../apollo/networking";
import { useOrg } from "../../auth/use-org";
import { TicketFragment, TicketModel } from "../../models/ticket.model";
import { LoadingDots } from "../../ui/loading-dots";
import { UnstyledLink } from "../../ui/unstyled-link";
import { getFormattedCurrency } from "../../utils/get-formatted-currency";
import { TicketEditorDialog } from "./ticket-editor";
import * as qs from 'query-string';
import { QuestionnaireEditorDialog } from "../questionnaire-editor";
import { gql, useMutation } from "@apollo/client";
import { useAppDialog } from "../../app-dialog";
import { sortItemsByIdList } from "../../utils/sort-items-by-id-list";
import { EventModel } from "../../models/event.model";
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import DownIcon from '@material-ui/icons/KeyboardArrowDown'
import { arrayMove } from "../../utils/array-move";
import { useUpdateByPk } from "../../apollo/networking/use-update-by-pk";
import { DeleteConfirmDialog } from "../../ui/delete-confirm.dialog";

type Props = {
    event: EventModel;
}

const MutationUpdateTicket = gql`
    mutation update_ticket($ticket_id: bigint!, $set: ticket_set_input!) {
        update_ticket_by_pk(
            pk_columns: {id: $ticket_id},
            _set: $set,
        ) {
            ${TicketFragment}
        }
    }
`;

export const TicketList = ({
    event,
}: Props) => {
    const classes = useStyles({});
    const location = useLocation();
    const params = qs.parse(location.search || '');
    const add = params?.add;
    const app_dialog = useAppDialog();
    const [confirm_delete, setConfirmDelete] = React.useState<TicketModel | false>(false);
    const [q_ticket, setQTicket] = React.useState<TicketModel | undefined>();
    const [anchor_el, setAnchorEl] = React.useState<{
        anchor: HTMLButtonElement;
        item: TicketModel;
    } | undefined>();
    const {
        org: {
            currency,
        }
    } = useOrg();
    const [updateTicket, update_ticket_status] = useMutation(MutationUpdateTicket);
    const [editor, setEditor] = React.useState<{
        action: 'edit' | 'create';
        item?: TicketModel;
    } | undefined>();
    const {
        items: _items,
        loading,
        refetch,
        error,
    } = useList<TicketModel>({
        entity_name: 'ticket',
        fragment: TicketFragment,
        clause_outer: `$event_id: bigint!`,
        variables: {
            event_id: event.id,
        },
        query_inner: `
            where: {event_id: {_eq: $event_id}},
            order_by: {id: asc}
        `
    });
    const { onUpdate } = useUpdateByPk({
        entity_name: 'event',
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: event.id,
        }],
        fragment: `id ticket_order`
    })

    const items = React.useMemo(() => {
        return sortItemsByIdList(event.ticket_order || [], _items);
    }, [_items, event.ticket_order])

    React.useEffect(() => {
        if (!!add) {
            setEditor({ action: 'create' });
        }
    }, [add])

    const onFinish = (_: TicketModel, is_new?: boolean) => {
        if (is_new) {
            refetch();
        }
        setEditor(undefined);
    }

    const onEdit = (item?: TicketModel) => () => {
        if (!item) {
            return;
        }
        setAnchorEl(undefined);
        setEditor({
            action: 'edit',
            item,
        })
    }

    const onUpdateQuestionnaire = async ({ schema, ui_schema }: any) => {
        if (!q_ticket) {
            return;
        }
        try {
            const { data } = await updateTicket({
                variables: {
                    ticket_id: q_ticket.id,
                    set: {
                        schema,
                        ui_schema,
                    },
                },
            });
            if (data?.update_ticket_by_pk?.id) {
                app_dialog.showSnackbar('Ticket questions updated');
                setQTicket(undefined);
            }
        } catch (e: any) {
            app_dialog.showError(e);
        }
    }

    const moveTicket = (index: number, dir: 'up' | 'down') => {
        if (dir === 'up' && index === 0) {
            return;
        }
        if (dir === 'down' && index === items.length - 1) {
            return;
        }
        const new_ticket_order = arrayMove(
            items.map(i => i.id),
            index,
            index + (dir === 'up' ? -1 : 1));
        onUpdate({
            ticket_order: new_ticket_order,
        })
    }
    const onDelete = () => {
        if (!anchor_el) {
            return;
        }
        setConfirmDelete(anchor_el.item);
        setAnchorEl(undefined);
    }

    const onConfirmDelete = async () => {
        if (!confirm_delete) {
            return;
        }
        try {
            await updateTicket({
                variables: {
                    ticket_id: confirm_delete.id,
                    set: {
                        deleted_at: 'now()',
                    }
                },
            });
            setConfirmDelete(false);
            app_dialog.showSnackbar('Ticket deleted');
            refetch();
        } catch (e: any) {
            app_dialog.showError(e);
        }
    }

    return <>
        {confirm_delete ? <DeleteConfirmDialog
            onCancel={() => setConfirmDelete(false)}
            onConfirm={onConfirmDelete}
            message={`Are you sure you want to delete the ticket: ${confirm_delete.name}. This is not reversible and if there are attendees assigned to this ticket, they won't be able to access it anymore.`}
            deleting={update_ticket_status.loading}
            confirm_text='confirm'
        /> : null}
        <Menu
            open={Boolean(anchor_el)}
            anchorEl={anchor_el?.anchor}
            onClose={() => setAnchorEl(undefined)}
        >
            <MenuItem onClick={onEdit(anchor_el?.item)}>Edit</MenuItem>
            <MenuItem onClick={onDelete}><Typography color='secondary'>Delete</Typography></MenuItem>
        </Menu>
        {editor ? <TicketEditorDialog
            action={editor.action}
            item={editor.item}
            event_id={event.id}
            onFinish={onFinish}
            onClose={() => setEditor(undefined)} /> : null}
        {q_ticket ? <QuestionnaireEditorDialog
            questionnaire={{
                schema: q_ticket.schema,
                ui_schema: q_ticket.ui_schema,
            }}
            loading={update_ticket_status.loading}
            onFinish={onUpdateQuestionnaire}
            onClose={() => setQTicket(undefined)}
        /> : null}
        <Button
            variant='outlined'
            color='primary'
            className={classes.addButton}
            onClick={() => setEditor({
                action: 'create',
            })}>+ Add Ticket</Button>
        <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow className='header'>
                        <TableCell />
                        <TableCell style={{ width: 50 }}>
                            ID</TableCell>
                        <TableCell >Name</TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">AGD Price</TableCell>
                        <TableCell align="right">Referral Price</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>Sale Ends</TableCell>
                        <TableCell >Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, index) => (
                        <TableRow
                            key={item.id}
                            hover
                        >
                            <TableCell>
                                <div className={classes.buttons}>
                                    <IconButton size='small'
                                        disabled={index === 0}
                                        onClick={() => moveTicket(index, 'up')}>
                                        <UpIcon />
                                    </IconButton>
                                    <IconButton
                                        disabled={index === items.length - 1}
                                        onClick={() => moveTicket(index, 'down')}
                                        size='small'>
                                        <DownIcon />
                                    </IconButton>
                                    <Tooltip title='View attendees'>
                                        <UnstyledLink to={`/events/${event.id}/attendees?filter=ticket_id:${item.id}`}>
                                            <IconButton size='small'>
                                                <AttendeeIcon fontSize='small' />
                                            </IconButton>
                                        </UnstyledLink>
                                    </Tooltip>
                                    <Tooltip title='Custom Questions'>
                                        <IconButton size='small' onClick={(e) => setQTicket(item)}>
                                            <QuestionIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='More Options'>
                                        <IconButton size='small' onClick={(e) => setAnchorEl({
                                            anchor: e.currentTarget,
                                            item,
                                        })}>
                                            <MoreIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </TableCell>
                            <TableCell style={{ width: 50 }}>
                                {item.id}
                            </TableCell>
                            <TableCell><Link to={`/events/${event.id}/sales`}>{item.name}</Link> </TableCell>
                            <TableCell align="right">{getFormattedCurrency(item.price || 0, currency)}</TableCell>
                            <TableCell align="right">{item.offer_agd_discount ? getFormattedCurrency(item.agd_discount_price || 0, currency) : '--'}</TableCell>
                            <TableCell align="right">{item.offer_referral_discount ? getFormattedCurrency(item.referral_discount_price || 0, currency) : '--'}</TableCell>
                            <TableCell >{item.ticket_tally?.total_sold || 0}/{item.is_inventory ? item.total_inventory || 0 : '∞'}</TableCell>
                            <TableCell >{item.sale_ends_at ? moment(item.sale_ends_at).calendar() : 'End of Event'}</TableCell>
                            <TableCell >{item.description}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        {loading ? <LoadingDots /> : null}
    </>
}

const useStyles = makeStyles(theme => createStyles({
    table: {
        width: '100%',
        background: 'white',
        border: `1px solid ${theme.palette.divider}`,
        '& .header': {
            background: theme.palette.background.paper,
        }
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 100,
    },
    addButton: {
        marginBottom: theme.spacing(2),
    }
}))