import { gql, useMutation } from "@apollo/client";
import { Box, Button, createStyles, Divider, Grid, InputAdornment, Link, makeStyles, Menu, MenuItem, TextField, Typography } from "@material-ui/core";
import CalendarIcon from '@material-ui/icons/ViewAgenda';
import { useNavigate } from "@reach/router";
import moment from "moment";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { usePaginate } from "../../apollo/networking";
import { useAppDialog } from "../../app-dialog";
import { useOrg } from "../../auth/use-org";
import { environment } from "../../environment";
import { EventFragment, EventModel } from "../../models/event.model";
import { DeleteConfirmDialog } from "../../ui/delete-confirm.dialog";
import { EmptyListComponent } from "../../ui/empty-list.component";
import { useStorage } from "../../utils/use-storage";
import { EventEditorDialog } from "./event-editor";
import { EventListItem } from "./event-list-item";
import { EventManageSidebar } from "./event-manage-sidebar";
import { EventShare } from "./event-share";
import { useEventDuplicate } from "./use-event-duplicate";


type Props = {

}

const UpdateEventMutation = gql`
    mutation update_event($event_id: bigint!, $set: event_set_input!) {
        update_event_by_pk(pk_columns: {
            id: $event_id,
        }, _set: $set) {
            id
            deleted_at
        }
    }
`;

const show_direction = 'show_direction';

export const EventList = (props: Props) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const {
        org: {
            name,
            currency,
            username,
            is_referral_program_enabled,
        }
    } = useOrg();
    const {
        duplicate,
    } = useEventDuplicate();
    const navigate = useNavigate();
    const [updateEvent, update_status] = useMutation(UpdateEventMutation);
    const { getKey, setKey } = useStorage();
    const show_future = getKey(show_direction, true);
    const setShowFuture = (v: boolean) => setKey(show_direction, v);
    const timestamp = React.useMemo(() => moment().subtract(show_future ? 6 : 0, 'hour').toISOString(), [show_future]);
    const [editor, setEditor] = React.useState<{
        action: 'create' | 'edit',
        item?: EventModel;
    } | undefined>();
    const [show_share, setShowShare] = React.useState<EventModel | undefined>();
    const [show_delete, setShowDelete] = React.useState<EventModel | undefined>();
    const [anchor_el, setAnchorEl] = React.useState<{
        anchor: HTMLButtonElement;
        item: EventModel;
    } | undefined>();
    const {
        items,
        loading,
        refetch,
    } = usePaginate<EventModel>({
        entity_name: 'event',
        fragment: EventFragment,
        query_inner: `where: {
            _and: [
                {is_live: {_eq: true}},
                ${show_future ? `{
                    ends_at: {_gte: $timestamp},
                }` : `{
                    ends_at: {_lt: $timestamp},
                }`},
            ],
       }, order_by: {starts_at: ${show_future ? 'asc' : 'desc'}}`,
        clause_outer: `$timestamp: timestamptz!`,
        variables: {
            timestamp
        }
    });

    const onMore = (ev: React.MouseEvent<HTMLButtonElement>, item: EventModel) => {
        setAnchorEl({
            anchor: ev.currentTarget,
            item,
        })
    }

    const onShare = (item: EventModel) => {
        setShowShare(item);
    }

    const onEdit = (item?: EventModel) => () => {
        if (!item) {
            return;
        }
        setAnchorEl(undefined);
        setEditor({
            action: 'edit',
            item,
        })
    }

    const onDelete = () => {
        if (!anchor_el?.item) {
            return;
        }
        setShowDelete(anchor_el.item);
        setAnchorEl(undefined);
    }

    const onConfirmDelete = async () => {
        if (!show_delete) {
            return;
        }
        try {
            const { data } = await updateEvent({
                variables: {
                    event_id: show_delete.id,
                    set: {
                        deleted_at: 'now()',
                    }
                }
            });
            if (data?.update_event_by_pk?.deleted_at) {
                app_dialog.showSnackbar('Event deleted');
                setShowDelete(undefined);
            }
        } catch (e: any) {
            app_dialog.showError(e);
        }
    }

    const onFinish = (_: EventModel, is_new?: boolean) => {
        if (is_new) {
            refetch();
        }
        setEditor(undefined);
    }

    const onDuplicate = async () => {
        if (!anchor_el?.item) {
            return;
        }
        const { item } = anchor_el;
        setAnchorEl(undefined);
        app_dialog.showDialog({
            title: 'Duplicate event',
            message: `You are about to make a new copy of this event: ${item.title}. This will make a new copy of this event, the tickets and certificates. The event will be place In Draft.`,
            buttons: [{
                label: 'Cancel',
            }, {
                label: 'Duplicate',
                color: 'primary',
                onClick: async () => {
                    const event = await duplicate(item.id);
                    if (!!event) {
                        navigate(`/events/${event.id}`);
                    }
                }
            }]
        })
    }

    const _items = React.useMemo(() => items.filter(i => !i.deleted_at), [items])

    return <>
        {show_share ? <EventShare
            name={show_share.title}
            url={show_share.url}
            is_live={show_share.is_live}
            event_id={show_share.id}
            onClose={() => setShowShare(undefined)}
        /> : null}
        {show_delete ? <DeleteConfirmDialog
            onCancel={() => setShowDelete(undefined)}
            onConfirm={onConfirmDelete}
            message={`Are you sure you want to delete the event: ${show_delete.title}. This is not reversible and if there are attendees assigned to this ticket, they won't be able to access it anymore.`}
            deleting={update_status.loading}
            confirm_text='confirm'
        /> : null}
        <Menu
            open={Boolean(anchor_el)}
            anchorEl={anchor_el?.anchor}
            onClose={() => setAnchorEl(undefined)}
        >
            <MenuItem onClick={onEdit(anchor_el?.item)}>Quick Edit</MenuItem>
            <Link style={{
                color: 'inherit',
                textDecoration: 'none',
            }} target='_blank' href={`/events/${anchor_el?.item.id}/edit`}>
                <MenuItem>Edit Full Description</MenuItem>
            </Link>
            <Divider />
            <MenuItem onClick={onDuplicate}><Typography>Duplicate</Typography></MenuItem>
            <MenuItem onClick={onDelete}><Typography color='secondary'>Delete</Typography></MenuItem>
        </Menu>
        {editor ? <EventEditorDialog
            action={editor.action}
            item={editor.item}
            onFinish={onFinish}
            onClose={() => setEditor(undefined)} /> : null}
        <Grid container spacing={2} direction='row-reverse'>
            <Grid item
                xs={12}
                sm={4}>
                <EventManageSidebar
                    show_future={show_future}
                    setShowFuture={setShowFuture}
                    onCreate={() => setEditor({ action: 'create' })} />
                <Typography variant='body1'>Share event list with your members</Typography>
                <TextField
                    fullWidth
                    margin='dense'
                    value={`${environment.frontend_url}/${username}`}
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>
                            <CopyToClipboard
                                text={`${environment.frontend_url}/${username}`}
                                onCopy={() => {
                                    app_dialog.showSnackbar('Link copied')
                                }}>
                                <Button size='small'>Copy</Button>
                            </CopyToClipboard>
                        </InputAdornment>
                    }}
                />
                {is_referral_program_enabled ? <Link
                    href='/referrers' className={classes.referralBox} target='_blank'>
                    <Typography variant='h6'>{name}'s Referral Program</Typography>
                    <Typography variant='body1'>Guests are able to refer friends and colleagues to your events and earn rewards.</Typography>
                    <Typography variant='body2'>View all amabassadors →</Typography>
                </Link> : null}
            </Grid>
            <Grid item
                xs={12}
                sm={8}>
                {_items.length === 0 && !loading ? <EmptyListComponent
                    icon={<CalendarIcon />}
                    message={`No ${show_future ? 'upcoming live' : 'past'} events found.${show_future ? ' Check drafts if you already created an event 👉' : ''}`}
                    actions={show_future ? [{
                        label: '+ Create event',
                        onClick: () => setEditor({ action: 'create' })
                    }] : undefined}
                /> : null}
                {_items.map(item => <EventListItem
                    item={item}
                    currency={currency}
                    key={item.id}
                    onMore={onMore}
                    onShare={onShare}
                />)}
            </Grid>
        </Grid>
    </>
}

const useStyles = makeStyles((theme) => createStyles({
    referralBox: {
        display: 'block',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        margin: theme.spacing(2, 0),
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            textDecoration: 'none',
            color: 'white',
        }
    }
}))