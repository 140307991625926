import { Box, createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import { Field, FormikProps } from 'formik';
import { Switch, TextField, } from 'formik-material-ui';
import React from 'react';
import { LoadingButton } from '../../ui/loading-button';
import { ProfileImageEditorComponent } from '../../ui/profile-image-editor.component';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker } from 'formik-material-ui-pickers';

interface Props extends FormikProps<any> {
	loading?: boolean;
}

export const OrgEditForm = ({
	loading,
	dirty,
	values,
}: Props) => {
	const classes = useStyles({});


	return <div className={classes.root}>
		<Field
			name='logo_image_key'
			component={ProfileImageEditorComponent}
		/>
		<Field
			component={TextField}
			name="name"
			type="text"
			label="Organization Name (required)"
			fullWidth
			margin='normal'
		/>
		<Field
			component={TextField}
			name="email"
			type="email"
			label="Email address (required)"
			fullWidth
			margin='normal'
		/>
		<Field
			component={TextField}
			name="phone"
			type="text"
			label="Phone (optional)"
			fullWidth
			margin='normal'
		/>
		<Field
			component={TextField}
			name="description"
			type="text"
			label="Description/About (Optional)"
			placeholder='Share a bit about your organization...'
			fullWidth
			margin='normal'
			multiline
		/>
		<Box display='flex' py={2}>
			<Box flex={1}>
				<Typography >Enable Referral Program?</Typography>
				<Typography variant='body1' color='textSecondary'>Let people promote your courses and earn rewards</Typography>
			</Box>
			<Field
				component={Switch}
				name="is_referral_program_enabled"
				type='checkbox'
			/>
		</Box>
		<Box py={2}>
			<Typography variant='overline'>PACE Information</Typography>
			<Field
				component={TextField}
				name="pace_id"
				type="text"
				label="PACE #"
				helperText='Add your PACE ID for Certificates'
				fullWidth
				margin='normal'
			/>
			<Field
				component={TextField}
				name="pace_key"
				type="text"
				label="PACE Submission Key"
				placeholder='e.g. 5s8r9kzVK4DnasVmkadsfRvF-X56F1sg66Xub_946'
				helperText='We can auto-submit to AGD Roster with this. Email pace@agd.org with subject: Roster API Submission to get your Key'
				fullWidth
				margin='normal'
			/>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<Field
							component={DatePicker}
							format='YYYY-MM-DD'
							name="pace_start"
							label="Approval Start Date"
							fullWidth
							margin='normal'
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field
							component={DatePicker}
							format='YYYY-MM-DD'
							name="pace_end"
							label="Approval End Date"
							fullWidth
							margin='normal'
						/>
					</Grid>
				</Grid>
			</MuiPickersUtilsProvider>
		</Box>
		<Box py={2}>
			<Typography variant='overline'>CERP Information</Typography>
			<Box display='flex'>
				<Typography style={{
					flex: 1,
				}}>Enable CERP recognition?</Typography>
				<Field
					component={Switch}
					name="is_cerp_enabled"
					type='checkbox'
				/>
			</Box>
			{values.is_cerp_enabled ? <MuiPickersUtilsProvider utils={DateFnsUtils}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<Field
							component={DatePicker}
							format='YYYY-MM-DD'
							name="cerp_start"
							label="Approval Start Date"
							fullWidth
							margin='normal'
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field
							component={DatePicker}
							format='YYYY-MM-DD'
							name="cerp_end"
							label="Approval End Date"
							fullWidth
							margin='normal'
						/>
					</Grid>
				</Grid>
			</MuiPickersUtilsProvider> : null}
		</Box>
		<Box pt={2} textAlign='right'>
			<LoadingButton
				loading={loading}
				disabled={!dirty}
				variant='contained'
				color='primary'
				type='submit'
				size='large'
			>
				{!dirty ? 'Saved' : 'Save'}
			</LoadingButton>
		</Box>
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
}));