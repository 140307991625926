import { gql, useApolloClient } from "@apollo/client";
import { Button, createStyles, DialogContent, makeStyles, Typography } from "@material-ui/core";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import moment from "moment";
import React from "react";
import { CSVLink } from "react-csv";
import { environment } from "../../environment";
import { InvoiceFragment, InvoiceModel } from "../../models/invoice.model";
import { StripePaymentIntentStatus } from "../../models/stripe-payment-intent-status.enum";
import { BasicDialog, BasicDialogTitle } from "../../ui/dialog";
import { LoadingDots } from "../../ui/loading-dots";
import { getFormattedCurrency } from "../../utils/get-formatted-currency";


type Props = {
    onClose: () => void;
}


const QueryInvoices = gql`
    query invoice(
        $cursor: bigint!,
        $limit: Int!,
        $status: String!
    ) {
        invoice(where: {
            _and: [
                {id: {_gt: $cursor}},
                {status: {_eq: $status}}
            ]
        }, limit: $limit, order_by: {id: asc}) {
            ${InvoiceFragment}
        }
    }
`;

export const ExportInvoiceItem = ({
    onClose,
}: Props) => {
    const classes = useStyles({});
    const [is_ready, setIsReady] = React.useState(false);
    const [csv_data, setCsvData] = React.useState<any[]>([]);
    const [columns, setColumns] = React.useState<string[]>([]);
    const [total_rows, setTotalRows] = React.useState(0);
    const client = useApolloClient();

    const fetchInvoices = async (invoices: InvoiceModel[], limit: number, cursor = 0): Promise<InvoiceModel[]> => {
        try {
            const { data, error } = await client.query({
                query: QueryInvoices,
                variables: {
                    limit,
                    cursor,
                    status: StripePaymentIntentStatus.Succeeded,
                }
            });
            const new_invoices = (data?.invoice || []) as InvoiceModel[];
            if (new_invoices.length < limit) {
                return [
                    ...invoices,
                    ...new_invoices,
                ];
            }
            return fetchInvoices([
                ...invoices,
                ...new_invoices,
            ], limit, new_invoices[new_invoices.length - 1].id);
        } catch (e: any) {
            console.error(e);
            return [];
        }
    }

    React.useEffect(() => {
        fetchInvoices([], 50).then(data => {
            const columns: string[] = [
                'id',
                'name',
                'email',
                'agd_id',
                'link',
                'created_at',
                'status_updated_at',
                'status',
                'subtotal',
                'discounts',
                'payment_intent_id',
                'discount_total',
                'amount'
            ];
            const csv_data = data
                .sort((a, b) => +new Date(b.created_at) - +new Date(a.created_at))
                .map(d => {
                    return {
                        ...d,
                        created_at: moment(d.created_at).format('LLL'),
                        subtotal: getFormattedCurrency(d.subtotal, d.currency),
                        discount_total: getFormattedCurrency(d.discount_total, d.currency),
                        amount: getFormattedCurrency(d.amount, d.currency),
                        status_updated_at: moment(d.status_updated_at).format('LLL'),
                        link: `${environment.checkout_url}/${d.id}?secret=${d.secret}`,
                    }
                });
            setTotalRows(csv_data.length);
            setColumns(columns);
            setCsvData(csv_data);
            setIsReady(true);
        })
    }, []);

    return <BasicDialog
        id='export-attendees'
        onClose={onClose}
        open={true}
    >
        <BasicDialogTitle title='Export Invoices' onClose={onClose} />
        <DialogContent>
            <div className={classes.loading}>
                {is_ready ? <>
                    <Typography variant='h6' gutterBottom>
                        Your download is ready. {total_rows} invoices found.
                    </Typography>
                    <CSVLink
                        data={csv_data}
                        filename={`invoices_${moment().format('LL')}.csv`}
                        headers={columns}
                        className={classes.button}
                        target="_blank">
                        <Button
                            variant='contained'
                            color='secondary'
                            size='large'
                            startIcon={<DownloadIcon />}
                        >Download Report</Button>
                    </CSVLink>
                </> : <>
                        <LoadingDots />
                        <Typography variant='h6' align='center'>
                            Preparing your export...one moment.</Typography>
                    </>}
            </div>
        </DialogContent>
    </BasicDialog>
}

const useStyles = makeStyles(theme => createStyles({
    button: {
        textDecoration: 'none !important',
    },
    loading: {
        textAlign: 'center',
        padding: theme.spacing(0, 0, 4, 0),
    }
}))