import { createStyles, Link, makeStyles, Typography } from "@material-ui/core"
import moment from "moment";
import React from "react"
import { environment } from "../../../environment";
import { InvoiceModel } from "../../../models/invoice.model";
import { getFormattedCurrency } from "../../../utils/get-formatted-currency";

type Props = {
    invoice: InvoiceModel;
}

export const Invoice = ({
    invoice,
}: Props) => {
    const classes = useStyles({});
    return <>
        <div className={classes.user}>
            <Typography variant='overline'>Invoice Prepared for</Typography>
            <Typography variant='body1'><strong>{invoice.name}</strong></Typography>
            <Typography variant='body2'>{invoice.email}</Typography>
            <Typography variant='body2' color='textSecondary'>{moment(invoice.status_updated_at).calendar()}</Typography>
        </div>
        <Link href={`${environment.checkout_url}/${invoice.id}?secret=${invoice.secret}`} target='_blank'>
            View Receipt
        </Link>
        <div className={classes.items}>
            <Typography variant='overline'>Invoice items</Typography>
            {invoice.invoice_items
                .map(item => <div key={item.id} className={classes.item}>
                    <div>
                        <Link href={`${environment.frontend_url}/e/${item.ticket.event.id}`}>
                            <Typography gutterBottom variant="body1">
                                {item.ticket.event.title}: <strong>
                                    {item.ticket.name}
                                </strong>
                            </Typography>
                        </Link>
                        <Typography variant='body1'>
                            {item.quantity} x {getFormattedCurrency(
                                item.price, invoice.currency)
                            } each</Typography>
                    </div>
                    <div style={{
                        flex: 1,
                    }} />
                    <Typography variant='h5'><strong>{getFormattedCurrency(
                        item.price * item.quantity, invoice.currency)
                    }</strong></Typography>
                </div>)}
            <div className={classes.item}>
                <div>
                    <Typography gutterBottom variant="body1">
                        <strong>Total Paid</strong>
                    </Typography>
                </div>
                <div style={{
                    flex: 1,
                }} />
                <Typography variant='h5'><strong>{getFormattedCurrency(
                    invoice.amount, invoice.currency)
                }</strong></Typography>
            </div>
            {(invoice.refund_tally?.total_amount || 0) > 0 ? <div className={classes.item}>
                <div>
                    <Typography color='secondary' gutterBottom variant="body1">
                        <strong>Total Refunded</strong>
                    </Typography>
                </div>
                <div style={{
                    flex: 1,
                }} />
                <Typography variant='h5' color='secondary'><strong>-{getFormattedCurrency(
                    invoice.refund_tally?.total_amount || 0, invoice.currency)
                }</strong></Typography>
            </div> : null}
        </div>
    </>
}

const useStyles = makeStyles(theme => createStyles({
    user: {
        padding: theme.spacing(2, 0),
    },
    items: {
        paddingTop: theme.spacing(2),
    },
    item: {
        display: 'flex',
        padding: theme.spacing(2, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
        alignItems: 'center',
        '&:last-child': {
            borderBottom: 0,
        }
    },
}))