import { createStyles, makeStyles, MenuItem } from "@material-ui/core";
import { Field, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import { AttendanceStateOptions } from "../../../models/attendance.model";

interface Props extends FormikProps<any> {

}

export const AttendanceForm = ({
    values,
}: Props) => {
    const classes = useStyles({});
    return <>
        <Field
            name='name'
            component={TextField}
            label='Name'
            margin='normal'
            fullWidth
        />
        <Field
            name='state'
            component={TextField}
            label='Select State'
            margin='normal'
            fullWidth
            select
            helperText='Check In/Check Out/Off'
        >{AttendanceStateOptions.map(o => <MenuItem
            key={o.id}
            value={o.id}
        >{o.label}</MenuItem>)}
        </Field>
        <Field
            name='youtube_url'
            component={TextField}
            label='Youtube Url (For Webinar Only)'
            margin='normal'
            fullWidth
        />
    </>
}

const useStyles = makeStyles(theme => createStyles({

}))