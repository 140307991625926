import { createStyles, IconButton, makeStyles, TableCell, TableRow, Tooltip } from "@material-ui/core";
import MoreIcon from '@material-ui/icons/MoreHoriz';
import moment from "moment";
import React from "react";
import { AttendeeModel } from "../../models/attendee.model";
import { InvoiceItemModel } from "../../models/invoice-item.model";
import { getFormattedCurrency } from "../../utils/get-formatted-currency";
import { InvoiceItemCol } from "./use-invoice-item-cols";

type Props = {
    item: InvoiceItemModel;
    showColumn: (id: InvoiceItemCol) => boolean;
    onMore: (e: React.MouseEvent<HTMLButtonElement>, item: InvoiceItemModel) => void;
}

export const InvoiceItem = ({
    item,
    showColumn,
    onMore,
}: Props) => {
    const classes = useStyles({});

    return <TableRow
        hover
    >
        <TableCell>
            <div className={classes.buttons}>
                <Tooltip title='More Options'>
                    <IconButton size='small' onClick={(e) => onMore(e, item)}>
                        <MoreIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
            </div>
        </TableCell>
        <TableCell style={{ width: 50 }}>
            {item.id}
        </TableCell>
        <TableCell style={{
            minWidth: 150,
        }}>{item.invoice.name}</TableCell>
        {showColumn('email') ? <TableCell style={{
            minWidth: 200,
        }}>{item.invoice.email}</TableCell> : null}
        {showColumn('referrer') ? <TableCell>{item.invoice.referrer ? `${item.invoice.referrer?.name}\n${item.invoice.referrer?.email}` : '--'}</TableCell> : null}
        {showColumn('agd_id') ? <TableCell>{item.invoice.agd_id}</TableCell> : null}
        <TableCell>{item.quantity}</TableCell>
        {showColumn('price') ? <TableCell>{getFormattedCurrency(item.price, item.invoice.currency)}</TableCell> : null}
        {showColumn('subtotal') ? <TableCell>{getFormattedCurrency(item.subtotal, item.invoice.currency)}</TableCell> : null}
        {showColumn('discount') ? <TableCell>{getFormattedCurrency(item.discount, item.invoice.currency)}</TableCell> : null}
        <TableCell>{getFormattedCurrency(item.amount, item.invoice.currency)}</TableCell>
        <TableCell className={classes.refund}>{item.invoice.refund_tally
            ? getFormattedCurrency(item.invoice.refund_tally?.total_amount || 0, item.invoice.currency) : ''}</TableCell>
        {showColumn('sold_at') ? <TableCell style={{
            minWidth: 180,
        }}>{moment(item.sold_at).format('YYYY/MM/DD HH:mm')}</TableCell> : null}
        {showColumn('ticket') ? <TableCell>{item.ticket.name}</TableCell> : null}
    </TableRow>
}

const useStyles = makeStyles(theme => createStyles({
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    refund: {
        color: theme.palette.secondary.main,
    },
}))