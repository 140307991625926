import { gql, useMutation } from "@apollo/client";
import { DialogContent } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { useAppDialog } from "../../../app-dialog";
import { DiplomaFragment, DiplomaModel } from "../../../models/diploma.model";
import { BasicDialogActions } from "../../../ui/dialog";
import { LoadingButton } from "../../../ui/loading-button";
import { DiplomaForm } from "./diploma.form";
import { DiplomaValidationSchema, DiplomaInitialValues, validateDiploma } from "./diploma.validation";

const MUTATION = gql`
    mutation insert($object: diploma_insert_input!) {
        insert_diploma_one(object: $object) {
            ${DiplomaFragment}
        }
    }
`;

type Props = {
    event_id: number;
    onFinish: (product: DiplomaModel, is_new?: boolean) => void;
}

export const DiplomaCreate = ({
    event_id,
    onFinish,
}: Props) => {
    const [insert, status] = useMutation(MUTATION)
    const app_dialog = useAppDialog();

    const onSubmit = async (values: any) => {
        try {
            const { data } = await insert({
                variables: {
                    object: {
                        ...validateDiploma(values),
                        event_id,
                    }
                }
            });
            const diploma = data?.insert_diploma_one;
            if (!diploma) {
                return;
            }
            onFinish(diploma, true);
        } catch (e: any) {
            app_dialog.showError(e);
        }
    }
    return <Formik initialValues={DiplomaInitialValues()}
        validationSchema={DiplomaValidationSchema}
        onSubmit={onSubmit}>{() => <Form noValidate>
            <DialogContent>
                <DiplomaForm />
            </DialogContent>
            <BasicDialogActions>
                <LoadingButton loading={status.loading}
                    color='primary'
                    type='submit'
                    size='large'
                    variant='contained'>
                    Create
                </LoadingButton>
            </BasicDialogActions>
        </Form>}
    </Formik>
}
