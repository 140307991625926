import React from "react";
import { EventModel } from "../../../models/event.model";
import { BasicDialog, BasicDialogTitle } from "../../../ui/dialog";
import { EventCreate } from "./event-create";
import { EventEdit } from "./event-edit";


type Props = {
    action: 'edit' | 'create';
    onClose: () => void;
    onFinish: (event: EventModel, is_new?: boolean) => void;
    item?: EventModel;
    no_editor_hint?: boolean;
}


export const EventEditorDialog = ({
    action,
    onClose,
    onFinish,
    item,
    no_editor_hint
}: Props) => {
    return <BasicDialog
        open={true}
        id='update-event'
        onClose={onClose}>
        <BasicDialogTitle onClose={onClose} title={action === 'edit' ? 'Edit event' : 'Create event'} />
        {item ? <EventEdit
            event={item}
            onFinish={onFinish}
            no_editor_hint={no_editor_hint}
        /> : <EventCreate 
            onFinish={onFinish}
        />}
    </BasicDialog>
}
