import { RouteComponentProps } from "@reach/router";
import React from 'react';
import { AppLayout } from '../../layout/app-layout';
import { EventList } from "./event-list";

type Props = {
}


export const EventsPage = ({
}: RouteComponentProps<Props>) => {
    return <AppLayout>
        <EventList />
    </AppLayout>;
}