import { createStyles, IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import AccountIcon from '@material-ui/icons/AccountCircle';
import React from 'react';
import { useOrg } from '../auth/use-org';
import firebase from '../firebase';
import { ImageSize, LazyImage } from '../image-upload';
// import { NotificationBell } from '../notification';
import { UnstyledLink } from '../ui/unstyled-link';


type Props = {
	no_video?: boolean;
}

export const UserMenu = ({ no_video }: Props) => {
	const classes = useStyles({});
	const {
		org,
	} = useOrg();
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const signout = async () => {
		return firebase.auth().signOut();
	}

	const renderProfileImage = <>
		{org.logo_image_key ? <LazyImage
			image_key={org.logo_image_key}
			image_opt={{
				size: ImageSize.medium,
			}}
			className={classes.profileImage}
		/> : <AccountIcon />}
	</>
	
	return <>
		<Menu
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={() => setAnchorEl(null)}
			id='account-menu'
			keepMounted
		>
			<MenuItem disabled>{org.name}</MenuItem>
			<UnstyledLink to='/edit'>
				<MenuItem>Edit Organization</MenuItem>
			</UnstyledLink>
			<UnstyledLink to='/billing'>
				<MenuItem>Billing</MenuItem>
			</UnstyledLink>
			<MenuItem onClick={signout}>Sign Out</MenuItem>
		</Menu>
		<IconButton onClick={handleClick}>
			{renderProfileImage}
		</IconButton>
	</>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
		marginLeft: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
	},
	avatar: {
		width: 35,
		height: 35,
		borderRadius: '50%',
	},
	profileImage: {
		width: 30,
		height: 30,
		borderRadius: '50%',
	},
	popperRoot: {
		padding: theme.spacing(2),
		minWidth: theme.breakpoints.values.xs,
		textAlign: 'center',
		position: 'relative',
	},
	fullMenu: {
		display: 'block',
		[theme.breakpoints.down('xs')]: {
			display: 'none'
		},
	},
	iconMenu: {
		display: 'none',
		[theme.breakpoints.down('xs')]: {
			display: 'block'
		},
	},
	link: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		'&:hover': {
			textDecoration: 'underline',
		}
	}
}));