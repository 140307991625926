import { Box, Button, Typography } from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useGetByPk } from "../../apollo/networking/use-get-by-pk"
import { AppLayout } from "../../layout/app-layout"
import { EventFragment, EventModel } from "../../models/event.model"
import { LoadingDots } from "../../ui/loading-dots"
import { PageHeader } from "../../ui/page-header"
import { InvoiceItemList } from "./invoice-item-list"

type Props = {
    event_id: number;
}

export const EventSalesPage = ({
    event_id,
}: RouteComponentProps<Props>) => {
    const { 
        loading,
        entity: event,
    } = useGetByPk<EventModel>({
        entity_name: 'event',
        fragment: EventFragment,
        pk_columns: [{
            value: event_id,
            name: 'id',
            type: 'bigint',
        }],
    });
    return <AppLayout>
        <PageHeader title='Sales' />
        {loading ? <LoadingDots /> : null}
        {event ? <InvoiceItemList event={event} /> : null}
    </AppLayout>
}
