import { createStyles, makeStyles } from "@material-ui/core";
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import React, { useRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

type Props = {
    content?: any;
    onUpdate: (v: any) => void;
}

export const EventEditor = ({
    content,
    onUpdate,
}: Props) => {
    const classes = useStyles({});
    const timeout_ref = useRef<NodeJS.Timeout>()
    const onContentChange = (state: EditorState) => {
        if (timeout_ref.current) {
            clearTimeout(timeout_ref.current);
        }
        timeout_ref.current = setTimeout(() => {
            onUpdate({
                content: convertToRaw(state.getCurrentContent())
            })
        }, 2000)

    }

    const handlePastedText = (
        text: string,
        html: string,
        editorState: EditorState,
        onChange: (editorState: EditorState) => void,
    ) => {
        return false;
    }

    return <div className={classes.editor}>
        <Editor
            defaultEditorState={
                content
                    ? EditorState.createWithContent(convertFromRaw(content))
                    : EditorState.createEmpty()
            }
            handlePastedText={handlePastedText}
            onEditorStateChange={onContentChange}
            placeholder={`Tell your guests about the event.\nThis is a free-form text editor, which means you can format your text with Bold, Italics, make links, add images and more.\nUse the toolbar above to format your text...`}
            wrapperClassName="demo-wrapper"
            editorClassName="editor"
            toolbarClassName='toolbar'
            toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'image', 'embedded', 'remove', 'emoji'],
                inline: { options: ['bold', 'italic', 'underline'] },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                remove: { inDropdown: true }
                // history: { inDropdown: true },
            }}
        />
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    editor: {
        position: 'relative',
        '& .editor': {
            minHeight: '100vh',
            padding: theme.spacing(2),
        },
        '& .toolbar': {
            position: 'sticky',
            top: 80,
            background: 'white',
            zIndex: 2,
        }
    }
}))