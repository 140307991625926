import { Box, Button, Menu, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { arrayMove } from '../../../utils/array-move';
import { sortItemsByIdList } from '../../../utils/sort-items-by-id-list';
import { NodeType, NodeTypeOptions, nodeTypeToJSON, VisualNodeModel } from './models';
import { VisualNodeComponent } from './visual-node.component';

type Props = {
	schema: any;
	ui_schema: any;
	onChangeSchema: (schema: any) => void
	onChangeUiSchema: (ui_schema: any) => void
}

export const VisualEditorComponent = ({
	schema,
	ui_schema,
	onChangeSchema,
	onChangeUiSchema,
}: Props) => {
	const add_menu_popup = usePopupState({
		popupId: 'add-menu',
		variant: 'popper',
	})
	const [insert_position, setInsertPosition] = React.useState(-1);

	const { properties, required } = schema || {};
	const order = ui_schema ? ui_schema['ui:order'] as string[] || [] : [];

	const nodes: VisualNodeModel[] = React.useMemo(() => {
		if (!schema || !order) {
			return [];
		}
		return sortItemsByIdList(order, Object.keys(properties || {}).map(property_id => {
			const property = properties[property_id];
			const ui = (ui_schema || {})[property_id];
			return {
				ui,
				id: property_id,
				property,
				meta: property.meta || {},
				is_required: (required || []).indexOf(property_id) > -1,
				answers: property.answers,
			};
		}));
	}, [ui_schema, properties, required])

	const onMove = (idx: number, direction: 'up' | 'down') => {
		const new_idx = idx + (direction === 'up' ? -1 : 1);
		if (new_idx < 0 || new_idx > order.length - 1) {
			return;
		}
		const new_order = arrayMove(order, idx, new_idx);
		onChangeUiSchema({
			...ui_schema,
			'ui:order': new_order,
			change: {
				[new Date().toISOString()]: true,
			},
		});
	}

	const onUpdateNode = (json: VisualNodeModel) => {
		const { id, property, is_required, meta, answers, } = json;
		const required = (schema.required || []).filter((r: string) => r !== id);
		onChangeSchema({
			...schema,
			properties: {
				...schema.properties,
				[id]: {
					...property,
					meta,
					answers,
				},
			},
			required: is_required ? [...required, id] : required,
		})
		onChangeUiSchema({
			...ui_schema,
			[id]: json.ui,
			change: {
				[new Date().toISOString()]: true,
			},
		})
	}

	const onDelete = (property_id: string) => {
		const _properties = Object.keys(properties)
		.filter(id => id !== property_id)
		.reduce((obj, id) => ({
			...obj,
			[id]: properties[id],
		}), {});
		onChangeSchema({
			...schema,
			properties: _properties,
			required: (schema.required || []).filter((p: string) => p !== property_id),
		});
		onChangeUiSchema({
			...ui_schema,
			'ui:order': order.filter(o => o !== property_id),
		});
	}

	const onAddItem = (node_type: NodeType) => {
		const id = uuidv4();
		const result = nodeTypeToJSON(node_type, {
			type: 'string',
		})
		const new_order = [...order];
		if (insert_position === -1) {
			new_order.push(id);
		} else {
			new_order.splice(insert_position + 1, 0, id);
		}

		onChangeUiSchema({
			...ui_schema,
			'ui:order': new_order,
			[id]: result.ui,
		});
		onChangeSchema({
			...schema,
			properties: {
				...schema.properties,
				[id]: result.property,
			},
		});
		setInsertPosition(-1);
		add_menu_popup.close();
	}

	return <>
		<Menu {...bindMenu(add_menu_popup)}>
			{NodeTypeOptions.map(type => <MenuItem
				key={type.id}
				value={type.id}
				onClick={() => onAddItem(type.id)}>
				{type.label}
			</MenuItem>)}
		</Menu>

		{nodes.map((node, idx) => <VisualNodeComponent
			key={node.id}
			node={node}
			is_up_enabled={idx !== 0}
			is_down_enabled={idx !== order.length - 1}
			move={direction => onMove(idx, direction)}
			onUpdateNode={onUpdateNode}
			onAdd={e => {
				setInsertPosition(idx);
				add_menu_popup.open(e);
			}}
			onDelete={() => onDelete(node.id)}
		/>)}
		<Box pt={2} textAlign='center'>
			<Button
				{...bindTrigger(add_menu_popup)}
				startIcon={<AddIcon />}
				endIcon={<ExpandIcon />}
			>Add Question</Button>
		</Box>
	</>
}
