export const PROD_CONFIG = {
  production: true,
  image_base_url: 'https://d1niz8ad8nu5h5.cloudfront.net',
  image_bucket: 'edropin-images-1',
  ws_url: 'wss://certifysimple-graphql.herokuapp.com/v1/graphql',
  http_url: 'https://certifysimple-graphql.herokuapp.com/v1/graphql',
  default_page_size: 50,
  frontend_url: 'https://certifysimple.com',
  checkout_url: 'https://checkout.certifysimple.com',
  user_url: 'https://user.certifysimple.com',
  admin_url: 'https://admin.certifysimple.com',
  host_url: 'https://host.certifysimple.com',
  checkin_url: 'https://checkin.certifysimple.com',
  // stripe_connect_key: 'ca_ChSRQDy2pgToi7ERxFexh3T6t5VeD9Xp', // dev
  stripe_connect_key: 'ca_ChSRVpCT6W1j0N8w8ol19xLls25K0BzB',
  firebase_refresh_url: 'https://us-central1-certify-simple.cloudfunctions.net/refreshToken',
  firebaseConfig: {
    apiKey: "AIzaSyDwx4klV719KaxtNW5-_kWBrdLiF_K3tus",
    authDomain: "certify-simple.firebaseapp.com",
    projectId: "certify-simple",
    storageBucket: "certify-simple.appspot.com",
    messagingSenderId: "1016111139978",
    appId: "1:1016111139978:web:d0995ecf71c7e63be33173",
    measurementId: "G-ZHX91R4W3C"
  }
};
